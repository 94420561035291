import  { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {Row,Col} from 'antd'
import api from "./../../api";

const MainFooter = ({ contained, copyright, country }) => {

  const [status,setStatus] = useState({
    backStatus: false,
    courierStatus: false,
    generadorCorreosStatus: false,
    apiUtilsStatus: false,
    apiPedidosStatus: false,
    totalPromoNoConfig: false,
    chileExpressStatus: false,
    starkenStatus: false,
  })

  const {
    backend,
    correosChile,
    informesBackend,
    manifiestosBackend,
    divisionPedidos,
    promociones,
    chileExpress,
    starken,
  } = country.statusServerBackendFooter;

  useEffect(() => {
    backend && getStatus();
    correosChile && getStatusCourier();
    manifiestosBackend && getStatusArchivosCorreo();
    informesBackend && getStatusApiUtils();
    divisionPedidos && getStatusPedidos();
    promociones && getTotalPromoNoConfig(2);
    chileExpress && getStatusChileExpress();
    starken && getStatusStarken();
  }, []);

  const serverStatus = (status) => {
    const statusColor = status ? { color: "green" } : { color: "red" };
    return (
      <i className="material-icons" style={{...statusColor,fontSize:16}}>
        {status ? "check_circle" : "report_problem"}
      </i>
    );
  };

  //Status para promociones no configuradas
  const promoStatus = (totalPromoNo) => {
    const statusColor =
      totalPromoNo === 0 ? { color: "green" } : { color: "red" };
    return (
      <i className="material-icons" style={{...statusColor,fontSize:16}}>
        {totalPromoNo === 0 ? "check_circle" : "report_problem"}
      </i>
    );
  };

  const getStatus = async () => {
    const statusApi = await api.apiBackendStatus();
    setStatus(_status => ({
      ..._status,
      backStatus:statusApi?.status === "UP"
    }))
  };

  const getStatusCourier = async () => {
    const statusApi = await api.apiCourierStatus();

    setStatus(_status => ({
      ..._status,
      courierStatus:statusApi?.status === "UP"
    }))
  };

  const getStatusArchivosCorreo = async () => {
    const statusApi = await api.apiCargaArchivosCorreoStatus();

    setStatus(_status => ({
      ..._status,
      generadorCorreosStatus:statusApi?.status === "UP"
    }))
  };

  const getStatusApiUtils = async () => {
    const statusApi = await api.apiUtilsStatus();

    setStatus(_status => ({
      ..._status,
      apiUtilsStatus:statusApi?.status === "UP"
    }))
  };

  const getStatusPedidos = async () => {
    const statusApi = await api.apiPedidosStatus();

    setStatus(_status => ({
      ..._status,
      apiPedidosStatus:statusApi?.status === "UP"
    }))
  };

  const getTotalPromoNoConfig = async (opt) => {
    const statusApi = await api.apiObtenerTotalPromocionesNoConfig(opt);

    setStatus(_status => ({
      ..._status,
      totalPromoNoConfig:statusApi?.data?.totalPromocionesNoconfig ?? 999
    }))
  };

  const getStatusChileExpress = async () => {
    const statusApi = await api.apiStatusChileExpress();

    setStatus(_status => ({
      ..._status,
      chileExpressStatus:statusApi?.status === "UP"
    }))
  };

  const getStatusStarken = async () => {
    const statusApi = await api.apiStatusStarken();

    setStatus(_status => ({
      ..._status,
      starkenStatus:statusApi?.status === "UP"
    }))
  };

  return (
    <footer
      style={{minHeight:50}}
      className="main-footer d-flex py-1 pb-0 px-0 bg-white border-top align-items-center"
    >
      <div className="container-fluid position-relative overflow-hidden ">
        <Row>
          <Col xs={24} md={20} className="d-flex align-items-center">
            <Row className="gap-2">
              {backend && (
                <Col>
                  <span className="ml-auto d-flex align-items-center">
                    Backend: {serverStatus(status.backStatus)}
                  </span>
                </Col>
              )}
              {correosChile && (
                <Col>
                  <span className="ml-auto d-flex align-items-center">
                    Correos de Chile: {serverStatus(status.courierStatus)}
                  </span>
                </Col>
              )}
              {starken && (
                <Col>
                  <span className="ml-auto d-flex align-items-center">
                    Starken: {serverStatus(status.starkenStatus)}
                  </span>
                </Col>
              )}
              {chileExpress && (
                <Col>
                  <span className="ml-auto d-flex align-items-center">
                    ChileExpress: {serverStatus(status.chileExpressStatus)}
                  </span>
                </Col>
              )}
              {informesBackend && (
                <Col>
                  <span className="ml-auto d-flex align-items-center">
                    Informes: {serverStatus(status.apiUtilsStatus)}
                  </span>
                </Col>
              )}
              {manifiestosBackend && (
                <Col>
                  <span className="ml-auto d-flex align-items-center">
                    Manifiestos: {serverStatus(status.generadorCorreosStatus)}
                  </span>
                </Col>
              )}
              {divisionPedidos && (
                <Col>
                  <span className="ml-auto d-flex align-items-center">
                    División de Pedidos: {serverStatus(status.apiPedidosStatus)}
                  </span>
                </Col>
              )}
              {promociones && (
                <Col>
                  <span className="ml-auto d-flex align-items-center">
                    Promociones sin Configurar {status.totalPromoNoConfig}:{" "}
                    {promoStatus(status.totalPromoNoConfig)}
                  </span>
                </Col>
              )}
            </Row>
          </Col>
          <Col xs={24} md={4} className="d-flex my-3 my-lg-0 justify-content-center">
            <span className="copyright">{copyright}</span>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string,
};

MainFooter.defaultProps = {
  contained: true,
  copyright: `${new Date().getFullYear()} - Forus Digital Factory`,
};

const mapStateToProps = (state) => ({
  country: state.app.country,
  navbarStatus: state.app.navbarStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainFooter);
