import React from "react";
import { connect } from "react-redux";

const SidebarMainNavbar = ({ country, navbarStatus }) => {
  return (
    <div>
      <nav
        className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0 navbar navbar-light"
        style={{height:60}}
      >
        <a
          className="me-0 navbar-brand d-flex flex-column position-relative overflow-hidden"
          style={{
            lineHeight: "25px",
            width:"100%"
          }}
        >
          {country.imageLogin && (
            <img
              src={country.imageLogin}
              alt={`imgCountry${country.id}`}
              style={{
                position: "absolute",
                right: -1,
                top: -1,
                height: 62,
                width: 62,
              }}
            />
          )}

          <div className="d-table m-auto">
            <img
              id="main-logo"
              className="d-inline-block align-top me-2"
              style={{ maxWidth: "25px" }}
              src={require("../../../images/icon.png")}
              alt="Shards Dashboard"
            />
            {navbarStatus && (
              <>
                <span className="d-none d-md-inline">Forus App</span>
              </>
            )}
          </div>
        </a>
        {/* eslint-disable-next-line */}
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => ({
  country: state.app.country,
  navbarStatus: state.app.navbarStatus,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMainNavbar);
