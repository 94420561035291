import { SET_LOGIN, SET_ACCESSTOKEN, LOGOUT, SET_USERID } from "../action";

const initialStateValues = {
  isLoggedIn: false,
  accessToken: null,
  userId: null,
  rut: null,
  ordenescompra: [],
  isVerifiedCedula: false,
  idCliente: ""
};

const initialState = { ...initialStateValues };

const actionsMap = {
  [LOGOUT]: (state, action) => {
    return {
      ...initialState
    };
  },
  [SET_LOGIN]: (state, action) => {
    const {
      data: { isLoggedIn, accessToken }
    } = action;

    return {
      ...state,
      isLoggedIn,
      accessToken
    };
  },
  [SET_ACCESSTOKEN]: (state, action) => {
    const { accessToken } = action;
    return {
      ...state,
      accessToken
    };
  },
  [SET_USERID]: (state, action) => {
    const { userId } = action;
    return {
      ...state,
      userId
    };
  }
};

export default (state = initialState, action = {}) => {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
};
