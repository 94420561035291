export const departamento = [
  { id: '01', name: 'Amazonas' },
  { id: '02', name: 'Áncash' },
  { id: '03', name: 'Apurímac' },
  { id: '04', name: 'Arequipa' },
  { id: '05', name: 'Ayacucho' },
  { id: '06', name: 'Cajamarca' },
  { id: '07', name: 'Callao' },
  { id: '08', name: 'Cusco' },
  { id: '09', name: 'Huancavelica' },
  { id: '10', name: 'Huánuco' },
  { id: '11', name: 'Ica' },
  { id: '12', name: 'Junín' },
  { id: '13', name: 'La Libertad' },
  { id: '14', name: 'Lambayeque' },
  { id: '15', name: 'Lima' },
  { id: '16', name: 'Loreto' },
  { id: '17', name: 'Madre de Dios' },
  { id: '18', name: 'Moquegua' },
  { id: '19', name: 'Pasco' },
  { id: '20', name: 'Piura' },
  { id: '21', name: 'Puno' },
  { id: '22', name: 'San Martín' },
  { id: '23', name: 'Tacna' },
  { id: '24', name: 'Tumbes' },
  { id: '25', name: 'Ucayali' }
]

export const provincia = [
  {
    id: '0101',
    name: 'Chachapoyas',
    parent: '01'
  },
  {
    id: '0102',
    name: 'Bagua',
    parent: '01'
  },
  {
    id: '0103',
    name: 'Bongará',
    parent: '01'
  },
  {
    id: '0104',
    name: 'Condorcanqui',
    parent: '01'
  },
  {
    id: '0105',
    name: 'Luya',
    parent: '01'
  },
  {
    id: '0106',
    name: 'Rodríguez de Mendoza',
    parent: '01'
  },
  {
    id: '0107',
    name: 'Utcubamba',
    parent: '01'
  },
  {
    id: '0201',
    name: 'Huaraz',
    parent: '02'
  },
  {
    id: '0202',
    name: 'Aija',
    parent: '02'
  },
  {
    id: '0203',
    name: 'Antonio Raymondi',
    parent: '02'
  },
  {
    id: '0204',
    name: 'Asunción',
    parent: '02'
  },
  {
    id: '0205',
    name: 'Bolognesi',
    parent: '02'
  },
  {
    id: '0206',
    name: 'Carhuaz',
    parent: '02'
  },
  {
    id: '0207',
    name: 'Carlos Fermín Fitzcarrald',
    parent: '02'
  },
  {
    id: '0208',
    name: 'Casma',
    parent: '02'
  },
  {
    id: '0209',
    name: 'Corongo',
    parent: '02'
  },
  {
    id: '0210',
    name: 'Huari',
    parent: '02'
  },
  {
    id: '0211',
    name: 'Huarmey',
    parent: '02'
  },
  {
    id: '0212',
    name: 'Huaylas',
    parent: '02'
  },
  {
    id: '0213',
    name: 'Mariscal Luzuriaga',
    parent: '02'
  },
  {
    id: '0214',
    name: 'Ocros',
    parent: '02'
  },
  {
    id: '0215',
    name: 'Pallasca',
    parent: '02'
  },
  {
    id: '0216',
    name: 'Pomabamba',
    parent: '02'
  },
  {
    id: '0217',
    name: 'Recuay',
    parent: '02'
  },
  {
    id: '0218',
    name: 'Santa',
    parent: '02'
  },
  {
    id: '0219',
    name: 'Sihuas',
    parent: '02'
  },
  {
    id: '0220',
    name: 'Yungay',
    parent: '02'
  },
  {
    id: '0301',
    name: 'Abancay',
    parent: '03'
  },
  {
    id: '0302',
    name: 'Andahuaylas',
    parent: '03'
  },
  {
    id: '0303',
    name: 'Antabamba',
    parent: '03'
  },
  {
    id: '0304',
    name: 'Aymaraes',
    parent: '03'
  },
  {
    id: '0305',
    name: 'Cotabambas',
    parent: '03'
  },
  {
    id: '0306',
    name: 'Chincheros',
    parent: '03'
  },
  {
    id: '0307',
    name: 'Grau',
    parent: '03'
  },
  {
    id: '0401',
    name: 'Arequipa',
    parent: '04'
  },
  {
    id: '0402',
    name: 'Camaná',
    parent: '04'
  },
  {
    id: '0403',
    name: 'Caravelí',
    parent: '04'
  },
  {
    id: '0404',
    name: 'Castilla',
    parent: '04'
  },
  {
    id: '0405',
    name: 'Caylloma',
    parent: '04'
  },
  {
    id: '0406',
    name: 'Condesuyos',
    parent: '04'
  },
  {
    id: '0407',
    name: 'Islay',
    parent: '04'
  },
  {
    id: '0408',
    name: 'La Uniòn',
    parent: '04'
  },
  {
    id: '0501',
    name: 'Huamanga',
    parent: '05'
  },
  {
    id: '0502',
    name: 'Cangallo',
    parent: '05'
  },
  {
    id: '0503',
    name: 'Huanca Sancos',
    parent: '05'
  },
  {
    id: '0504',
    name: 'Huanta',
    parent: '05'
  },
  {
    id: '0505',
    name: 'La Mar',
    parent: '05'
  },
  {
    id: '0506',
    name: 'Lucanas',
    parent: '05'
  },
  {
    id: '0507',
    name: 'Parinacochas',
    parent: '05'
  },
  {
    id: '0508',
    name: 'Pàucar del Sara Sara',
    parent: '05'
  },
  {
    id: '0509',
    name: 'Sucre',
    parent: '05'
  },
  {
    id: '0510',
    name: 'Víctor Fajardo',
    parent: '05'
  },
  {
    id: '0511',
    name: 'Vilcas Huamán',
    parent: '05'
  },
  {
    id: '0601',
    name: 'Cajamarca',
    parent: '06'
  },
  {
    id: '0602',
    name: 'Cajabamba',
    parent: '06'
  },
  {
    id: '0603',
    name: 'Celendín',
    parent: '06'
  },
  {
    id: '0604',
    name: 'Chota',
    parent: '06'
  },
  {
    id: '0605',
    name: 'Contumazá',
    parent: '06'
  },
  {
    id: '0606',
    name: 'Cutervo',
    parent: '06'
  },
  {
    id: '0607',
    name: 'Hualgayoc',
    parent: '06'
  },
  {
    id: '0608',
    name: 'Jaén',
    parent: '06'
  },
  {
    id: '0609',
    name: 'San Ignacio',
    parent: '06'
  },
  {
    id: '0610',
    name: 'San Marcos',
    parent: '06'
  },
  {
    id: '0611',
    name: 'San Miguel',
    parent: '06'
  },
  {
    id: '0612',
    name: 'San Pablo',
    parent: '06'
  },
  {
    id: '0613',
    name: 'Santa Cruz',
    parent: '06'
  },
  {
    id: '0701',
    name: 'Prov. Const. del Callao',
    parent: '07'
  },
  {
    id: '0801',
    name: 'Cusco',
    parent: '08'
  },
  {
    id: '0802',
    name: 'Acomayo',
    parent: '08'
  },
  {
    id: '0803',
    name: 'Anta',
    parent: '08'
  },
  {
    id: '0804',
    name: 'Calca',
    parent: '08'
  },
  {
    id: '0805',
    name: 'Canas',
    parent: '08'
  },
  {
    id: '0806',
    name: 'Canchis',
    parent: '08'
  },
  {
    id: '0807',
    name: 'Chumbivilcas',
    parent: '08'
  },
  {
    id: '0808',
    name: 'Espinar',
    parent: '08'
  },
  {
    id: '0809',
    name: 'La Convención',
    parent: '08'
  },
  {
    id: '0810',
    name: 'Paruro',
    parent: '08'
  },
  {
    id: '0811',
    name: 'Paucartambo',
    parent: '08'
  },
  {
    id: '0812',
    name: 'Quispicanchi',
    parent: '08'
  },
  {
    id: '0813',
    name: 'Urubamba',
    parent: '08'
  },
  {
    id: '0901',
    name: 'Huancavelica',
    parent: '09'
  },
  {
    id: '0902',
    name: 'Acobamba',
    parent: '09'
  },
  {
    id: '0903',
    name: 'Angaraes',
    parent: '09'
  },
  {
    id: '0904',
    name: 'Castrovirreyna',
    parent: '09'
  },
  {
    id: '0905',
    name: 'Churcampa',
    parent: '09'
  },
  {
    id: '0906',
    name: 'Huaytará',
    parent: '09'
  },
  {
    id: '0907',
    name: 'Tayacaja',
    parent: '09'
  },
  {
    id: '1001',
    name: 'Huánuco',
    parent: '10'
  },
  {
    id: '1002',
    name: 'Ambo',
    parent: '10'
  },
  {
    id: '1003',
    name: 'Dos de Mayo',
    parent: '10'
  },
  {
    id: '1004',
    name: 'Huacaybamba',
    parent: '10'
  },
  {
    id: '1005',
    name: 'Huamalíes',
    parent: '10'
  },
  {
    id: '1006',
    name: 'Leoncio Prado',
    parent: '10'
  },
  {
    id: '1007',
    name: 'Marañón',
    parent: '10'
  },
  {
    id: '1008',
    name: 'Pachitea',
    parent: '10'
  },
  {
    id: '1009',
    name: 'Puerto Inca',
    parent: '10'
  },
  {
    id: '1010',
    name: 'Lauricocha ',
    parent: '10'
  },
  {
    id: '1011',
    name: 'Yarowilca ',
    parent: '10'
  },
  {
    id: '1101',
    name: 'Ica ',
    parent: '11'
  },
  {
    id: '1102',
    name: 'Chincha ',
    parent: '11'
  },
  {
    id: '1103',
    name: 'Nasca ',
    parent: '11'
  },
  {
    id: '1104',
    name: 'Palpa ',
    parent: '11'
  },
  {
    id: '1105',
    name: 'Pisco ',
    parent: '11'
  },
  {
    id: '1201',
    name: 'Huancayo ',
    parent: '12'
  },
  {
    id: '1202',
    name: 'Concepción ',
    parent: '12'
  },
  {
    id: '1203',
    name: 'Chanchamayo ',
    parent: '12'
  },
  {
    id: '1204',
    name: 'Jauja ',
    parent: '12'
  },
  {
    id: '1205',
    name: 'Junín ',
    parent: '12'
  },
  {
    id: '1206',
    name: 'Satipo ',
    parent: '12'
  },
  {
    id: '1207',
    name: 'Tarma ',
    parent: '12'
  },
  {
    id: '1208',
    name: 'Yauli ',
    parent: '12'
  },
  {
    id: '1209',
    name: 'Chupaca ',
    parent: '12'
  },
  {
    id: '1301',
    name: 'Trujillo ',
    parent: '13'
  },
  {
    id: '1302',
    name: 'Ascope ',
    parent: '13'
  },
  {
    id: '1303',
    name: 'Bolívar ',
    parent: '13'
  },
  {
    id: '1304',
    name: 'Chepén ',
    parent: '13'
  },
  {
    id: '1305',
    name: 'Julcán ',
    parent: '13'
  },
  {
    id: '1306',
    name: 'Otuzco ',
    parent: '13'
  },
  {
    id: '1307',
    name: 'Pacasmayo ',
    parent: '13'
  },
  {
    id: '1308',
    name: 'Pataz ',
    parent: '13'
  },
  {
    id: '1309',
    name: 'Sánchez Carrión ',
    parent: '13'
  },
  {
    id: '1310',
    name: 'Santiago de Chuco ',
    parent: '13'
  },
  {
    id: '1311',
    name: 'Gran Chimú ',
    parent: '13'
  },
  {
    id: '1312',
    name: 'Virú ',
    parent: '13'
  },
  {
    id: '1401',
    name: 'Chiclayo ',
    parent: '14'
  },
  {
    id: '1402',
    name: 'Ferreñafe ',
    parent: '14'
  },
  {
    id: '1403',
    name: 'Lambayeque ',
    parent: '14'
  },
  {
    id: '1501',
    name: 'Lima ',
    parent: '15'
  },
  {
    id: '1502',
    name: 'Barranca ',
    parent: '15'
  },
  {
    id: '1503',
    name: 'Cajatambo ',
    parent: '15'
  },
  {
    id: '1504',
    name: 'Canta ',
    parent: '15'
  },
  {
    id: '1505',
    name: 'Cañete ',
    parent: '15'
  },
  {
    id: '1506',
    name: 'Huaral ',
    parent: '15'
  },
  {
    id: '1507',
    name: 'Huarochirí ',
    parent: '15'
  },
  {
    id: '1508',
    name: 'Huaura ',
    parent: '15'
  },
  {
    id: '1509',
    name: 'Oyón ',
    parent: '15'
  },
  {
    id: '1510',
    name: 'Yauyos ',
    parent: '15'
  },
  {
    id: '1601',
    name: 'Maynas ',
    parent: '16'
  },
  {
    id: '1602',
    name: 'Alto Amazonas ',
    parent: '16'
  },
  {
    id: '1603',
    name: 'Loreto ',
    parent: '16'
  },
  {
    id: '1604',
    name: 'Mariscal Ramón Castilla ',
    parent: '16'
  },
  {
    id: '1605',
    name: 'Requena ',
    parent: '16'
  },
  {
    id: '1606',
    name: 'Ucayali ',
    parent: '16'
  },
  {
    id: '1607',
    name: 'Datem del Marañón ',
    parent: '16'
  },
  {
    id: '1608',
    name: 'Putumayo',
    parent: '16'
  },
  {
    id: '1701',
    name: 'Tambopata ',
    parent: '17'
  },
  {
    id: '1702',
    name: 'Manu ',
    parent: '17'
  },
  {
    id: '1703',
    name: 'Tahuamanu ',
    parent: '17'
  },
  {
    id: '1801',
    name: 'Mariscal Nieto ',
    parent: '18'
  },
  {
    id: '1802',
    name: 'General Sánchez Cerro ',
    parent: '18'
  },
  {
    id: '1803',
    name: 'Ilo ',
    parent: '18'
  },
  {
    id: '1901',
    name: 'Pasco ',
    parent: '19'
  },
  {
    id: '1902',
    name: 'Daniel Alcides Carrión ',
    parent: '19'
  },
  {
    id: '1903',
    name: 'Oxapampa ',
    parent: '19'
  },
  {
    id: '2001',
    name: 'Piura ',
    parent: '20'
  },
  {
    id: '2002',
    name: 'Ayabaca ',
    parent: '20'
  },
  {
    id: '2003',
    name: 'Huancabamba ',
    parent: '20'
  },
  {
    id: '2004',
    name: 'Morropón ',
    parent: '20'
  },
  {
    id: '2005',
    name: 'Paita ',
    parent: '20'
  },
  {
    id: '2006',
    name: 'Sullana ',
    parent: '20'
  },
  {
    id: '2007',
    name: 'Talara ',
    parent: '20'
  },
  {
    id: '2008',
    name: 'Sechura ',
    parent: '20'
  },
  {
    id: '2101',
    name: 'Puno ',
    parent: '21'
  },
  {
    id: '2102',
    name: 'Azángaro ',
    parent: '21'
  },
  {
    id: '2103',
    name: 'Carabaya ',
    parent: '21'
  },
  {
    id: '2104',
    name: 'Chucuito ',
    parent: '21'
  },
  {
    id: '2105',
    name: 'El Collao ',
    parent: '21'
  },
  {
    id: '2106',
    name: 'Huancané ',
    parent: '21'
  },
  {
    id: '2107',
    name: 'Lampa ',
    parent: '21'
  },
  {
    id: '2108',
    name: 'Melgar ',
    parent: '21'
  },
  {
    id: '2109',
    name: 'Moho ',
    parent: '21'
  },
  {
    id: '2110',
    name: 'San Antonio de Putina ',
    parent: '21'
  },
  {
    id: '2111',
    name: 'San Román ',
    parent: '21'
  },
  {
    id: '2112',
    name: 'Sandia ',
    parent: '21'
  },
  {
    id: '2113',
    name: 'Yunguyo ',
    parent: '21'
  },
  {
    id: '2201',
    name: 'Moyobamba ',
    parent: '22'
  },
  {
    id: '2202',
    name: 'Bellavista ',
    parent: '22'
  },
  {
    id: '2203',
    name: 'El Dorado ',
    parent: '22'
  },
  {
    id: '2204',
    name: 'Huallaga ',
    parent: '22'
  },
  {
    id: '2205',
    name: 'Lamas ',
    parent: '22'
  },
  {
    id: '2206',
    name: 'Mariscal Cáceres ',
    parent: '22'
  },
  {
    id: '2207',
    name: 'Picota ',
    parent: '22'
  },
  {
    id: '2208',
    name: 'Rioja ',
    parent: '22'
  },
  {
    id: '2209',
    name: 'San Martín ',
    parent: '22'
  },
  {
    id: '2210',
    name: 'Tocache ',
    parent: '22'
  },
  {
    id: '2301',
    name: 'Tacna ',
    parent: '23'
  },
  {
    id: '2302',
    name: 'Candarave ',
    parent: '23'
  },
  {
    id: '2303',
    name: 'Jorge Basadre ',
    parent: '23'
  },
  {
    id: '2304',
    name: 'Tarata ',
    parent: '23'
  },
  {
    id: '2401',
    name: 'Tumbes ',
    parent: '24'
  },
  {
    id: '2402',
    name: 'Contralmirante Villar ',
    parent: '24'
  },
  {
    id: '2403',
    name: 'Zarumilla ',
    parent: '24'
  },
  {
    id: '2501',
    name: 'Coronel Portillo ',
    parent: '25'
  },
  {
    id: '2502',
    name: 'Atalaya ',
    parent: '25'
  },
  {
    id: '2503',
    name: 'Padre Abad ',
    parent: '25'
  },
  {
    id: '2504',
    name: 'Purús',
    parent: '25'
  }
]

export const distrito = [
  {
    id: '010101',
    name: 'Chachapoyas',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010102',
    name: 'Asunción',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010103',
    name: 'Balsas',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010104',
    name: 'Cheto',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010105',
    name: 'Chiliquin',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010106',
    name: 'Chuquibamba',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010107',
    name: 'Granada',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010108',
    name: 'Huancas',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010109',
    name: 'La Jalca',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010110',
    name: 'Leimebamba',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010111',
    name: 'Levanto',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010112',
    name: 'Magdalena',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010113',
    name: 'Mariscal Castilla',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010114',
    name: 'Molinopampa',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010115',
    name: 'Montevideo',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010116',
    name: 'Olleros',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010117',
    name: 'Quinjalca',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010118',
    name: 'San Francisco de Daguas',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010119',
    name: 'San Isidro de Maino',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010120',
    name: 'Soloco',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010121',
    name: 'Sonche',
    parent: '0101',
    department_id: '01'
  },
  {
    id: '010201',
    name: 'Bagua',
    parent: '0102',
    department_id: '01'
  },
  {
    id: '010202',
    name: 'Aramango',
    parent: '0102',
    department_id: '01'
  },
  {
    id: '010203',
    name: 'Copallin',
    parent: '0102',
    department_id: '01'
  },
  {
    id: '010204',
    name: 'El Parco',
    parent: '0102',
    department_id: '01'
  },
  {
    id: '010205',
    name: 'Imaza',
    parent: '0102',
    department_id: '01'
  },
  {
    id: '010206',
    name: 'La Peca',
    parent: '0102',
    department_id: '01'
  },
  {
    id: '010301',
    name: 'Jumbilla',
    parent: '0103',
    department_id: '01'
  },
  {
    id: '010302',
    name: 'Chisquilla',
    parent: '0103',
    department_id: '01'
  },
  {
    id: '010303',
    name: 'Churuja',
    parent: '0103',
    department_id: '01'
  },
  {
    id: '010304',
    name: 'Corosha',
    parent: '0103',
    department_id: '01'
  },
  {
    id: '010305',
    name: 'Cuispes',
    parent: '0103',
    department_id: '01'
  },
  {
    id: '010306',
    name: 'Florida',
    parent: '0103',
    department_id: '01'
  },
  {
    id: '010307',
    name: 'Jazan',
    parent: '0103',
    department_id: '01'
  },
  {
    id: '010308',
    name: 'Recta',
    parent: '0103',
    department_id: '01'
  },
  {
    id: '010309',
    name: 'San Carlos',
    parent: '0103',
    department_id: '01'
  },
  {
    id: '010310',
    name: 'Shipasbamba',
    parent: '0103',
    department_id: '01'
  },
  {
    id: '010311',
    name: 'Valera',
    parent: '0103',
    department_id: '01'
  },
  {
    id: '010312',
    name: 'Yambrasbamba',
    parent: '0103',
    department_id: '01'
  },
  {
    id: '010401',
    name: 'Nieva',
    parent: '0104',
    department_id: '01'
  },
  {
    id: '010402',
    name: 'El Cenepa',
    parent: '0104',
    department_id: '01'
  },
  {
    id: '010403',
    name: 'Río Santiago',
    parent: '0104',
    department_id: '01'
  },
  {
    id: '010501',
    name: 'Lamud',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010502',
    name: 'Camporredondo',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010503',
    name: 'Cocabamba',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010504',
    name: 'Colcamar',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010505',
    name: 'Conila',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010506',
    name: 'Inguilpata',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010507',
    name: 'Longuita',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010508',
    name: 'Lonya Chico',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010509',
    name: 'Luya',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010510',
    name: 'Luya Viejo',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010511',
    name: 'María',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010512',
    name: 'Ocalli',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010513',
    name: 'Ocumal',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010514',
    name: 'Pisuquia',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010515',
    name: 'Providencia',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010516',
    name: 'San Cristóbal',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010517',
    name: 'San Francisco de Yeso',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010518',
    name: 'San Jerónimo',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010519',
    name: 'San Juan de Lopecancha',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010520',
    name: 'Santa Catalina',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010521',
    name: 'Santo Tomas',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010522',
    name: 'Tingo',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010523',
    name: 'Trita',
    parent: '0105',
    department_id: '01'
  },
  {
    id: '010601',
    name: 'San Nicolás',
    parent: '0106',
    department_id: '01'
  },
  {
    id: '010602',
    name: 'Chirimoto',
    parent: '0106',
    department_id: '01'
  },
  {
    id: '010603',
    name: 'Cochamal',
    parent: '0106',
    department_id: '01'
  },
  {
    id: '010604',
    name: 'Huambo',
    parent: '0106',
    department_id: '01'
  },
  {
    id: '010605',
    name: 'Limabamba',
    parent: '0106',
    department_id: '01'
  },
  {
    id: '010606',
    name: 'Longar',
    parent: '0106',
    department_id: '01'
  },
  {
    id: '010607',
    name: 'Mariscal Benavides',
    parent: '0106',
    department_id: '01'
  },
  {
    id: '010608',
    name: 'Milpuc',
    parent: '0106',
    department_id: '01'
  },
  {
    id: '010609',
    name: 'Omia',
    parent: '0106',
    department_id: '01'
  },
  {
    id: '010610',
    name: 'Santa Rosa',
    parent: '0106',
    department_id: '01'
  },
  {
    id: '010611',
    name: 'Totora',
    parent: '0106',
    department_id: '01'
  },
  {
    id: '010612',
    name: 'Vista Alegre',
    parent: '0106',
    department_id: '01'
  },
  {
    id: '010701',
    name: 'Bagua Grande',
    parent: '0107',
    department_id: '01'
  },
  {
    id: '010702',
    name: 'Cajaruro',
    parent: '0107',
    department_id: '01'
  },
  {
    id: '010703',
    name: 'Cumba',
    parent: '0107',
    department_id: '01'
  },
  {
    id: '010704',
    name: 'El Milagro',
    parent: '0107',
    department_id: '01'
  },
  {
    id: '010705',
    name: 'Jamalca',
    parent: '0107',
    department_id: '01'
  },
  {
    id: '010706',
    name: 'Lonya Grande',
    parent: '0107',
    department_id: '01'
  },
  {
    id: '010707',
    name: 'Yamon',
    parent: '0107',
    department_id: '01'
  },
  {
    id: '020101',
    name: 'Huaraz',
    parent: '0201',
    department_id: '02'
  },
  {
    id: '020102',
    name: 'Cochabamba',
    parent: '0201',
    department_id: '02'
  },
  {
    id: '020103',
    name: 'Colcabamba',
    parent: '0201',
    department_id: '02'
  },
  {
    id: '020104',
    name: 'Huanchay',
    parent: '0201',
    department_id: '02'
  },
  {
    id: '020105',
    name: 'Independencia',
    parent: '0201',
    department_id: '02'
  },
  {
    id: '020106',
    name: 'Jangas',
    parent: '0201',
    department_id: '02'
  },
  {
    id: '020107',
    name: 'La Libertad',
    parent: '0201',
    department_id: '02'
  },
  {
    id: '020108',
    name: 'Olleros',
    parent: '0201',
    department_id: '02'
  },
  {
    id: '020109',
    name: 'Pampas Grande',
    parent: '0201',
    department_id: '02'
  },
  {
    id: '020110',
    name: 'Pariacoto',
    parent: '0201',
    department_id: '02'
  },
  {
    id: '020111',
    name: 'Pira',
    parent: '0201',
    department_id: '02'
  },
  {
    id: '020112',
    name: 'Tarica',
    parent: '0201',
    department_id: '02'
  },
  {
    id: '020201',
    name: 'Aija',
    parent: '0202',
    department_id: '02'
  },
  {
    id: '020202',
    name: 'Coris',
    parent: '0202',
    department_id: '02'
  },
  {
    id: '020203',
    name: 'Huacllan',
    parent: '0202',
    department_id: '02'
  },
  {
    id: '020204',
    name: 'La Merced',
    parent: '0202',
    department_id: '02'
  },
  {
    id: '020205',
    name: 'Succha',
    parent: '0202',
    department_id: '02'
  },
  {
    id: '020301',
    name: 'Llamellin',
    parent: '0203',
    department_id: '02'
  },
  {
    id: '020302',
    name: 'Aczo',
    parent: '0203',
    department_id: '02'
  },
  {
    id: '020303',
    name: 'Chaccho',
    parent: '0203',
    department_id: '02'
  },
  {
    id: '020304',
    name: 'Chingas',
    parent: '0203',
    department_id: '02'
  },
  {
    id: '020305',
    name: 'Mirgas',
    parent: '0203',
    department_id: '02'
  },
  {
    id: '020306',
    name: 'San Juan de Rontoy',
    parent: '0203',
    department_id: '02'
  },
  {
    id: '020401',
    name: 'Chacas',
    parent: '0204',
    department_id: '02'
  },
  {
    id: '020402',
    name: 'Acochaca',
    parent: '0204',
    department_id: '02'
  },
  {
    id: '020501',
    name: 'Chiquian',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020502',
    name: 'Abelardo Pardo Lezameta',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020503',
    name: 'Antonio Raymondi',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020504',
    name: 'Aquia',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020505',
    name: 'Cajacay',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020506',
    name: 'Canis',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020507',
    name: 'Colquioc',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020508',
    name: 'Huallanca',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020509',
    name: 'Huasta',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020510',
    name: 'Huayllacayan',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020511',
    name: 'La Primavera',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020512',
    name: 'Mangas',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020513',
    name: 'Pacllon',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020514',
    name: 'San Miguel de Corpanqui',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020515',
    name: 'Ticllos',
    parent: '0205',
    department_id: '02'
  },
  {
    id: '020601',
    name: 'Carhuaz',
    parent: '0206',
    department_id: '02'
  },
  {
    id: '020602',
    name: 'Acopampa',
    parent: '0206',
    department_id: '02'
  },
  {
    id: '020603',
    name: 'Amashca',
    parent: '0206',
    department_id: '02'
  },
  {
    id: '020604',
    name: 'Anta',
    parent: '0206',
    department_id: '02'
  },
  {
    id: '020605',
    name: 'Ataquero',
    parent: '0206',
    department_id: '02'
  },
  {
    id: '020606',
    name: 'Marcara',
    parent: '0206',
    department_id: '02'
  },
  {
    id: '020607',
    name: 'Pariahuanca',
    parent: '0206',
    department_id: '02'
  },
  {
    id: '020608',
    name: 'San Miguel de Aco',
    parent: '0206',
    department_id: '02'
  },
  {
    id: '020609',
    name: 'Shilla',
    parent: '0206',
    department_id: '02'
  },
  {
    id: '020610',
    name: 'Tinco',
    parent: '0206',
    department_id: '02'
  },
  {
    id: '020611',
    name: 'Yungar',
    parent: '0206',
    department_id: '02'
  },
  {
    id: '020701',
    name: 'San Luis',
    parent: '0207',
    department_id: '02'
  },
  {
    id: '020702',
    name: 'San Nicolás',
    parent: '0207',
    department_id: '02'
  },
  {
    id: '020703',
    name: 'Yauya',
    parent: '0207',
    department_id: '02'
  },
  {
    id: '020801',
    name: 'Casma',
    parent: '0208',
    department_id: '02'
  },
  {
    id: '020802',
    name: 'Buena Vista Alta',
    parent: '0208',
    department_id: '02'
  },
  {
    id: '020803',
    name: 'Comandante Noel',
    parent: '0208',
    department_id: '02'
  },
  {
    id: '020804',
    name: 'Yautan',
    parent: '0208',
    department_id: '02'
  },
  {
    id: '020901',
    name: 'Corongo',
    parent: '0209',
    department_id: '02'
  },
  {
    id: '020902',
    name: 'Aco',
    parent: '0209',
    department_id: '02'
  },
  {
    id: '020903',
    name: 'Bambas',
    parent: '0209',
    department_id: '02'
  },
  {
    id: '020904',
    name: 'Cusca',
    parent: '0209',
    department_id: '02'
  },
  {
    id: '020905',
    name: 'La Pampa',
    parent: '0209',
    department_id: '02'
  },
  {
    id: '020906',
    name: 'Yanac',
    parent: '0209',
    department_id: '02'
  },
  {
    id: '020907',
    name: 'Yupan',
    parent: '0209',
    department_id: '02'
  },
  {
    id: '021001',
    name: 'Huari',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021002',
    name: 'Anra',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021003',
    name: 'Cajay',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021004',
    name: 'Chavin de Huantar',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021005',
    name: 'Huacachi',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021006',
    name: 'Huacchis',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021007',
    name: 'Huachis',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021008',
    name: 'Huantar',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021009',
    name: 'Masin',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021010',
    name: 'Paucas',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021011',
    name: 'Ponto',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021012',
    name: 'Rahuapampa',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021013',
    name: 'Rapayan',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021014',
    name: 'San Marcos',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021015',
    name: 'San Pedro de Chana',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021016',
    name: 'Uco',
    parent: '0210',
    department_id: '02'
  },
  {
    id: '021101',
    name: 'Huarmey',
    parent: '0211',
    department_id: '02'
  },
  {
    id: '021102',
    name: 'Cochapeti',
    parent: '0211',
    department_id: '02'
  },
  {
    id: '021103',
    name: 'Culebras',
    parent: '0211',
    department_id: '02'
  },
  {
    id: '021104',
    name: 'Huayan',
    parent: '0211',
    department_id: '02'
  },
  {
    id: '021105',
    name: 'Malvas',
    parent: '0211',
    department_id: '02'
  },
  {
    id: '021201',
    name: 'Caraz',
    parent: '0212',
    department_id: '02'
  },
  {
    id: '021202',
    name: 'Huallanca',
    parent: '0212',
    department_id: '02'
  },
  {
    id: '021203',
    name: 'Huata',
    parent: '0212',
    department_id: '02'
  },
  {
    id: '021204',
    name: 'Huaylas',
    parent: '0212',
    department_id: '02'
  },
  {
    id: '021205',
    name: 'Mato',
    parent: '0212',
    department_id: '02'
  },
  {
    id: '021206',
    name: 'Pamparomas',
    parent: '0212',
    department_id: '02'
  },
  {
    id: '021207',
    name: 'Pueblo Libre',
    parent: '0212',
    department_id: '02'
  },
  {
    id: '021208',
    name: 'Santa Cruz',
    parent: '0212',
    department_id: '02'
  },
  {
    id: '021209',
    name: 'Santo Toribio',
    parent: '0212',
    department_id: '02'
  },
  {
    id: '021210',
    name: 'Yuracmarca',
    parent: '0212',
    department_id: '02'
  },
  {
    id: '021301',
    name: 'Piscobamba',
    parent: '0213',
    department_id: '02'
  },
  {
    id: '021302',
    name: 'Casca',
    parent: '0213',
    department_id: '02'
  },
  {
    id: '021303',
    name: 'Eleazar Guzmán Barron',
    parent: '0213',
    department_id: '02'
  },
  {
    id: '021304',
    name: 'Fidel Olivas Escudero',
    parent: '0213',
    department_id: '02'
  },
  {
    id: '021305',
    name: 'Llama',
    parent: '0213',
    department_id: '02'
  },
  {
    id: '021306',
    name: 'Llumpa',
    parent: '0213',
    department_id: '02'
  },
  {
    id: '021307',
    name: 'Lucma',
    parent: '0213',
    department_id: '02'
  },
  {
    id: '021308',
    name: 'Musga',
    parent: '0213',
    department_id: '02'
  },
  {
    id: '021401',
    name: 'Ocros',
    parent: '0214',
    department_id: '02'
  },
  {
    id: '021402',
    name: 'Acas',
    parent: '0214',
    department_id: '02'
  },
  {
    id: '021403',
    name: 'Cajamarquilla',
    parent: '0214',
    department_id: '02'
  },
  {
    id: '021404',
    name: 'Carhuapampa',
    parent: '0214',
    department_id: '02'
  },
  {
    id: '021405',
    name: 'Cochas',
    parent: '0214',
    department_id: '02'
  },
  {
    id: '021406',
    name: 'Congas',
    parent: '0214',
    department_id: '02'
  },
  {
    id: '021407',
    name: 'Llipa',
    parent: '0214',
    department_id: '02'
  },
  {
    id: '021408',
    name: 'San Cristóbal de Rajan',
    parent: '0214',
    department_id: '02'
  },
  {
    id: '021409',
    name: 'San Pedro',
    parent: '0214',
    department_id: '02'
  },
  {
    id: '021410',
    name: 'Santiago de Chilcas',
    parent: '0214',
    department_id: '02'
  },
  {
    id: '021501',
    name: 'Cabana',
    parent: '0215',
    department_id: '02'
  },
  {
    id: '021502',
    name: 'Bolognesi',
    parent: '0215',
    department_id: '02'
  },
  {
    id: '021503',
    name: 'Conchucos',
    parent: '0215',
    department_id: '02'
  },
  {
    id: '021504',
    name: 'Huacaschuque',
    parent: '0215',
    department_id: '02'
  },
  {
    id: '021505',
    name: 'Huandoval',
    parent: '0215',
    department_id: '02'
  },
  {
    id: '021506',
    name: 'Lacabamba',
    parent: '0215',
    department_id: '02'
  },
  {
    id: '021507',
    name: 'Llapo',
    parent: '0215',
    department_id: '02'
  },
  {
    id: '021508',
    name: 'Pallasca',
    parent: '0215',
    department_id: '02'
  },
  {
    id: '021509',
    name: 'Pampas',
    parent: '0215',
    department_id: '02'
  },
  {
    id: '021510',
    name: 'Santa Rosa',
    parent: '0215',
    department_id: '02'
  },
  {
    id: '021511',
    name: 'Tauca',
    parent: '0215',
    department_id: '02'
  },
  {
    id: '021601',
    name: 'Pomabamba',
    parent: '0216',
    department_id: '02'
  },
  {
    id: '021602',
    name: 'Huayllan',
    parent: '0216',
    department_id: '02'
  },
  {
    id: '021603',
    name: 'Parobamba',
    parent: '0216',
    department_id: '02'
  },
  {
    id: '021604',
    name: 'Quinuabamba',
    parent: '0216',
    department_id: '02'
  },
  {
    id: '021701',
    name: 'Recuay',
    parent: '0217',
    department_id: '02'
  },
  {
    id: '021702',
    name: 'Catac',
    parent: '0217',
    department_id: '02'
  },
  {
    id: '021703',
    name: 'Cotaparaco',
    parent: '0217',
    department_id: '02'
  },
  {
    id: '021704',
    name: 'Huayllapampa',
    parent: '0217',
    department_id: '02'
  },
  {
    id: '021705',
    name: 'Llacllin',
    parent: '0217',
    department_id: '02'
  },
  {
    id: '021706',
    name: 'Marca',
    parent: '0217',
    department_id: '02'
  },
  {
    id: '021707',
    name: 'Pampas Chico',
    parent: '0217',
    department_id: '02'
  },
  {
    id: '021708',
    name: 'Pararin',
    parent: '0217',
    department_id: '02'
  },
  {
    id: '021709',
    name: 'Tapacocha',
    parent: '0217',
    department_id: '02'
  },
  {
    id: '021710',
    name: 'Ticapampa',
    parent: '0217',
    department_id: '02'
  },
  {
    id: '021801',
    name: 'Chimbote',
    parent: '0218',
    department_id: '02'
  },
  {
    id: '021802',
    name: 'Cáceres del Perú',
    parent: '0218',
    department_id: '02'
  },
  {
    id: '021803',
    name: 'Coishco',
    parent: '0218',
    department_id: '02'
  },
  {
    id: '021804',
    name: 'Macate',
    parent: '0218',
    department_id: '02'
  },
  {
    id: '021805',
    name: 'Moro',
    parent: '0218',
    department_id: '02'
  },
  {
    id: '021806',
    name: 'Nepeña',
    parent: '0218',
    department_id: '02'
  },
  {
    id: '021807',
    name: 'Samanco',
    parent: '0218',
    department_id: '02'
  },
  {
    id: '021808',
    name: 'Santa',
    parent: '0218',
    department_id: '02'
  },
  {
    id: '021809',
    name: 'Nuevo Chimbote',
    parent: '0218',
    department_id: '02'
  },
  {
    id: '021901',
    name: 'Sihuas',
    parent: '0219',
    department_id: '02'
  },
  {
    id: '021902',
    name: 'Acobamba',
    parent: '0219',
    department_id: '02'
  },
  {
    id: '021903',
    name: 'Alfonso Ugarte',
    parent: '0219',
    department_id: '02'
  },
  {
    id: '021904',
    name: 'Cashapampa',
    parent: '0219',
    department_id: '02'
  },
  {
    id: '021905',
    name: 'Chingalpo',
    parent: '0219',
    department_id: '02'
  },
  {
    id: '021906',
    name: 'Huayllabamba',
    parent: '0219',
    department_id: '02'
  },
  {
    id: '021907',
    name: 'Quiches',
    parent: '0219',
    department_id: '02'
  },
  {
    id: '021908',
    name: 'Ragash',
    parent: '0219',
    department_id: '02'
  },
  {
    id: '021909',
    name: 'San Juan',
    parent: '0219',
    department_id: '02'
  },
  {
    id: '021910',
    name: 'Sicsibamba',
    parent: '0219',
    department_id: '02'
  },
  {
    id: '022001',
    name: 'Yungay',
    parent: '0220',
    department_id: '02'
  },
  {
    id: '022002',
    name: 'Cascapara',
    parent: '0220',
    department_id: '02'
  },
  {
    id: '022003',
    name: 'Mancos',
    parent: '0220',
    department_id: '02'
  },
  {
    id: '022004',
    name: 'Matacoto',
    parent: '0220',
    department_id: '02'
  },
  {
    id: '022005',
    name: 'Quillo',
    parent: '0220',
    department_id: '02'
  },
  {
    id: '022006',
    name: 'Ranrahirca',
    parent: '0220',
    department_id: '02'
  },
  {
    id: '022007',
    name: 'Shupluy',
    parent: '0220',
    department_id: '02'
  },
  {
    id: '022008',
    name: 'Yanama',
    parent: '0220',
    department_id: '02'
  },
  {
    id: '030101',
    name: 'Abancay',
    parent: '0301',
    department_id: '03'
  },
  {
    id: '030102',
    name: 'Chacoche',
    parent: '0301',
    department_id: '03'
  },
  {
    id: '030103',
    name: 'Circa',
    parent: '0301',
    department_id: '03'
  },
  {
    id: '030104',
    name: 'Curahuasi',
    parent: '0301',
    department_id: '03'
  },
  {
    id: '030105',
    name: 'Huanipaca',
    parent: '0301',
    department_id: '03'
  },
  {
    id: '030106',
    name: 'Lambrama',
    parent: '0301',
    department_id: '03'
  },
  {
    id: '030107',
    name: 'Pichirhua',
    parent: '0301',
    department_id: '03'
  },
  {
    id: '030108',
    name: 'San Pedro de Cachora',
    parent: '0301',
    department_id: '03'
  },
  {
    id: '030109',
    name: 'Tamburco',
    parent: '0301',
    department_id: '03'
  },
  {
    id: '030201',
    name: 'Andahuaylas',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030202',
    name: 'Andarapa',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030203',
    name: 'Chiara',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030204',
    name: 'Huancarama',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030205',
    name: 'Huancaray',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030206',
    name: 'Huayana',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030207',
    name: 'Kishuara',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030208',
    name: 'Pacobamba',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030209',
    name: 'Pacucha',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030210',
    name: 'Pampachiri',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030211',
    name: 'Pomacocha',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030212',
    name: 'San Antonio de Cachi',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030213',
    name: 'San Jerónimo',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030214',
    name: 'San Miguel de Chaccrampa',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030215',
    name: 'Santa María de Chicmo',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030216',
    name: 'Talavera',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030217',
    name: 'Tumay Huaraca',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030218',
    name: 'Turpo',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030219',
    name: 'Kaquiabamba',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030220',
    name: 'José María Arguedas',
    parent: '0302',
    department_id: '03'
  },
  {
    id: '030301',
    name: 'Antabamba',
    parent: '0303',
    department_id: '03'
  },
  {
    id: '030302',
    name: 'El Oro',
    parent: '0303',
    department_id: '03'
  },
  {
    id: '030303',
    name: 'Huaquirca',
    parent: '0303',
    department_id: '03'
  },
  {
    id: '030304',
    name: 'Juan Espinoza Medrano',
    parent: '0303',
    department_id: '03'
  },
  {
    id: '030305',
    name: 'Oropesa',
    parent: '0303',
    department_id: '03'
  },
  {
    id: '030306',
    name: 'Pachaconas',
    parent: '0303',
    department_id: '03'
  },
  {
    id: '030307',
    name: 'Sabaino',
    parent: '0303',
    department_id: '03'
  },
  {
    id: '030401',
    name: 'Chalhuanca',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030402',
    name: 'Capaya',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030403',
    name: 'Caraybamba',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030404',
    name: 'Chapimarca',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030405',
    name: 'Colcabamba',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030406',
    name: 'Cotaruse',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030407',
    name: 'Ihuayllo',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030408',
    name: 'Justo Apu Sahuaraura',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030409',
    name: 'Lucre',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030410',
    name: 'Pocohuanca',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030411',
    name: 'San Juan de Chacña',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030412',
    name: 'Sañayca',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030413',
    name: 'Soraya',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030414',
    name: 'Tapairihua',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030415',
    name: 'Tintay',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030416',
    name: 'Toraya',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030417',
    name: 'Yanaca',
    parent: '0304',
    department_id: '03'
  },
  {
    id: '030501',
    name: 'Tambobamba',
    parent: '0305',
    department_id: '03'
  },
  {
    id: '030502',
    name: 'Cotabambas',
    parent: '0305',
    department_id: '03'
  },
  {
    id: '030503',
    name: 'Coyllurqui',
    parent: '0305',
    department_id: '03'
  },
  {
    id: '030504',
    name: 'Haquira',
    parent: '0305',
    department_id: '03'
  },
  {
    id: '030505',
    name: 'Mara',
    parent: '0305',
    department_id: '03'
  },
  {
    id: '030506',
    name: 'Challhuahuacho',
    parent: '0305',
    department_id: '03'
  },
  {
    id: '030601',
    name: 'Chincheros',
    parent: '0306',
    department_id: '03'
  },
  {
    id: '030602',
    name: 'Anco_Huallo',
    parent: '0306',
    department_id: '03'
  },
  {
    id: '030603',
    name: 'Cocharcas',
    parent: '0306',
    department_id: '03'
  },
  {
    id: '030604',
    name: 'Huaccana',
    parent: '0306',
    department_id: '03'
  },
  {
    id: '030605',
    name: 'Ocobamba',
    parent: '0306',
    department_id: '03'
  },
  {
    id: '030606',
    name: 'Ongoy',
    parent: '0306',
    department_id: '03'
  },
  {
    id: '030607',
    name: 'Uranmarca',
    parent: '0306',
    department_id: '03'
  },
  {
    id: '030608',
    name: 'Ranracancha',
    parent: '0306',
    department_id: '03'
  },
  {
    id: '030609',
    name: 'Rocchacc',
    parent: '0306',
    department_id: '03'
  },
  {
    id: '030610',
    name: 'El Porvenir',
    parent: '0306',
    department_id: '03'
  },
  {
    id: '030611',
    name: 'Los Chankas',
    parent: '0306',
    department_id: '03'
  },
  {
    id: '030701',
    name: 'Chuquibambilla',
    parent: '0307',
    department_id: '03'
  },
  {
    id: '030702',
    name: 'Curpahuasi',
    parent: '0307',
    department_id: '03'
  },
  {
    id: '030703',
    name: 'Gamarra',
    parent: '0307',
    department_id: '03'
  },
  {
    id: '030704',
    name: 'Huayllati',
    parent: '0307',
    department_id: '03'
  },
  {
    id: '030705',
    name: 'Mamara',
    parent: '0307',
    department_id: '03'
  },
  {
    id: '030706',
    name: 'Micaela Bastidas',
    parent: '0307',
    department_id: '03'
  },
  {
    id: '030707',
    name: 'Pataypampa',
    parent: '0307',
    department_id: '03'
  },
  {
    id: '030708',
    name: 'Progreso',
    parent: '0307',
    department_id: '03'
  },
  {
    id: '030709',
    name: 'San Antonio',
    parent: '0307',
    department_id: '03'
  },
  {
    id: '030710',
    name: 'Santa Rosa',
    parent: '0307',
    department_id: '03'
  },
  {
    id: '030711',
    name: 'Turpay',
    parent: '0307',
    department_id: '03'
  },
  {
    id: '030712',
    name: 'Vilcabamba',
    parent: '0307',
    department_id: '03'
  },
  {
    id: '030713',
    name: 'Virundo',
    parent: '0307',
    department_id: '03'
  },
  {
    id: '030714',
    name: 'Curasco',
    parent: '0307',
    department_id: '03'
  },
  {
    id: '040101',
    name: 'Arequipa',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040102',
    name: 'Alto Selva Alegre',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040103',
    name: 'Cayma',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040104',
    name: 'Cerro Colorado',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040105',
    name: 'Characato',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040106',
    name: 'Chiguata',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040107',
    name: 'Jacobo Hunter',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040108',
    name: 'La Joya',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040109',
    name: 'Mariano Melgar',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040110',
    name: 'Miraflores',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040111',
    name: 'Mollebaya',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040112',
    name: 'Paucarpata',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040113',
    name: 'Pocsi',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040114',
    name: 'Polobaya',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040115',
    name: 'Quequeña',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040116',
    name: 'Sabandia',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040117',
    name: 'Sachaca',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040118',
    name: 'San Juan de Siguas',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040119',
    name: 'San Juan de Tarucani',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040120',
    name: 'Santa Isabel de Siguas',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040121',
    name: 'Santa Rita de Siguas',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040122',
    name: 'Socabaya',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040123',
    name: 'Tiabaya',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040124',
    name: 'Uchumayo',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040125',
    name: 'Vitor',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040126',
    name: 'Yanahuara',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040127',
    name: 'Yarabamba',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040128',
    name: 'Yura',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040129',
    name: 'José Luis Bustamante Y Rivero',
    parent: '0401',
    department_id: '04'
  },
  {
    id: '040201',
    name: 'Camaná',
    parent: '0402',
    department_id: '04'
  },
  {
    id: '040202',
    name: 'José María Quimper',
    parent: '0402',
    department_id: '04'
  },
  {
    id: '040203',
    name: 'Mariano Nicolás Valcárcel',
    parent: '0402',
    department_id: '04'
  },
  {
    id: '040204',
    name: 'Mariscal Cáceres',
    parent: '0402',
    department_id: '04'
  },
  {
    id: '040205',
    name: 'Nicolás de Pierola',
    parent: '0402',
    department_id: '04'
  },
  {
    id: '040206',
    name: 'Ocoña',
    parent: '0402',
    department_id: '04'
  },
  {
    id: '040207',
    name: 'Quilca',
    parent: '0402',
    department_id: '04'
  },
  {
    id: '040208',
    name: 'Samuel Pastor',
    parent: '0402',
    department_id: '04'
  },
  {
    id: '040301',
    name: 'Caravelí',
    parent: '0403',
    department_id: '04'
  },
  {
    id: '040302',
    name: 'Acarí',
    parent: '0403',
    department_id: '04'
  },
  {
    id: '040303',
    name: 'Atico',
    parent: '0403',
    department_id: '04'
  },
  {
    id: '040304',
    name: 'Atiquipa',
    parent: '0403',
    department_id: '04'
  },
  {
    id: '040305',
    name: 'Bella Unión',
    parent: '0403',
    department_id: '04'
  },
  {
    id: '040306',
    name: 'Cahuacho',
    parent: '0403',
    department_id: '04'
  },
  {
    id: '040307',
    name: 'Chala',
    parent: '0403',
    department_id: '04'
  },
  {
    id: '040308',
    name: 'Chaparra',
    parent: '0403',
    department_id: '04'
  },
  {
    id: '040309',
    name: 'Huanuhuanu',
    parent: '0403',
    department_id: '04'
  },
  {
    id: '040310',
    name: 'Jaqui',
    parent: '0403',
    department_id: '04'
  },
  {
    id: '040311',
    name: 'Lomas',
    parent: '0403',
    department_id: '04'
  },
  {
    id: '040312',
    name: 'Quicacha',
    parent: '0403',
    department_id: '04'
  },
  {
    id: '040313',
    name: 'Yauca',
    parent: '0403',
    department_id: '04'
  },
  {
    id: '040401',
    name: 'Aplao',
    parent: '0404',
    department_id: '04'
  },
  {
    id: '040402',
    name: 'Andagua',
    parent: '0404',
    department_id: '04'
  },
  {
    id: '040403',
    name: 'Ayo',
    parent: '0404',
    department_id: '04'
  },
  {
    id: '040404',
    name: 'Chachas',
    parent: '0404',
    department_id: '04'
  },
  {
    id: '040405',
    name: 'Chilcaymarca',
    parent: '0404',
    department_id: '04'
  },
  {
    id: '040406',
    name: 'Choco',
    parent: '0404',
    department_id: '04'
  },
  {
    id: '040407',
    name: 'Huancarqui',
    parent: '0404',
    department_id: '04'
  },
  {
    id: '040408',
    name: 'Machaguay',
    parent: '0404',
    department_id: '04'
  },
  {
    id: '040409',
    name: 'Orcopampa',
    parent: '0404',
    department_id: '04'
  },
  {
    id: '040410',
    name: 'Pampacolca',
    parent: '0404',
    department_id: '04'
  },
  {
    id: '040411',
    name: 'Tipan',
    parent: '0404',
    department_id: '04'
  },
  {
    id: '040412',
    name: 'Uñon',
    parent: '0404',
    department_id: '04'
  },
  {
    id: '040413',
    name: 'Uraca',
    parent: '0404',
    department_id: '04'
  },
  {
    id: '040414',
    name: 'Viraco',
    parent: '0404',
    department_id: '04'
  },
  {
    id: '040501',
    name: 'Chivay',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040502',
    name: 'Achoma',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040503',
    name: 'Cabanaconde',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040504',
    name: 'Callalli',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040505',
    name: 'Caylloma',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040506',
    name: 'Coporaque',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040507',
    name: 'Huambo',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040508',
    name: 'Huanca',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040509',
    name: 'Ichupampa',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040510',
    name: 'Lari',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040511',
    name: 'Lluta',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040512',
    name: 'Maca',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040513',
    name: 'Madrigal',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040514',
    name: 'San Antonio de Chuca',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040515',
    name: 'Sibayo',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040516',
    name: 'Tapay',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040517',
    name: 'Tisco',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040518',
    name: 'Tuti',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040519',
    name: 'Yanque',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040520',
    name: 'Majes',
    parent: '0405',
    department_id: '04'
  },
  {
    id: '040601',
    name: 'Chuquibamba',
    parent: '0406',
    department_id: '04'
  },
  {
    id: '040602',
    name: 'Andaray',
    parent: '0406',
    department_id: '04'
  },
  {
    id: '040603',
    name: 'Cayarani',
    parent: '0406',
    department_id: '04'
  },
  {
    id: '040604',
    name: 'Chichas',
    parent: '0406',
    department_id: '04'
  },
  {
    id: '040605',
    name: 'Iray',
    parent: '0406',
    department_id: '04'
  },
  {
    id: '040606',
    name: 'Río Grande',
    parent: '0406',
    department_id: '04'
  },
  {
    id: '040607',
    name: 'Salamanca',
    parent: '0406',
    department_id: '04'
  },
  {
    id: '040608',
    name: 'Yanaquihua',
    parent: '0406',
    department_id: '04'
  },
  {
    id: '040701',
    name: 'Mollendo',
    parent: '0407',
    department_id: '04'
  },
  {
    id: '040702',
    name: 'Cocachacra',
    parent: '0407',
    department_id: '04'
  },
  {
    id: '040703',
    name: 'Dean Valdivia',
    parent: '0407',
    department_id: '04'
  },
  {
    id: '040704',
    name: 'Islay',
    parent: '0407',
    department_id: '04'
  },
  {
    id: '040705',
    name: 'Mejia',
    parent: '0407',
    department_id: '04'
  },
  {
    id: '040706',
    name: 'Punta de Bombón',
    parent: '0407',
    department_id: '04'
  },
  {
    id: '040801',
    name: 'Cotahuasi',
    parent: '0408',
    department_id: '04'
  },
  {
    id: '040802',
    name: 'Alca',
    parent: '0408',
    department_id: '04'
  },
  {
    id: '040803',
    name: 'Charcana',
    parent: '0408',
    department_id: '04'
  },
  {
    id: '040804',
    name: 'Huaynacotas',
    parent: '0408',
    department_id: '04'
  },
  {
    id: '040805',
    name: 'Pampamarca',
    parent: '0408',
    department_id: '04'
  },
  {
    id: '040806',
    name: 'Puyca',
    parent: '0408',
    department_id: '04'
  },
  {
    id: '040807',
    name: 'Quechualla',
    parent: '0408',
    department_id: '04'
  },
  {
    id: '040808',
    name: 'Sayla',
    parent: '0408',
    department_id: '04'
  },
  {
    id: '040809',
    name: 'Tauria',
    parent: '0408',
    department_id: '04'
  },
  {
    id: '040810',
    name: 'Tomepampa',
    parent: '0408',
    department_id: '04'
  },
  {
    id: '040811',
    name: 'Toro',
    parent: '0408',
    department_id: '04'
  },
  {
    id: '050101',
    name: 'Ayacucho',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050102',
    name: 'Acocro',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050103',
    name: 'Acos Vinchos',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050104',
    name: 'Carmen Alto',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050105',
    name: 'Chiara',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050106',
    name: 'Ocros',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050107',
    name: 'Pacaycasa',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050108',
    name: 'Quinua',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050109',
    name: 'San José de Ticllas',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050110',
    name: 'San Juan Bautista',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050111',
    name: 'Santiago de Pischa',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050112',
    name: 'Socos',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050113',
    name: 'Tambillo',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050114',
    name: 'Vinchos',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050115',
    name: 'Jesús Nazareno',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050116',
    name: 'Andrés Avelino Cáceres Dorregaray',
    parent: '0501',
    department_id: '05'
  },
  {
    id: '050201',
    name: 'Cangallo',
    parent: '0502',
    department_id: '05'
  },
  {
    id: '050202',
    name: 'Chuschi',
    parent: '0502',
    department_id: '05'
  },
  {
    id: '050203',
    name: 'Los Morochucos',
    parent: '0502',
    department_id: '05'
  },
  {
    id: '050204',
    name: 'María Parado de Bellido',
    parent: '0502',
    department_id: '05'
  },
  {
    id: '050205',
    name: 'Paras',
    parent: '0502',
    department_id: '05'
  },
  {
    id: '050206',
    name: 'Totos',
    parent: '0502',
    department_id: '05'
  },
  {
    id: '050301',
    name: 'Sancos',
    parent: '0503',
    department_id: '05'
  },
  {
    id: '050302',
    name: 'Carapo',
    parent: '0503',
    department_id: '05'
  },
  {
    id: '050303',
    name: 'Sacsamarca',
    parent: '0503',
    department_id: '05'
  },
  {
    id: '050304',
    name: 'Santiago de Lucanamarca',
    parent: '0503',
    department_id: '05'
  },
  {
    id: '050401',
    name: 'Huanta',
    parent: '0504',
    department_id: '05'
  },
  {
    id: '050402',
    name: 'Ayahuanco',
    parent: '0504',
    department_id: '05'
  },
  {
    id: '050403',
    name: 'Huamanguilla',
    parent: '0504',
    department_id: '05'
  },
  {
    id: '050404',
    name: 'Iguain',
    parent: '0504',
    department_id: '05'
  },
  {
    id: '050405',
    name: 'Luricocha',
    parent: '0504',
    department_id: '05'
  },
  {
    id: '050406',
    name: 'Santillana',
    parent: '0504',
    department_id: '05'
  },
  {
    id: '050407',
    name: 'Sivia',
    parent: '0504',
    department_id: '05'
  },
  {
    id: '050408',
    name: 'Llochegua',
    parent: '0504',
    department_id: '05'
  },
  {
    id: '050409',
    name: 'Canayre',
    parent: '0504',
    department_id: '05'
  },
  {
    id: '050410',
    name: 'Uchuraccay',
    parent: '0504',
    department_id: '05'
  },
  {
    id: '050411',
    name: 'Pucacolpa',
    parent: '0504',
    department_id: '05'
  },
  {
    id: '050412',
    name: 'Chaca',
    parent: '0504',
    department_id: '05'
  },
  {
    id: '050501',
    name: 'San Miguel',
    parent: '0505',
    department_id: '05'
  },
  {
    id: '050502',
    name: 'Anco',
    parent: '0505',
    department_id: '05'
  },
  {
    id: '050503',
    name: 'Ayna',
    parent: '0505',
    department_id: '05'
  },
  {
    id: '050504',
    name: 'Chilcas',
    parent: '0505',
    department_id: '05'
  },
  {
    id: '050505',
    name: 'Chungui',
    parent: '0505',
    department_id: '05'
  },
  {
    id: '050506',
    name: 'Luis Carranza',
    parent: '0505',
    department_id: '05'
  },
  {
    id: '050507',
    name: 'Santa Rosa',
    parent: '0505',
    department_id: '05'
  },
  {
    id: '050508',
    name: 'Tambo',
    parent: '0505',
    department_id: '05'
  },
  {
    id: '050509',
    name: 'Samugari',
    parent: '0505',
    department_id: '05'
  },
  {
    id: '050510',
    name: 'Anchihuay',
    parent: '0505',
    department_id: '05'
  },
  {
    id: '050511',
    name: 'Oronccoy',
    parent: '0505',
    department_id: '05'
  },
  {
    id: '050601',
    name: 'Puquio',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050602',
    name: 'Aucara',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050603',
    name: 'Cabana',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050604',
    name: 'Carmen Salcedo',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050605',
    name: 'Chaviña',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050606',
    name: 'Chipao',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050607',
    name: 'Huac-Huas',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050608',
    name: 'Laramate',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050609',
    name: 'Leoncio Prado',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050610',
    name: 'Llauta',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050611',
    name: 'Lucanas',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050612',
    name: 'Ocaña',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050613',
    name: 'Otoca',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050614',
    name: 'Saisa',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050615',
    name: 'San Cristóbal',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050616',
    name: 'San Juan',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050617',
    name: 'San Pedro',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050618',
    name: 'San Pedro de Palco',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050619',
    name: 'Sancos',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050620',
    name: 'Santa Ana de Huaycahuacho',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050621',
    name: 'Santa Lucia',
    parent: '0506',
    department_id: '05'
  },
  {
    id: '050701',
    name: 'Coracora',
    parent: '0507',
    department_id: '05'
  },
  {
    id: '050702',
    name: 'Chumpi',
    parent: '0507',
    department_id: '05'
  },
  {
    id: '050703',
    name: 'Coronel Castañeda',
    parent: '0507',
    department_id: '05'
  },
  {
    id: '050704',
    name: 'Pacapausa',
    parent: '0507',
    department_id: '05'
  },
  {
    id: '050705',
    name: 'Pullo',
    parent: '0507',
    department_id: '05'
  },
  {
    id: '050706',
    name: 'Puyusca',
    parent: '0507',
    department_id: '05'
  },
  {
    id: '050707',
    name: 'San Francisco de Ravacayco',
    parent: '0507',
    department_id: '05'
  },
  {
    id: '050708',
    name: 'Upahuacho',
    parent: '0507',
    department_id: '05'
  },
  {
    id: '050801',
    name: 'Pausa',
    parent: '0508',
    department_id: '05'
  },
  {
    id: '050802',
    name: 'Colta',
    parent: '0508',
    department_id: '05'
  },
  {
    id: '050803',
    name: 'Corculla',
    parent: '0508',
    department_id: '05'
  },
  {
    id: '050804',
    name: 'Lampa',
    parent: '0508',
    department_id: '05'
  },
  {
    id: '050805',
    name: 'Marcabamba',
    parent: '0508',
    department_id: '05'
  },
  {
    id: '050806',
    name: 'Oyolo',
    parent: '0508',
    department_id: '05'
  },
  {
    id: '050807',
    name: 'Pararca',
    parent: '0508',
    department_id: '05'
  },
  {
    id: '050808',
    name: 'San Javier de Alpabamba',
    parent: '0508',
    department_id: '05'
  },
  {
    id: '050809',
    name: 'San José de Ushua',
    parent: '0508',
    department_id: '05'
  },
  {
    id: '050810',
    name: 'Sara Sara',
    parent: '0508',
    department_id: '05'
  },
  {
    id: '050901',
    name: 'Querobamba',
    parent: '0509',
    department_id: '05'
  },
  {
    id: '050902',
    name: 'Belén',
    parent: '0509',
    department_id: '05'
  },
  {
    id: '050903',
    name: 'Chalcos',
    parent: '0509',
    department_id: '05'
  },
  {
    id: '050904',
    name: 'Chilcayoc',
    parent: '0509',
    department_id: '05'
  },
  {
    id: '050905',
    name: 'Huacaña',
    parent: '0509',
    department_id: '05'
  },
  {
    id: '050906',
    name: 'Morcolla',
    parent: '0509',
    department_id: '05'
  },
  {
    id: '050907',
    name: 'Paico',
    parent: '0509',
    department_id: '05'
  },
  {
    id: '050908',
    name: 'San Pedro de Larcay',
    parent: '0509',
    department_id: '05'
  },
  {
    id: '050909',
    name: 'San Salvador de Quije',
    parent: '0509',
    department_id: '05'
  },
  {
    id: '050910',
    name: 'Santiago de Paucaray',
    parent: '0509',
    department_id: '05'
  },
  {
    id: '050911',
    name: 'Soras',
    parent: '0509',
    department_id: '05'
  },
  {
    id: '051001',
    name: 'Huancapi',
    parent: '0510',
    department_id: '05'
  },
  {
    id: '051002',
    name: 'Alcamenca',
    parent: '0510',
    department_id: '05'
  },
  {
    id: '051003',
    name: 'Apongo',
    parent: '0510',
    department_id: '05'
  },
  {
    id: '051004',
    name: 'Asquipata',
    parent: '0510',
    department_id: '05'
  },
  {
    id: '051005',
    name: 'Canaria',
    parent: '0510',
    department_id: '05'
  },
  {
    id: '051006',
    name: 'Cayara',
    parent: '0510',
    department_id: '05'
  },
  {
    id: '051007',
    name: 'Colca',
    parent: '0510',
    department_id: '05'
  },
  {
    id: '051008',
    name: 'Huamanquiquia',
    parent: '0510',
    department_id: '05'
  },
  {
    id: '051009',
    name: 'Huancaraylla',
    parent: '0510',
    department_id: '05'
  },
  {
    id: '051010',
    name: 'Hualla',
    parent: '0510',
    department_id: '05'
  },
  {
    id: '051011',
    name: 'Sarhua',
    parent: '0510',
    department_id: '05'
  },
  {
    id: '051012',
    name: 'Vilcanchos',
    parent: '0510',
    department_id: '05'
  },
  {
    id: '051101',
    name: 'Vilcas Huaman',
    parent: '0511',
    department_id: '05'
  },
  {
    id: '051102',
    name: 'Accomarca',
    parent: '0511',
    department_id: '05'
  },
  {
    id: '051103',
    name: 'Carhuanca',
    parent: '0511',
    department_id: '05'
  },
  {
    id: '051104',
    name: 'Concepción',
    parent: '0511',
    department_id: '05'
  },
  {
    id: '051105',
    name: 'Huambalpa',
    parent: '0511',
    department_id: '05'
  },
  {
    id: '051106',
    name: 'Independencia',
    parent: '0511',
    department_id: '05'
  },
  {
    id: '051107',
    name: 'Saurama',
    parent: '0511',
    department_id: '05'
  },
  {
    id: '051108',
    name: 'Vischongo',
    parent: '0511',
    department_id: '05'
  },
  {
    id: '060101',
    name: 'Cajamarca',
    parent: '0601',
    department_id: '06'
  },
  {
    id: '060102',
    name: 'Asunción',
    parent: '0601',
    department_id: '06'
  },
  {
    id: '060103',
    name: 'Chetilla',
    parent: '0601',
    department_id: '06'
  },
  {
    id: '060104',
    name: 'Cospan',
    parent: '0601',
    department_id: '06'
  },
  {
    id: '060105',
    name: 'Encañada',
    parent: '0601',
    department_id: '06'
  },
  {
    id: '060106',
    name: 'Jesús',
    parent: '0601',
    department_id: '06'
  },
  {
    id: '060107',
    name: 'Llacanora',
    parent: '0601',
    department_id: '06'
  },
  {
    id: '060108',
    name: 'Los Baños del Inca',
    parent: '0601',
    department_id: '06'
  },
  {
    id: '060109',
    name: 'Magdalena',
    parent: '0601',
    department_id: '06'
  },
  {
    id: '060110',
    name: 'Matara',
    parent: '0601',
    department_id: '06'
  },
  {
    id: '060111',
    name: 'Namora',
    parent: '0601',
    department_id: '06'
  },
  {
    id: '060112',
    name: 'San Juan',
    parent: '0601',
    department_id: '06'
  },
  {
    id: '060201',
    name: 'Cajabamba',
    parent: '0602',
    department_id: '06'
  },
  {
    id: '060202',
    name: 'Cachachi',
    parent: '0602',
    department_id: '06'
  },
  {
    id: '060203',
    name: 'Condebamba',
    parent: '0602',
    department_id: '06'
  },
  {
    id: '060204',
    name: 'Sitacocha',
    parent: '0602',
    department_id: '06'
  },
  {
    id: '060301',
    name: 'Celendín',
    parent: '0603',
    department_id: '06'
  },
  {
    id: '060302',
    name: 'Chumuch',
    parent: '0603',
    department_id: '06'
  },
  {
    id: '060303',
    name: 'Cortegana',
    parent: '0603',
    department_id: '06'
  },
  {
    id: '060304',
    name: 'Huasmin',
    parent: '0603',
    department_id: '06'
  },
  {
    id: '060305',
    name: 'Jorge Chávez',
    parent: '0603',
    department_id: '06'
  },
  {
    id: '060306',
    name: 'José Gálvez',
    parent: '0603',
    department_id: '06'
  },
  {
    id: '060307',
    name: 'Miguel Iglesias',
    parent: '0603',
    department_id: '06'
  },
  {
    id: '060308',
    name: 'Oxamarca',
    parent: '0603',
    department_id: '06'
  },
  {
    id: '060309',
    name: 'Sorochuco',
    parent: '0603',
    department_id: '06'
  },
  {
    id: '060310',
    name: 'Sucre',
    parent: '0603',
    department_id: '06'
  },
  {
    id: '060311',
    name: 'Utco',
    parent: '0603',
    department_id: '06'
  },
  {
    id: '060312',
    name: 'La Libertad de Pallan',
    parent: '0603',
    department_id: '06'
  },
  {
    id: '060401',
    name: 'Chota',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060402',
    name: 'Anguia',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060403',
    name: 'Chadin',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060404',
    name: 'Chiguirip',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060405',
    name: 'Chimban',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060406',
    name: 'Choropampa',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060407',
    name: 'Cochabamba',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060408',
    name: 'Conchan',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060409',
    name: 'Huambos',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060410',
    name: 'Lajas',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060411',
    name: 'Llama',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060412',
    name: 'Miracosta',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060413',
    name: 'Paccha',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060414',
    name: 'Pion',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060415',
    name: 'Querocoto',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060416',
    name: 'San Juan de Licupis',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060417',
    name: 'Tacabamba',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060418',
    name: 'Tocmoche',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060419',
    name: 'Chalamarca',
    parent: '0604',
    department_id: '06'
  },
  {
    id: '060501',
    name: 'Contumaza',
    parent: '0605',
    department_id: '06'
  },
  {
    id: '060502',
    name: 'Chilete',
    parent: '0605',
    department_id: '06'
  },
  {
    id: '060503',
    name: 'Cupisnique',
    parent: '0605',
    department_id: '06'
  },
  {
    id: '060504',
    name: 'Guzmango',
    parent: '0605',
    department_id: '06'
  },
  {
    id: '060505',
    name: 'San Benito',
    parent: '0605',
    department_id: '06'
  },
  {
    id: '060506',
    name: 'Santa Cruz de Toledo',
    parent: '0605',
    department_id: '06'
  },
  {
    id: '060507',
    name: 'Tantarica',
    parent: '0605',
    department_id: '06'
  },
  {
    id: '060508',
    name: 'Yonan',
    parent: '0605',
    department_id: '06'
  },
  {
    id: '060601',
    name: 'Cutervo',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060602',
    name: 'Callayuc',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060603',
    name: 'Choros',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060604',
    name: 'Cujillo',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060605',
    name: 'La Ramada',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060606',
    name: 'Pimpingos',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060607',
    name: 'Querocotillo',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060608',
    name: 'San Andrés de Cutervo',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060609',
    name: 'San Juan de Cutervo',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060610',
    name: 'San Luis de Lucma',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060611',
    name: 'Santa Cruz',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060612',
    name: 'Santo Domingo de la Capilla',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060613',
    name: 'Santo Tomas',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060614',
    name: 'Socota',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060615',
    name: 'Toribio Casanova',
    parent: '0606',
    department_id: '06'
  },
  {
    id: '060701',
    name: 'Bambamarca',
    parent: '0607',
    department_id: '06'
  },
  {
    id: '060702',
    name: 'Chugur',
    parent: '0607',
    department_id: '06'
  },
  {
    id: '060703',
    name: 'Hualgayoc',
    parent: '0607',
    department_id: '06'
  },
  {
    id: '060801',
    name: 'Jaén',
    parent: '0608',
    department_id: '06'
  },
  {
    id: '060802',
    name: 'Bellavista',
    parent: '0608',
    department_id: '06'
  },
  {
    id: '060803',
    name: 'Chontali',
    parent: '0608',
    department_id: '06'
  },
  {
    id: '060804',
    name: 'Colasay',
    parent: '0608',
    department_id: '06'
  },
  {
    id: '060805',
    name: 'Huabal',
    parent: '0608',
    department_id: '06'
  },
  {
    id: '060806',
    name: 'Las Pirias',
    parent: '0608',
    department_id: '06'
  },
  {
    id: '060807',
    name: 'Pomahuaca',
    parent: '0608',
    department_id: '06'
  },
  {
    id: '060808',
    name: 'Pucara',
    parent: '0608',
    department_id: '06'
  },
  {
    id: '060809',
    name: 'Sallique',
    parent: '0608',
    department_id: '06'
  },
  {
    id: '060810',
    name: 'San Felipe',
    parent: '0608',
    department_id: '06'
  },
  {
    id: '060811',
    name: 'San José del Alto',
    parent: '0608',
    department_id: '06'
  },
  {
    id: '060812',
    name: 'Santa Rosa',
    parent: '0608',
    department_id: '06'
  },
  {
    id: '060901',
    name: 'San Ignacio',
    parent: '0609',
    department_id: '06'
  },
  {
    id: '060902',
    name: 'Chirinos',
    parent: '0609',
    department_id: '06'
  },
  {
    id: '060903',
    name: 'Huarango',
    parent: '0609',
    department_id: '06'
  },
  {
    id: '060904',
    name: 'La Coipa',
    parent: '0609',
    department_id: '06'
  },
  {
    id: '060905',
    name: 'Namballe',
    parent: '0609',
    department_id: '06'
  },
  {
    id: '060906',
    name: 'San José de Lourdes',
    parent: '0609',
    department_id: '06'
  },
  {
    id: '060907',
    name: 'Tabaconas',
    parent: '0609',
    department_id: '06'
  },
  {
    id: '061001',
    name: 'Pedro Gálvez',
    parent: '0610',
    department_id: '06'
  },
  {
    id: '061002',
    name: 'Chancay',
    parent: '0610',
    department_id: '06'
  },
  {
    id: '061003',
    name: 'Eduardo Villanueva',
    parent: '0610',
    department_id: '06'
  },
  {
    id: '061004',
    name: 'Gregorio Pita',
    parent: '0610',
    department_id: '06'
  },
  {
    id: '061005',
    name: 'Ichocan',
    parent: '0610',
    department_id: '06'
  },
  {
    id: '061006',
    name: 'José Manuel Quiroz',
    parent: '0610',
    department_id: '06'
  },
  {
    id: '061007',
    name: 'José Sabogal',
    parent: '0610',
    department_id: '06'
  },
  {
    id: '061101',
    name: 'San Miguel',
    parent: '0611',
    department_id: '06'
  },
  {
    id: '061102',
    name: 'Bolívar',
    parent: '0611',
    department_id: '06'
  },
  {
    id: '061103',
    name: 'Calquis',
    parent: '0611',
    department_id: '06'
  },
  {
    id: '061104',
    name: 'Catilluc',
    parent: '0611',
    department_id: '06'
  },
  {
    id: '061105',
    name: 'El Prado',
    parent: '0611',
    department_id: '06'
  },
  {
    id: '061106',
    name: 'La Florida',
    parent: '0611',
    department_id: '06'
  },
  {
    id: '061107',
    name: 'Llapa',
    parent: '0611',
    department_id: '06'
  },
  {
    id: '061108',
    name: 'Nanchoc',
    parent: '0611',
    department_id: '06'
  },
  {
    id: '061109',
    name: 'Niepos',
    parent: '0611',
    department_id: '06'
  },
  {
    id: '061110',
    name: 'San Gregorio',
    parent: '0611',
    department_id: '06'
  },
  {
    id: '061111',
    name: 'San Silvestre de Cochan',
    parent: '0611',
    department_id: '06'
  },
  {
    id: '061112',
    name: 'Tongod',
    parent: '0611',
    department_id: '06'
  },
  {
    id: '061113',
    name: 'Unión Agua Blanca',
    parent: '0611',
    department_id: '06'
  },
  {
    id: '061201',
    name: 'San Pablo',
    parent: '0612',
    department_id: '06'
  },
  {
    id: '061202',
    name: 'San Bernardino',
    parent: '0612',
    department_id: '06'
  },
  {
    id: '061203',
    name: 'San Luis',
    parent: '0612',
    department_id: '06'
  },
  {
    id: '061204',
    name: 'Tumbaden',
    parent: '0612',
    department_id: '06'
  },
  {
    id: '061301',
    name: 'Santa Cruz',
    parent: '0613',
    department_id: '06'
  },
  {
    id: '061302',
    name: 'Andabamba',
    parent: '0613',
    department_id: '06'
  },
  {
    id: '061303',
    name: 'Catache',
    parent: '0613',
    department_id: '06'
  },
  {
    id: '061304',
    name: 'Chancaybaños',
    parent: '0613',
    department_id: '06'
  },
  {
    id: '061305',
    name: 'La Esperanza',
    parent: '0613',
    department_id: '06'
  },
  {
    id: '061306',
    name: 'Ninabamba',
    parent: '0613',
    department_id: '06'
  },
  {
    id: '061307',
    name: 'Pulan',
    parent: '0613',
    department_id: '06'
  },
  {
    id: '061308',
    name: 'Saucepampa',
    parent: '0613',
    department_id: '06'
  },
  {
    id: '061309',
    name: 'Sexi',
    parent: '0613',
    department_id: '06'
  },
  {
    id: '061310',
    name: 'Uticyacu',
    parent: '0613',
    department_id: '06'
  },
  {
    id: '061311',
    name: 'Yauyucan',
    parent: '0613',
    department_id: '06'
  },
  {
    id: '070101',
    name: 'Callao',
    parent: '0701',
    department_id: '07'
  },
  {
    id: '070102',
    name: 'Bellavista',
    parent: '0701',
    department_id: '07'
  },
  {
    id: '070103',
    name: 'Carmen de la Legua Reynoso',
    parent: '0701',
    department_id: '07'
  },
  {
    id: '070104',
    name: 'La Perla',
    parent: '0701',
    department_id: '07'
  },
  {
    id: '070105',
    name: 'La Punta',
    parent: '0701',
    department_id: '07'
  },
  {
    id: '070106',
    name: 'Ventanilla',
    parent: '0701',
    department_id: '07'
  },
  {
    id: '070107',
    name: 'Mi Perú',
    parent: '0701',
    department_id: '07'
  },
  {
    id: '080101',
    name: 'Cusco',
    parent: '0801',
    department_id: '08'
  },
  {
    id: '080102',
    name: 'Ccorca',
    parent: '0801',
    department_id: '08'
  },
  {
    id: '080103',
    name: 'Poroy',
    parent: '0801',
    department_id: '08'
  },
  {
    id: '080104',
    name: 'San Jerónimo',
    parent: '0801',
    department_id: '08'
  },
  {
    id: '080105',
    name: 'San Sebastian',
    parent: '0801',
    department_id: '08'
  },
  {
    id: '080106',
    name: 'Santiago',
    parent: '0801',
    department_id: '08'
  },
  {
    id: '080107',
    name: 'Saylla',
    parent: '0801',
    department_id: '08'
  },
  {
    id: '080108',
    name: 'Wanchaq',
    parent: '0801',
    department_id: '08'
  },
  {
    id: '080201',
    name: 'Acomayo',
    parent: '0802',
    department_id: '08'
  },
  {
    id: '080202',
    name: 'Acopia',
    parent: '0802',
    department_id: '08'
  },
  {
    id: '080203',
    name: 'Acos',
    parent: '0802',
    department_id: '08'
  },
  {
    id: '080204',
    name: 'Mosoc Llacta',
    parent: '0802',
    department_id: '08'
  },
  {
    id: '080205',
    name: 'Pomacanchi',
    parent: '0802',
    department_id: '08'
  },
  {
    id: '080206',
    name: 'Rondocan',
    parent: '0802',
    department_id: '08'
  },
  {
    id: '080207',
    name: 'Sangarara',
    parent: '0802',
    department_id: '08'
  },
  {
    id: '080301',
    name: 'Anta',
    parent: '0803',
    department_id: '08'
  },
  {
    id: '080302',
    name: 'Ancahuasi',
    parent: '0803',
    department_id: '08'
  },
  {
    id: '080303',
    name: 'Cachimayo',
    parent: '0803',
    department_id: '08'
  },
  {
    id: '080304',
    name: 'Chinchaypujio',
    parent: '0803',
    department_id: '08'
  },
  {
    id: '080305',
    name: 'Huarocondo',
    parent: '0803',
    department_id: '08'
  },
  {
    id: '080306',
    name: 'Limatambo',
    parent: '0803',
    department_id: '08'
  },
  {
    id: '080307',
    name: 'Mollepata',
    parent: '0803',
    department_id: '08'
  },
  {
    id: '080308',
    name: 'Pucyura',
    parent: '0803',
    department_id: '08'
  },
  {
    id: '080309',
    name: 'Zurite',
    parent: '0803',
    department_id: '08'
  },
  {
    id: '080401',
    name: 'Calca',
    parent: '0804',
    department_id: '08'
  },
  {
    id: '080402',
    name: 'Coya',
    parent: '0804',
    department_id: '08'
  },
  {
    id: '080403',
    name: 'Lamay',
    parent: '0804',
    department_id: '08'
  },
  {
    id: '080404',
    name: 'Lares',
    parent: '0804',
    department_id: '08'
  },
  {
    id: '080405',
    name: 'Pisac',
    parent: '0804',
    department_id: '08'
  },
  {
    id: '080406',
    name: 'San Salvador',
    parent: '0804',
    department_id: '08'
  },
  {
    id: '080407',
    name: 'Taray',
    parent: '0804',
    department_id: '08'
  },
  {
    id: '080408',
    name: 'Yanatile',
    parent: '0804',
    department_id: '08'
  },
  {
    id: '080501',
    name: 'Yanaoca',
    parent: '0805',
    department_id: '08'
  },
  {
    id: '080502',
    name: 'Checca',
    parent: '0805',
    department_id: '08'
  },
  {
    id: '080503',
    name: 'Kunturkanki',
    parent: '0805',
    department_id: '08'
  },
  {
    id: '080504',
    name: 'Langui',
    parent: '0805',
    department_id: '08'
  },
  {
    id: '080505',
    name: 'Layo',
    parent: '0805',
    department_id: '08'
  },
  {
    id: '080506',
    name: 'Pampamarca',
    parent: '0805',
    department_id: '08'
  },
  {
    id: '080507',
    name: 'Quehue',
    parent: '0805',
    department_id: '08'
  },
  {
    id: '080508',
    name: 'Tupac Amaru',
    parent: '0805',
    department_id: '08'
  },
  {
    id: '080601',
    name: 'Sicuani',
    parent: '0806',
    department_id: '08'
  },
  {
    id: '080602',
    name: 'Checacupe',
    parent: '0806',
    department_id: '08'
  },
  {
    id: '080603',
    name: 'Combapata',
    parent: '0806',
    department_id: '08'
  },
  {
    id: '080604',
    name: 'Marangani',
    parent: '0806',
    department_id: '08'
  },
  {
    id: '080605',
    name: 'Pitumarca',
    parent: '0806',
    department_id: '08'
  },
  {
    id: '080606',
    name: 'San Pablo',
    parent: '0806',
    department_id: '08'
  },
  {
    id: '080607',
    name: 'San Pedro',
    parent: '0806',
    department_id: '08'
  },
  {
    id: '080608',
    name: 'Tinta',
    parent: '0806',
    department_id: '08'
  },
  {
    id: '080701',
    name: 'Santo Tomas',
    parent: '0807',
    department_id: '08'
  },
  {
    id: '080702',
    name: 'Capacmarca',
    parent: '0807',
    department_id: '08'
  },
  {
    id: '080703',
    name: 'Chamaca',
    parent: '0807',
    department_id: '08'
  },
  {
    id: '080704',
    name: 'Colquemarca',
    parent: '0807',
    department_id: '08'
  },
  {
    id: '080705',
    name: 'Livitaca',
    parent: '0807',
    department_id: '08'
  },
  {
    id: '080706',
    name: 'Llusco',
    parent: '0807',
    department_id: '08'
  },
  {
    id: '080707',
    name: 'Quiñota',
    parent: '0807',
    department_id: '08'
  },
  {
    id: '080708',
    name: 'Velille',
    parent: '0807',
    department_id: '08'
  },
  {
    id: '080801',
    name: 'Espinar',
    parent: '0808',
    department_id: '08'
  },
  {
    id: '080802',
    name: 'Condoroma',
    parent: '0808',
    department_id: '08'
  },
  {
    id: '080803',
    name: 'Coporaque',
    parent: '0808',
    department_id: '08'
  },
  {
    id: '080804',
    name: 'Ocoruro',
    parent: '0808',
    department_id: '08'
  },
  {
    id: '080805',
    name: 'Pallpata',
    parent: '0808',
    department_id: '08'
  },
  {
    id: '080806',
    name: 'Pichigua',
    parent: '0808',
    department_id: '08'
  },
  {
    id: '080807',
    name: 'Suyckutambo',
    parent: '0808',
    department_id: '08'
  },
  {
    id: '080808',
    name: 'Alto Pichigua',
    parent: '0808',
    department_id: '08'
  },
  {
    id: '080901',
    name: 'Santa Ana',
    parent: '0809',
    department_id: '08'
  },
  {
    id: '080902',
    name: 'Echarate',
    parent: '0809',
    department_id: '08'
  },
  {
    id: '080903',
    name: 'Huayopata',
    parent: '0809',
    department_id: '08'
  },
  {
    id: '080904',
    name: 'Maranura',
    parent: '0809',
    department_id: '08'
  },
  {
    id: '080905',
    name: 'Ocobamba',
    parent: '0809',
    department_id: '08'
  },
  {
    id: '080906',
    name: 'Quellouno',
    parent: '0809',
    department_id: '08'
  },
  {
    id: '080907',
    name: 'Kimbiri',
    parent: '0809',
    department_id: '08'
  },
  {
    id: '080908',
    name: 'Santa Teresa',
    parent: '0809',
    department_id: '08'
  },
  {
    id: '080909',
    name: 'Vilcabamba',
    parent: '0809',
    department_id: '08'
  },
  {
    id: '080910',
    name: 'Pichari',
    parent: '0809',
    department_id: '08'
  },
  {
    id: '080911',
    name: 'Inkawasi',
    parent: '0809',
    department_id: '08'
  },
  {
    id: '080912',
    name: 'Villa Virgen',
    parent: '0809',
    department_id: '08'
  },
  {
    id: '080913',
    name: 'Villa Kintiarina',
    parent: '0809',
    department_id: '08'
  },
  {
    id: '080914',
    name: 'Megantoni',
    parent: '0809',
    department_id: '08'
  },
  {
    id: '081001',
    name: 'Paruro',
    parent: '0810',
    department_id: '08'
  },
  {
    id: '081002',
    name: 'Accha',
    parent: '0810',
    department_id: '08'
  },
  {
    id: '081003',
    name: 'Ccapi',
    parent: '0810',
    department_id: '08'
  },
  {
    id: '081004',
    name: 'Colcha',
    parent: '0810',
    department_id: '08'
  },
  {
    id: '081005',
    name: 'Huanoquite',
    parent: '0810',
    department_id: '08'
  },
  {
    id: '081006',
    name: 'Omachaç',
    parent: '0810',
    department_id: '08'
  },
  {
    id: '081007',
    name: 'Paccaritambo',
    parent: '0810',
    department_id: '08'
  },
  {
    id: '081008',
    name: 'Pillpinto',
    parent: '0810',
    department_id: '08'
  },
  {
    id: '081009',
    name: 'Yaurisque',
    parent: '0810',
    department_id: '08'
  },
  {
    id: '081101',
    name: 'Paucartambo',
    parent: '0811',
    department_id: '08'
  },
  {
    id: '081102',
    name: 'Caicay',
    parent: '0811',
    department_id: '08'
  },
  {
    id: '081103',
    name: 'Challabamba',
    parent: '0811',
    department_id: '08'
  },
  {
    id: '081104',
    name: 'Colquepata',
    parent: '0811',
    department_id: '08'
  },
  {
    id: '081105',
    name: 'Huancarani',
    parent: '0811',
    department_id: '08'
  },
  {
    id: '081106',
    name: 'Kosñipata',
    parent: '0811',
    department_id: '08'
  },
  {
    id: '081201',
    name: 'Urcos',
    parent: '0812',
    department_id: '08'
  },
  {
    id: '081202',
    name: 'Andahuaylillas',
    parent: '0812',
    department_id: '08'
  },
  {
    id: '081203',
    name: 'Camanti',
    parent: '0812',
    department_id: '08'
  },
  {
    id: '081204',
    name: 'Ccarhuayo',
    parent: '0812',
    department_id: '08'
  },
  {
    id: '081205',
    name: 'Ccatca',
    parent: '0812',
    department_id: '08'
  },
  {
    id: '081206',
    name: 'Cusipata',
    parent: '0812',
    department_id: '08'
  },
  {
    id: '081207',
    name: 'Huaro',
    parent: '0812',
    department_id: '08'
  },
  {
    id: '081208',
    name: 'Lucre',
    parent: '0812',
    department_id: '08'
  },
  {
    id: '081209',
    name: 'Marcapata',
    parent: '0812',
    department_id: '08'
  },
  {
    id: '081210',
    name: 'Ocongate',
    parent: '0812',
    department_id: '08'
  },
  {
    id: '081211',
    name: 'Oropesa',
    parent: '0812',
    department_id: '08'
  },
  {
    id: '081212',
    name: 'Quiquijana',
    parent: '0812',
    department_id: '08'
  },
  {
    id: '081301',
    name: 'Urubamba',
    parent: '0813',
    department_id: '08'
  },
  {
    id: '081302',
    name: 'Chinchero',
    parent: '0813',
    department_id: '08'
  },
  {
    id: '081303',
    name: 'Huayllabamba',
    parent: '0813',
    department_id: '08'
  },
  {
    id: '081304',
    name: 'Machupicchu',
    parent: '0813',
    department_id: '08'
  },
  {
    id: '081305',
    name: 'Maras',
    parent: '0813',
    department_id: '08'
  },
  {
    id: '081306',
    name: 'Ollantaytambo',
    parent: '0813',
    department_id: '08'
  },
  {
    id: '081307',
    name: 'Yucay',
    parent: '0813',
    department_id: '08'
  },
  {
    id: '090101',
    name: 'Huancavelica',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090102',
    name: 'Acobambilla',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090103',
    name: 'Acoria',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090104',
    name: 'Conayca',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090105',
    name: 'Cuenca',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090106',
    name: 'Huachocolpa',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090107',
    name: 'Huayllahuara',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090108',
    name: 'Izcuchaca',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090109',
    name: 'Laria',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090110',
    name: 'Manta',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090111',
    name: 'Mariscal Cáceres',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090112',
    name: 'Moya',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090113',
    name: 'Nuevo Occoro',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090114',
    name: 'Palca',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090115',
    name: 'Pilchaca',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090116',
    name: 'Vilca',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090117',
    name: 'Yauli',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090118',
    name: 'Ascensión',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090119',
    name: 'Huando',
    parent: '0901',
    department_id: '09'
  },
  {
    id: '090201',
    name: 'Acobamba',
    parent: '0902',
    department_id: '09'
  },
  {
    id: '090202',
    name: 'Andabamba',
    parent: '0902',
    department_id: '09'
  },
  {
    id: '090203',
    name: 'Anta',
    parent: '0902',
    department_id: '09'
  },
  {
    id: '090204',
    name: 'Caja',
    parent: '0902',
    department_id: '09'
  },
  {
    id: '090205',
    name: 'Marcas',
    parent: '0902',
    department_id: '09'
  },
  {
    id: '090206',
    name: 'Paucara',
    parent: '0902',
    department_id: '09'
  },
  {
    id: '090207',
    name: 'Pomacocha',
    parent: '0902',
    department_id: '09'
  },
  {
    id: '090208',
    name: 'Rosario',
    parent: '0902',
    department_id: '09'
  },
  {
    id: '090301',
    name: 'Lircay',
    parent: '0903',
    department_id: '09'
  },
  {
    id: '090302',
    name: 'Anchonga',
    parent: '0903',
    department_id: '09'
  },
  {
    id: '090303',
    name: 'Callanmarca',
    parent: '0903',
    department_id: '09'
  },
  {
    id: '090304',
    name: 'Ccochaccasa',
    parent: '0903',
    department_id: '09'
  },
  {
    id: '090305',
    name: 'Chincho',
    parent: '0903',
    department_id: '09'
  },
  {
    id: '090306',
    name: 'Congalla',
    parent: '0903',
    department_id: '09'
  },
  {
    id: '090307',
    name: 'Huanca-Huanca',
    parent: '0903',
    department_id: '09'
  },
  {
    id: '090308',
    name: 'Huayllay Grande',
    parent: '0903',
    department_id: '09'
  },
  {
    id: '090309',
    name: 'Julcamarca',
    parent: '0903',
    department_id: '09'
  },
  {
    id: '090310',
    name: 'San Antonio de Antaparco',
    parent: '0903',
    department_id: '09'
  },
  {
    id: '090311',
    name: 'Santo Tomas de Pata',
    parent: '0903',
    department_id: '09'
  },
  {
    id: '090312',
    name: 'Secclla',
    parent: '0903',
    department_id: '09'
  },
  {
    id: '090401',
    name: 'Castrovirreyna',
    parent: '0904',
    department_id: '09'
  },
  {
    id: '090402',
    name: 'Arma',
    parent: '0904',
    department_id: '09'
  },
  {
    id: '090403',
    name: 'Aurahua',
    parent: '0904',
    department_id: '09'
  },
  {
    id: '090404',
    name: 'Capillas',
    parent: '0904',
    department_id: '09'
  },
  {
    id: '090405',
    name: 'Chupamarca',
    parent: '0904',
    department_id: '09'
  },
  {
    id: '090406',
    name: 'Cocas',
    parent: '0904',
    department_id: '09'
  },
  {
    id: '090407',
    name: 'Huachos',
    parent: '0904',
    department_id: '09'
  },
  {
    id: '090408',
    name: 'Huamatambo',
    parent: '0904',
    department_id: '09'
  },
  {
    id: '090409',
    name: 'Mollepampa',
    parent: '0904',
    department_id: '09'
  },
  {
    id: '090410',
    name: 'San Juan',
    parent: '0904',
    department_id: '09'
  },
  {
    id: '090411',
    name: 'Santa Ana',
    parent: '0904',
    department_id: '09'
  },
  {
    id: '090412',
    name: 'Tantara',
    parent: '0904',
    department_id: '09'
  },
  {
    id: '090413',
    name: 'Ticrapo',
    parent: '0904',
    department_id: '09'
  },
  {
    id: '090501',
    name: 'Churcampa',
    parent: '0905',
    department_id: '09'
  },
  {
    id: '090502',
    name: 'Anco',
    parent: '0905',
    department_id: '09'
  },
  {
    id: '090503',
    name: 'Chinchihuasi',
    parent: '0905',
    department_id: '09'
  },
  {
    id: '090504',
    name: 'El Carmen',
    parent: '0905',
    department_id: '09'
  },
  {
    id: '090505',
    name: 'La Merced',
    parent: '0905',
    department_id: '09'
  },
  {
    id: '090506',
    name: 'Locroja',
    parent: '0905',
    department_id: '09'
  },
  {
    id: '090507',
    name: 'Paucarbamba',
    parent: '0905',
    department_id: '09'
  },
  {
    id: '090508',
    name: 'San Miguel de Mayocc',
    parent: '0905',
    department_id: '09'
  },
  {
    id: '090509',
    name: 'San Pedro de Coris',
    parent: '0905',
    department_id: '09'
  },
  {
    id: '090510',
    name: 'Pachamarca',
    parent: '0905',
    department_id: '09'
  },
  {
    id: '090511',
    name: 'Cosme',
    parent: '0905',
    department_id: '09'
  },
  {
    id: '090601',
    name: 'Huaytara',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090602',
    name: 'Ayavi',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090603',
    name: 'Córdova',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090604',
    name: 'Huayacundo Arma',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090605',
    name: 'Laramarca',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090606',
    name: 'Ocoyo',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090607',
    name: 'Pilpichaca',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090608',
    name: 'Querco',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090609',
    name: 'Quito-Arma',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090610',
    name: 'San Antonio de Cusicancha',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090611',
    name: 'San Francisco de Sangayaico',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090612',
    name: 'San Isidro',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090613',
    name: 'Santiago de Chocorvos',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090614',
    name: 'Santiago de Quirahuara',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090615',
    name: 'Santo Domingo de Capillas',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090616',
    name: 'Tambo',
    parent: '0906',
    department_id: '09'
  },
  {
    id: '090701',
    name: 'Pampas',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090702',
    name: 'Acostambo',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090703',
    name: 'Acraquia',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090704',
    name: 'Ahuaycha',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090705',
    name: 'Colcabamba',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090706',
    name: 'Daniel Hernández',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090707',
    name: 'Huachocolpa',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090709',
    name: 'Huaribamba',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090710',
    name: 'Ñahuimpuquio',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090711',
    name: 'Pazos',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090713',
    name: 'Quishuar',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090714',
    name: 'Salcabamba',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090715',
    name: 'Salcahuasi',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090716',
    name: 'San Marcos de Rocchac',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090717',
    name: 'Surcubamba',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090718',
    name: 'Tintay Puncu',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090719',
    name: 'Quichuas',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090720',
    name: 'Andaymarca',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090721',
    name: 'Roble',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090722',
    name: 'Pichos',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '090723',
    name: 'Santiago de Tucuma',
    parent: '0907',
    department_id: '09'
  },
  {
    id: '100101',
    name: 'Huanuco',
    parent: '1001',
    department_id: '10'
  },
  {
    id: '100102',
    name: 'Amarilis',
    parent: '1001',
    department_id: '10'
  },
  {
    id: '100103',
    name: 'Chinchao',
    parent: '1001',
    department_id: '10'
  },
  {
    id: '100104',
    name: 'Churubamba',
    parent: '1001',
    department_id: '10'
  },
  {
    id: '100105',
    name: 'Margos',
    parent: '1001',
    department_id: '10'
  },
  {
    id: '100106',
    name: 'Quisqui (Kichki)',
    parent: '1001',
    department_id: '10'
  },
  {
    id: '100107',
    name: 'San Francisco de Cayran',
    parent: '1001',
    department_id: '10'
  },
  {
    id: '100108',
    name: 'San Pedro de Chaulan',
    parent: '1001',
    department_id: '10'
  },
  {
    id: '100109',
    name: 'Santa María del Valle',
    parent: '1001',
    department_id: '10'
  },
  {
    id: '100110',
    name: 'Yarumayo',
    parent: '1001',
    department_id: '10'
  },
  {
    id: '100111',
    name: 'Pillco Marca',
    parent: '1001',
    department_id: '10'
  },
  {
    id: '100112',
    name: 'Yacus',
    parent: '1001',
    department_id: '10'
  },
  {
    id: '100113',
    name: 'San Pablo de Pillao',
    parent: '1001',
    department_id: '10'
  },
  {
    id: '100201',
    name: 'Ambo',
    parent: '1002',
    department_id: '10'
  },
  {
    id: '100202',
    name: 'Cayna',
    parent: '1002',
    department_id: '10'
  },
  {
    id: '100203',
    name: 'Colpas',
    parent: '1002',
    department_id: '10'
  },
  {
    id: '100204',
    name: 'Conchamarca',
    parent: '1002',
    department_id: '10'
  },
  {
    id: '100205',
    name: 'Huacar',
    parent: '1002',
    department_id: '10'
  },
  {
    id: '100206',
    name: 'San Francisco',
    parent: '1002',
    department_id: '10'
  },
  {
    id: '100207',
    name: 'San Rafael',
    parent: '1002',
    department_id: '10'
  },
  {
    id: '100208',
    name: 'Tomay Kichwa',
    parent: '1002',
    department_id: '10'
  },
  {
    id: '100301',
    name: 'La Unión',
    parent: '1003',
    department_id: '10'
  },
  {
    id: '100307',
    name: 'Chuquis',
    parent: '1003',
    department_id: '10'
  },
  {
    id: '100311',
    name: 'Marías',
    parent: '1003',
    department_id: '10'
  },
  {
    id: '100313',
    name: 'Pachas',
    parent: '1003',
    department_id: '10'
  },
  {
    id: '100316',
    name: 'Quivilla',
    parent: '1003',
    department_id: '10'
  },
  {
    id: '100317',
    name: 'Ripan',
    parent: '1003',
    department_id: '10'
  },
  {
    id: '100321',
    name: 'Shunqui',
    parent: '1003',
    department_id: '10'
  },
  {
    id: '100322',
    name: 'Sillapata',
    parent: '1003',
    department_id: '10'
  },
  {
    id: '100323',
    name: 'Yanas',
    parent: '1003',
    department_id: '10'
  },
  {
    id: '100401',
    name: 'Huacaybamba',
    parent: '1004',
    department_id: '10'
  },
  {
    id: '100402',
    name: 'Canchabamba',
    parent: '1004',
    department_id: '10'
  },
  {
    id: '100403',
    name: 'Cochabamba',
    parent: '1004',
    department_id: '10'
  },
  {
    id: '100404',
    name: 'Pinra',
    parent: '1004',
    department_id: '10'
  },
  {
    id: '100501',
    name: 'Llata',
    parent: '1005',
    department_id: '10'
  },
  {
    id: '100502',
    name: 'Arancay',
    parent: '1005',
    department_id: '10'
  },
  {
    id: '100503',
    name: 'Chavín de Pariarca',
    parent: '1005',
    department_id: '10'
  },
  {
    id: '100504',
    name: 'Jacas Grande',
    parent: '1005',
    department_id: '10'
  },
  {
    id: '100505',
    name: 'Jircan',
    parent: '1005',
    department_id: '10'
  },
  {
    id: '100506',
    name: 'Miraflores',
    parent: '1005',
    department_id: '10'
  },
  {
    id: '100507',
    name: 'Monzón',
    parent: '1005',
    department_id: '10'
  },
  {
    id: '100508',
    name: 'Punchao',
    parent: '1005',
    department_id: '10'
  },
  {
    id: '100509',
    name: 'Puños',
    parent: '1005',
    department_id: '10'
  },
  {
    id: '100510',
    name: 'Singa',
    parent: '1005',
    department_id: '10'
  },
  {
    id: '100511',
    name: 'Tantamayo',
    parent: '1005',
    department_id: '10'
  },
  {
    id: '100601',
    name: 'Rupa-Rupa',
    parent: '1006',
    department_id: '10'
  },
  {
    id: '100602',
    name: 'Daniel Alomía Robles',
    parent: '1006',
    department_id: '10'
  },
  {
    id: '100603',
    name: 'Hermílio Valdizan',
    parent: '1006',
    department_id: '10'
  },
  {
    id: '100604',
    name: 'José Crespo y Castillo',
    parent: '1006',
    department_id: '10'
  },
  {
    id: '100605',
    name: 'Luyando',
    parent: '1006',
    department_id: '10'
  },
  {
    id: '100606',
    name: 'Mariano Damaso Beraun',
    parent: '1006',
    department_id: '10'
  },
  {
    id: '100607',
    name: 'Pucayacu',
    parent: '1006',
    department_id: '10'
  },
  {
    id: '100608',
    name: 'Castillo Grande',
    parent: '1006',
    department_id: '10'
  },
  {
    id: '100609',
    name: 'Pueblo Nuevo',
    parent: '1006',
    department_id: '10'
  },
  {
    id: '100610',
    name: 'Santo Domingo de Anda',
    parent: '1006',
    department_id: '10'
  },
  {
    id: '100701',
    name: 'Huacrachuco',
    parent: '1007',
    department_id: '10'
  },
  {
    id: '100702',
    name: 'Cholon',
    parent: '1007',
    department_id: '10'
  },
  {
    id: '100703',
    name: 'San Buenaventura',
    parent: '1007',
    department_id: '10'
  },
  {
    id: '100704',
    name: 'La Morada',
    parent: '1007',
    department_id: '10'
  },
  {
    id: '100705',
    name: 'Santa Rosa de Alto Yanajanca',
    parent: '1007',
    department_id: '10'
  },
  {
    id: '100801',
    name: 'Panao',
    parent: '1008',
    department_id: '10'
  },
  {
    id: '100802',
    name: 'Chaglla',
    parent: '1008',
    department_id: '10'
  },
  {
    id: '100803',
    name: 'Molino',
    parent: '1008',
    department_id: '10'
  },
  {
    id: '100804',
    name: 'Umari',
    parent: '1008',
    department_id: '10'
  },
  {
    id: '100901',
    name: 'Puerto Inca',
    parent: '1009',
    department_id: '10'
  },
  {
    id: '100902',
    name: 'Codo del Pozuzo',
    parent: '1009',
    department_id: '10'
  },
  {
    id: '100903',
    name: 'Honoria',
    parent: '1009',
    department_id: '10'
  },
  {
    id: '100904',
    name: 'Tournavista',
    parent: '1009',
    department_id: '10'
  },
  {
    id: '100905',
    name: 'Yuyapichis',
    parent: '1009',
    department_id: '10'
  },
  {
    id: '101001',
    name: 'Jesús',
    parent: '1010',
    department_id: '10'
  },
  {
    id: '101002',
    name: 'Baños',
    parent: '1010',
    department_id: '10'
  },
  {
    id: '101003',
    name: 'Jivia',
    parent: '1010',
    department_id: '10'
  },
  {
    id: '101004',
    name: 'Queropalca',
    parent: '1010',
    department_id: '10'
  },
  {
    id: '101005',
    name: 'Rondos',
    parent: '1010',
    department_id: '10'
  },
  {
    id: '101006',
    name: 'San Francisco de Asís',
    parent: '1010',
    department_id: '10'
  },
  {
    id: '101007',
    name: 'San Miguel de Cauri',
    parent: '1010',
    department_id: '10'
  },
  {
    id: '101101',
    name: 'Chavinillo',
    parent: '1011',
    department_id: '10'
  },
  {
    id: '101102',
    name: 'Cahuac',
    parent: '1011',
    department_id: '10'
  },
  {
    id: '101103',
    name: 'Chacabamba',
    parent: '1011',
    department_id: '10'
  },
  {
    id: '101104',
    name: 'Aparicio Pomares',
    parent: '1011',
    department_id: '10'
  },
  {
    id: '101105',
    name: 'Jacas Chico',
    parent: '1011',
    department_id: '10'
  },
  {
    id: '101106',
    name: 'Obas',
    parent: '1011',
    department_id: '10'
  },
  {
    id: '101107',
    name: 'Pampamarca',
    parent: '1011',
    department_id: '10'
  },
  {
    id: '101108',
    name: 'Choras',
    parent: '1011',
    department_id: '10'
  },
  {
    id: '110101',
    name: 'Ica',
    parent: '1101',
    department_id: '11'
  },
  {
    id: '110102',
    name: 'La Tinguiña',
    parent: '1101',
    department_id: '11'
  },
  {
    id: '110103',
    name: 'Los Aquijes',
    parent: '1101',
    department_id: '11'
  },
  {
    id: '110104',
    name: 'Ocucaje',
    parent: '1101',
    department_id: '11'
  },
  {
    id: '110105',
    name: 'Pachacutec',
    parent: '1101',
    department_id: '11'
  },
  {
    id: '110106',
    name: 'Parcona',
    parent: '1101',
    department_id: '11'
  },
  {
    id: '110107',
    name: 'Pueblo Nuevo',
    parent: '1101',
    department_id: '11'
  },
  {
    id: '110108',
    name: 'Salas',
    parent: '1101',
    department_id: '11'
  },
  {
    id: '110109',
    name: 'San José de Los Molinos',
    parent: '1101',
    department_id: '11'
  },
  {
    id: '110110',
    name: 'San Juan Bautista',
    parent: '1101',
    department_id: '11'
  },
  {
    id: '110111',
    name: 'Santiago',
    parent: '1101',
    department_id: '11'
  },
  {
    id: '110112',
    name: 'Subtanjalla',
    parent: '1101',
    department_id: '11'
  },
  {
    id: '110113',
    name: 'Tate',
    parent: '1101',
    department_id: '11'
  },
  {
    id: '110114',
    name: 'Yauca del Rosario',
    parent: '1101',
    department_id: '11'
  },
  {
    id: '110201',
    name: 'Chincha Alta',
    parent: '1102',
    department_id: '11'
  },
  {
    id: '110202',
    name: 'Alto Laran',
    parent: '1102',
    department_id: '11'
  },
  {
    id: '110203',
    name: 'Chavin',
    parent: '1102',
    department_id: '11'
  },
  {
    id: '110204',
    name: 'Chincha Baja',
    parent: '1102',
    department_id: '11'
  },
  {
    id: '110205',
    name: 'El Carmen',
    parent: '1102',
    department_id: '11'
  },
  {
    id: '110206',
    name: 'Grocio Prado',
    parent: '1102',
    department_id: '11'
  },
  {
    id: '110207',
    name: 'Pueblo Nuevo',
    parent: '1102',
    department_id: '11'
  },
  {
    id: '110208',
    name: 'San Juan de Yanac',
    parent: '1102',
    department_id: '11'
  },
  {
    id: '110209',
    name: 'San Pedro de Huacarpana',
    parent: '1102',
    department_id: '11'
  },
  {
    id: '110210',
    name: 'Sunampe',
    parent: '1102',
    department_id: '11'
  },
  {
    id: '110211',
    name: 'Tambo de Mora',
    parent: '1102',
    department_id: '11'
  },
  {
    id: '110301',
    name: 'Nasca',
    parent: '1103',
    department_id: '11'
  },
  {
    id: '110302',
    name: 'Changuillo',
    parent: '1103',
    department_id: '11'
  },
  {
    id: '110303',
    name: 'El Ingenio',
    parent: '1103',
    department_id: '11'
  },
  {
    id: '110304',
    name: 'Marcona',
    parent: '1103',
    department_id: '11'
  },
  {
    id: '110305',
    name: 'Vista Alegre',
    parent: '1103',
    department_id: '11'
  },
  {
    id: '110401',
    name: 'Palpa',
    parent: '1104',
    department_id: '11'
  },
  {
    id: '110402',
    name: 'Llipata',
    parent: '1104',
    department_id: '11'
  },
  {
    id: '110403',
    name: 'Río Grande',
    parent: '1104',
    department_id: '11'
  },
  {
    id: '110404',
    name: 'Santa Cruz',
    parent: '1104',
    department_id: '11'
  },
  {
    id: '110405',
    name: 'Tibillo',
    parent: '1104',
    department_id: '11'
  },
  {
    id: '110501',
    name: 'Pisco',
    parent: '1105',
    department_id: '11'
  },
  {
    id: '110502',
    name: 'Huancano',
    parent: '1105',
    department_id: '11'
  },
  {
    id: '110503',
    name: 'Humay',
    parent: '1105',
    department_id: '11'
  },
  {
    id: '110504',
    name: 'Independencia',
    parent: '1105',
    department_id: '11'
  },
  {
    id: '110505',
    name: 'Paracas',
    parent: '1105',
    department_id: '11'
  },
  {
    id: '110506',
    name: 'San Andrés',
    parent: '1105',
    department_id: '11'
  },
  {
    id: '110507',
    name: 'San Clemente',
    parent: '1105',
    department_id: '11'
  },
  {
    id: '110508',
    name: 'Tupac Amaru Inca',
    parent: '1105',
    department_id: '11'
  },
  {
    id: '120101',
    name: 'Huancayo',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120104',
    name: 'Carhuacallanga',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120105',
    name: 'Chacapampa',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120106',
    name: 'Chicche',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120107',
    name: 'Chilca',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120108',
    name: 'Chongos Alto',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120111',
    name: 'Chupuro',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120112',
    name: 'Colca',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120113',
    name: 'Cullhuas',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120114',
    name: 'El Tambo',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120116',
    name: 'Huacrapuquio',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120117',
    name: 'Hualhuas',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120119',
    name: 'Huancan',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120120',
    name: 'Huasicancha',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120121',
    name: 'Huayucachi',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120122',
    name: 'Ingenio',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120124',
    name: 'Pariahuanca',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120125',
    name: 'Pilcomayo',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120126',
    name: 'Pucara',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120127',
    name: 'Quichuay',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120128',
    name: 'Quilcas',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120129',
    name: 'San Agustín',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120130',
    name: 'San Jerónimo de Tunan',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120132',
    name: 'Saño',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120133',
    name: 'Sapallanga',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120134',
    name: 'Sicaya',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120135',
    name: 'Santo Domingo de Acobamba',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120136',
    name: 'Viques',
    parent: '1201',
    department_id: '12'
  },
  {
    id: '120201',
    name: 'Concepción',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120202',
    name: 'Aco',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120203',
    name: 'Andamarca',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120204',
    name: 'Chambara',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120205',
    name: 'Cochas',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120206',
    name: 'Comas',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120207',
    name: 'Heroínas Toledo',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120208',
    name: 'Manzanares',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120209',
    name: 'Mariscal Castilla',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120210',
    name: 'Matahuasi',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120211',
    name: 'Mito',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120212',
    name: 'Nueve de Julio',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120213',
    name: 'Orcotuna',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120214',
    name: 'San José de Quero',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120215',
    name: 'Santa Rosa de Ocopa',
    parent: '1202',
    department_id: '12'
  },
  {
    id: '120301',
    name: 'Chanchamayo',
    parent: '1203',
    department_id: '12'
  },
  {
    id: '120302',
    name: 'Perene',
    parent: '1203',
    department_id: '12'
  },
  {
    id: '120303',
    name: 'Pichanaqui',
    parent: '1203',
    department_id: '12'
  },
  {
    id: '120304',
    name: 'San Luis de Shuaro',
    parent: '1203',
    department_id: '12'
  },
  {
    id: '120305',
    name: 'San Ramón',
    parent: '1203',
    department_id: '12'
  },
  {
    id: '120306',
    name: 'Vitoc',
    parent: '1203',
    department_id: '12'
  },
  {
    id: '120401',
    name: 'Jauja',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120402',
    name: 'Acolla',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120403',
    name: 'Apata',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120404',
    name: 'Ataura',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120405',
    name: 'Canchayllo',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120406',
    name: 'Curicaca',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120407',
    name: 'El Mantaro',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120408',
    name: 'Huamali',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120409',
    name: 'Huaripampa',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120410',
    name: 'Huertas',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120411',
    name: 'Janjaillo',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120412',
    name: 'Julcán',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120413',
    name: 'Leonor Ordóñez',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120414',
    name: 'Llocllapampa',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120415',
    name: 'Marco',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120416',
    name: 'Masma',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120417',
    name: 'Masma Chicche',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120418',
    name: 'Molinos',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120419',
    name: 'Monobamba',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120420',
    name: 'Muqui',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120421',
    name: 'Muquiyauyo',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120422',
    name: 'Paca',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120423',
    name: 'Paccha',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120424',
    name: 'Pancan',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120425',
    name: 'Parco',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120426',
    name: 'Pomacancha',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120427',
    name: 'Ricran',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120428',
    name: 'San Lorenzo',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120429',
    name: 'San Pedro de Chunan',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120430',
    name: 'Sausa',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120431',
    name: 'Sincos',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120432',
    name: 'Tunan Marca',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120433',
    name: 'Yauli',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120434',
    name: 'Yauyos',
    parent: '1204',
    department_id: '12'
  },
  {
    id: '120501',
    name: 'Junin',
    parent: '1205',
    department_id: '12'
  },
  {
    id: '120502',
    name: 'Carhuamayo',
    parent: '1205',
    department_id: '12'
  },
  {
    id: '120503',
    name: 'Ondores',
    parent: '1205',
    department_id: '12'
  },
  {
    id: '120504',
    name: 'Ulcumayo',
    parent: '1205',
    department_id: '12'
  },
  {
    id: '120601',
    name: 'Satipo',
    parent: '1206',
    department_id: '12'
  },
  {
    id: '120602',
    name: 'Coviriali',
    parent: '1206',
    department_id: '12'
  },
  {
    id: '120603',
    name: 'Llaylla',
    parent: '1206',
    department_id: '12'
  },
  {
    id: '120604',
    name: 'Mazamari',
    parent: '1206',
    department_id: '12'
  },
  {
    id: '120605',
    name: 'Pampa Hermosa',
    parent: '1206',
    department_id: '12'
  },
  {
    id: '120606',
    name: 'Pangoa',
    parent: '1206',
    department_id: '12'
  },
  {
    id: '120607',
    name: 'Río Negro',
    parent: '1206',
    department_id: '12'
  },
  {
    id: '120608',
    name: 'Río Tambo',
    parent: '1206',
    department_id: '12'
  },
  {
    id: '120609',
    name: 'Vizcatan del Ene',
    parent: '1206',
    department_id: '12'
  },
  {
    id: '120701',
    name: 'Tarma',
    parent: '1207',
    department_id: '12'
  },
  {
    id: '120702',
    name: 'Acobamba',
    parent: '1207',
    department_id: '12'
  },
  {
    id: '120703',
    name: 'Huaricolca',
    parent: '1207',
    department_id: '12'
  },
  {
    id: '120704',
    name: 'Huasahuasi',
    parent: '1207',
    department_id: '12'
  },
  {
    id: '120705',
    name: 'La Unión',
    parent: '1207',
    department_id: '12'
  },
  {
    id: '120706',
    name: 'Palca',
    parent: '1207',
    department_id: '12'
  },
  {
    id: '120707',
    name: 'Palcamayo',
    parent: '1207',
    department_id: '12'
  },
  {
    id: '120708',
    name: 'San Pedro de Cajas',
    parent: '1207',
    department_id: '12'
  },
  {
    id: '120709',
    name: 'Tapo',
    parent: '1207',
    department_id: '12'
  },
  {
    id: '120801',
    name: 'La Oroya',
    parent: '1208',
    department_id: '12'
  },
  {
    id: '120802',
    name: 'Chacapalpa',
    parent: '1208',
    department_id: '12'
  },
  {
    id: '120803',
    name: 'Huay-Huay',
    parent: '1208',
    department_id: '12'
  },
  {
    id: '120804',
    name: 'Marcapomacocha',
    parent: '1208',
    department_id: '12'
  },
  {
    id: '120805',
    name: 'Morococha',
    parent: '1208',
    department_id: '12'
  },
  {
    id: '120806',
    name: 'Paccha',
    parent: '1208',
    department_id: '12'
  },
  {
    id: '120807',
    name: 'Santa Bárbara de Carhuacayan',
    parent: '1208',
    department_id: '12'
  },
  {
    id: '120808',
    name: 'Santa Rosa de Sacco',
    parent: '1208',
    department_id: '12'
  },
  {
    id: '120809',
    name: 'Suitucancha',
    parent: '1208',
    department_id: '12'
  },
  {
    id: '120810',
    name: 'Yauli',
    parent: '1208',
    department_id: '12'
  },
  {
    id: '120901',
    name: 'Chupaca',
    parent: '1209',
    department_id: '12'
  },
  {
    id: '120902',
    name: 'Ahuac',
    parent: '1209',
    department_id: '12'
  },
  {
    id: '120903',
    name: 'Chongos Bajo',
    parent: '1209',
    department_id: '12'
  },
  {
    id: '120904',
    name: 'Huachac',
    parent: '1209',
    department_id: '12'
  },
  {
    id: '120905',
    name: 'Huamancaca Chico',
    parent: '1209',
    department_id: '12'
  },
  {
    id: '120906',
    name: 'San Juan de Iscos',
    parent: '1209',
    department_id: '12'
  },
  {
    id: '120907',
    name: 'San Juan de Jarpa',
    parent: '1209',
    department_id: '12'
  },
  {
    id: '120908',
    name: 'Tres de Diciembre',
    parent: '1209',
    department_id: '12'
  },
  {
    id: '120909',
    name: 'Yanacancha',
    parent: '1209',
    department_id: '12'
  },
  {
    id: '130101',
    name: 'Trujillo',
    parent: '1301',
    department_id: '13'
  },
  {
    id: '130102',
    name: 'El Porvenir',
    parent: '1301',
    department_id: '13'
  },
  {
    id: '130103',
    name: 'Florencia de Mora',
    parent: '1301',
    department_id: '13'
  },
  {
    id: '130104',
    name: 'Huanchaco',
    parent: '1301',
    department_id: '13'
  },
  {
    id: '130105',
    name: 'La Esperanza',
    parent: '1301',
    department_id: '13'
  },
  {
    id: '130106',
    name: 'Laredo',
    parent: '1301',
    department_id: '13'
  },
  {
    id: '130107',
    name: 'Moche',
    parent: '1301',
    department_id: '13'
  },
  {
    id: '130108',
    name: 'Poroto',
    parent: '1301',
    department_id: '13'
  },
  {
    id: '130109',
    name: 'Salaverry',
    parent: '1301',
    department_id: '13'
  },
  {
    id: '130110',
    name: 'Simbal',
    parent: '1301',
    department_id: '13'
  },
  {
    id: '130111',
    name: 'Victor Larco Herrera',
    parent: '1301',
    department_id: '13'
  },
  {
    id: '130201',
    name: 'Ascope',
    parent: '1302',
    department_id: '13'
  },
  {
    id: '130202',
    name: 'Chicama',
    parent: '1302',
    department_id: '13'
  },
  {
    id: '130203',
    name: 'Chocope',
    parent: '1302',
    department_id: '13'
  },
  {
    id: '130204',
    name: 'Magdalena de Cao',
    parent: '1302',
    department_id: '13'
  },
  {
    id: '130205',
    name: 'Paijan',
    parent: '1302',
    department_id: '13'
  },
  {
    id: '130206',
    name: 'Rázuri',
    parent: '1302',
    department_id: '13'
  },
  {
    id: '130207',
    name: 'Santiago de Cao',
    parent: '1302',
    department_id: '13'
  },
  {
    id: '130208',
    name: 'Casa Grande',
    parent: '1302',
    department_id: '13'
  },
  {
    id: '130301',
    name: 'Bolívar',
    parent: '1303',
    department_id: '13'
  },
  {
    id: '130302',
    name: 'Bambamarca',
    parent: '1303',
    department_id: '13'
  },
  {
    id: '130303',
    name: 'Condormarca',
    parent: '1303',
    department_id: '13'
  },
  {
    id: '130304',
    name: 'Longotea',
    parent: '1303',
    department_id: '13'
  },
  {
    id: '130305',
    name: 'Uchumarca',
    parent: '1303',
    department_id: '13'
  },
  {
    id: '130306',
    name: 'Ucuncha',
    parent: '1303',
    department_id: '13'
  },
  {
    id: '130401',
    name: 'Chepen',
    parent: '1304',
    department_id: '13'
  },
  {
    id: '130402',
    name: 'Pacanga',
    parent: '1304',
    department_id: '13'
  },
  {
    id: '130403',
    name: 'Pueblo Nuevo',
    parent: '1304',
    department_id: '13'
  },
  {
    id: '130501',
    name: 'Julcan',
    parent: '1305',
    department_id: '13'
  },
  {
    id: '130502',
    name: 'Calamarca',
    parent: '1305',
    department_id: '13'
  },
  {
    id: '130503',
    name: 'Carabamba',
    parent: '1305',
    department_id: '13'
  },
  {
    id: '130504',
    name: 'Huaso',
    parent: '1305',
    department_id: '13'
  },
  {
    id: '130601',
    name: 'Otuzco',
    parent: '1306',
    department_id: '13'
  },
  {
    id: '130602',
    name: 'Agallpampa',
    parent: '1306',
    department_id: '13'
  },
  {
    id: '130604',
    name: 'Charat',
    parent: '1306',
    department_id: '13'
  },
  {
    id: '130605',
    name: 'Huaranchal',
    parent: '1306',
    department_id: '13'
  },
  {
    id: '130606',
    name: 'La Cuesta',
    parent: '1306',
    department_id: '13'
  },
  {
    id: '130608',
    name: 'Mache',
    parent: '1306',
    department_id: '13'
  },
  {
    id: '130610',
    name: 'Paranday',
    parent: '1306',
    department_id: '13'
  },
  {
    id: '130611',
    name: 'Salpo',
    parent: '1306',
    department_id: '13'
  },
  {
    id: '130613',
    name: 'Sinsicap',
    parent: '1306',
    department_id: '13'
  },
  {
    id: '130614',
    name: 'Usquil',
    parent: '1306',
    department_id: '13'
  },
  {
    id: '130701',
    name: 'San Pedro de Lloc',
    parent: '1307',
    department_id: '13'
  },
  {
    id: '130702',
    name: 'Guadalupe',
    parent: '1307',
    department_id: '13'
  },
  {
    id: '130703',
    name: 'Jequetepeque',
    parent: '1307',
    department_id: '13'
  },
  {
    id: '130704',
    name: 'Pacasmayo',
    parent: '1307',
    department_id: '13'
  },
  {
    id: '130705',
    name: 'San José',
    parent: '1307',
    department_id: '13'
  },
  {
    id: '130801',
    name: 'Tayabamba',
    parent: '1308',
    department_id: '13'
  },
  {
    id: '130802',
    name: 'Buldibuyo',
    parent: '1308',
    department_id: '13'
  },
  {
    id: '130803',
    name: 'Chillia',
    parent: '1308',
    department_id: '13'
  },
  {
    id: '130804',
    name: 'Huancaspata',
    parent: '1308',
    department_id: '13'
  },
  {
    id: '130805',
    name: 'Huaylillas',
    parent: '1308',
    department_id: '13'
  },
  {
    id: '130806',
    name: 'Huayo',
    parent: '1308',
    department_id: '13'
  },
  {
    id: '130807',
    name: 'Ongon',
    parent: '1308',
    department_id: '13'
  },
  {
    id: '130808',
    name: 'Parcoy',
    parent: '1308',
    department_id: '13'
  },
  {
    id: '130809',
    name: 'Pataz',
    parent: '1308',
    department_id: '13'
  },
  {
    id: '130810',
    name: 'Pias',
    parent: '1308',
    department_id: '13'
  },
  {
    id: '130811',
    name: 'Santiago de Challas',
    parent: '1308',
    department_id: '13'
  },
  {
    id: '130812',
    name: 'Taurija',
    parent: '1308',
    department_id: '13'
  },
  {
    id: '130813',
    name: 'Urpay',
    parent: '1308',
    department_id: '13'
  },
  {
    id: '130901',
    name: 'Huamachuco',
    parent: '1309',
    department_id: '13'
  },
  {
    id: '130902',
    name: 'Chugay',
    parent: '1309',
    department_id: '13'
  },
  {
    id: '130903',
    name: 'Cochorco',
    parent: '1309',
    department_id: '13'
  },
  {
    id: '130904',
    name: 'Curgos',
    parent: '1309',
    department_id: '13'
  },
  {
    id: '130905',
    name: 'Marcabal',
    parent: '1309',
    department_id: '13'
  },
  {
    id: '130906',
    name: 'Sanagoran',
    parent: '1309',
    department_id: '13'
  },
  {
    id: '130907',
    name: 'Sarin',
    parent: '1309',
    department_id: '13'
  },
  {
    id: '130908',
    name: 'Sartimbamba',
    parent: '1309',
    department_id: '13'
  },
  {
    id: '131001',
    name: 'Santiago de Chuco',
    parent: '1310',
    department_id: '13'
  },
  {
    id: '131002',
    name: 'Angasmarca',
    parent: '1310',
    department_id: '13'
  },
  {
    id: '131003',
    name: 'Cachicadan',
    parent: '1310',
    department_id: '13'
  },
  {
    id: '131004',
    name: 'Mollebamba',
    parent: '1310',
    department_id: '13'
  },
  {
    id: '131005',
    name: 'Mollepata',
    parent: '1310',
    department_id: '13'
  },
  {
    id: '131006',
    name: 'Quiruvilca',
    parent: '1310',
    department_id: '13'
  },
  {
    id: '131007',
    name: 'Santa Cruz de Chuca',
    parent: '1310',
    department_id: '13'
  },
  {
    id: '131008',
    name: 'Sitabamba',
    parent: '1310',
    department_id: '13'
  },
  {
    id: '131101',
    name: 'Cascas',
    parent: '1311',
    department_id: '13'
  },
  {
    id: '131102',
    name: 'Lucma',
    parent: '1311',
    department_id: '13'
  },
  {
    id: '131103',
    name: 'Marmot',
    parent: '1311',
    department_id: '13'
  },
  {
    id: '131104',
    name: 'Sayapullo',
    parent: '1311',
    department_id: '13'
  },
  {
    id: '131201',
    name: 'Viru',
    parent: '1312',
    department_id: '13'
  },
  {
    id: '131202',
    name: 'Chao',
    parent: '1312',
    department_id: '13'
  },
  {
    id: '131203',
    name: 'Guadalupito',
    parent: '1312',
    department_id: '13'
  },
  {
    id: '140101',
    name: 'Chiclayo',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140102',
    name: 'Chongoyape',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140103',
    name: 'Eten',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140104',
    name: 'Eten Puerto',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140105',
    name: 'José Leonardo Ortiz',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140106',
    name: 'La Victoria',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140107',
    name: 'Lagunas',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140108',
    name: 'Monsefu',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140109',
    name: 'Nueva Arica',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140110',
    name: 'Oyotun',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140111',
    name: 'Picsi',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140112',
    name: 'Pimentel',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140113',
    name: 'Reque',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140114',
    name: 'Santa Rosa',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140115',
    name: 'Saña',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140116',
    name: 'Cayalti',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140117',
    name: 'Patapo',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140118',
    name: 'Pomalca',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140119',
    name: 'Pucala',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140120',
    name: 'Tuman',
    parent: '1401',
    department_id: '14'
  },
  {
    id: '140201',
    name: 'Ferreñafe',
    parent: '1402',
    department_id: '14'
  },
  {
    id: '140202',
    name: 'Cañaris',
    parent: '1402',
    department_id: '14'
  },
  {
    id: '140203',
    name: 'Incahuasi',
    parent: '1402',
    department_id: '14'
  },
  {
    id: '140204',
    name: 'Manuel Antonio Mesones Muro',
    parent: '1402',
    department_id: '14'
  },
  {
    id: '140205',
    name: 'Pitipo',
    parent: '1402',
    department_id: '14'
  },
  {
    id: '140206',
    name: 'Pueblo Nuevo',
    parent: '1402',
    department_id: '14'
  },
  {
    id: '140301',
    name: 'Lambayeque',
    parent: '1403',
    department_id: '14'
  },
  {
    id: '140302',
    name: 'Chochope',
    parent: '1403',
    department_id: '14'
  },
  {
    id: '140303',
    name: 'Illimo',
    parent: '1403',
    department_id: '14'
  },
  {
    id: '140304',
    name: 'Jayanca',
    parent: '1403',
    department_id: '14'
  },
  {
    id: '140305',
    name: 'Mochumi',
    parent: '1403',
    department_id: '14'
  },
  {
    id: '140306',
    name: 'Morrope',
    parent: '1403',
    department_id: '14'
  },
  {
    id: '140307',
    name: 'Motupe',
    parent: '1403',
    department_id: '14'
  },
  {
    id: '140308',
    name: 'Olmos',
    parent: '1403',
    department_id: '14'
  },
  {
    id: '140309',
    name: 'Pacora',
    parent: '1403',
    department_id: '14'
  },
  {
    id: '140310',
    name: 'Salas',
    parent: '1403',
    department_id: '14'
  },
  {
    id: '140311',
    name: 'San José',
    parent: '1403',
    department_id: '14'
  },
  {
    id: '140312',
    name: 'Tucume',
    parent: '1403',
    department_id: '14'
  },
  {
    id: '150101',
    name: 'Lima',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150102',
    name: 'Ancón',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150103',
    name: 'Ate',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150104',
    name: 'Barranco',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150105',
    name: 'Breña',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150106',
    name: 'Carabayllo',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150107',
    name: 'Chaclacayo',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150108',
    name: 'Chorrillos',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150109',
    name: 'Cieneguilla',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150110',
    name: 'Comas',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150111',
    name: 'El Agustino',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150112',
    name: 'Independencia',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150113',
    name: 'Jesús María',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150114',
    name: 'La Molina',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150115',
    name: 'La Victoria',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150116',
    name: 'Lince',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150117',
    name: 'Los Olivos',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150118',
    name: 'Lurigancho',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150119',
    name: 'Lurin',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150120',
    name: 'Magdalena del Mar',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150121',
    name: 'Pueblo Libre',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150122',
    name: 'Miraflores',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150123',
    name: 'Pachacamac',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150124',
    name: 'Pucusana',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150125',
    name: 'Puente Piedra',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150126',
    name: 'Punta Hermosa',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150127',
    name: 'Punta Negra',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150128',
    name: 'Rímac',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150129',
    name: 'San Bartolo',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150130',
    name: 'San Borja',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150131',
    name: 'San Isidro',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150132',
    name: 'San Juan de Lurigancho',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150133',
    name: 'San Juan de Miraflores',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150134',
    name: 'San Luis',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150135',
    name: 'San Martín de Porres',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150136',
    name: 'San Miguel',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150137',
    name: 'Santa Anita',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150138',
    name: 'Santa María del Mar',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150139',
    name: 'Santa Rosa',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150140',
    name: 'Santiago de Surco',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150141',
    name: 'Surquillo',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150142',
    name: 'Villa El Salvador',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150143',
    name: 'Villa María del Triunfo',
    parent: '1501',
    department_id: '15'
  },
  {
    id: '150201',
    name: 'Barranca',
    parent: '1502',
    department_id: '15'
  },
  {
    id: '150202',
    name: 'Paramonga',
    parent: '1502',
    department_id: '15'
  },
  {
    id: '150203',
    name: 'Pativilca',
    parent: '1502',
    department_id: '15'
  },
  {
    id: '150204',
    name: 'Supe',
    parent: '1502',
    department_id: '15'
  },
  {
    id: '150205',
    name: 'Supe Puerto',
    parent: '1502',
    department_id: '15'
  },
  {
    id: '150301',
    name: 'Cajatambo',
    parent: '1503',
    department_id: '15'
  },
  {
    id: '150302',
    name: 'Copa',
    parent: '1503',
    department_id: '15'
  },
  {
    id: '150303',
    name: 'Gorgor',
    parent: '1503',
    department_id: '15'
  },
  {
    id: '150304',
    name: 'Huancapon',
    parent: '1503',
    department_id: '15'
  },
  {
    id: '150305',
    name: 'Manas',
    parent: '1503',
    department_id: '15'
  },
  {
    id: '150401',
    name: 'Canta',
    parent: '1504',
    department_id: '15'
  },
  {
    id: '150402',
    name: 'Arahuay',
    parent: '1504',
    department_id: '15'
  },
  {
    id: '150403',
    name: 'Huamantanga',
    parent: '1504',
    department_id: '15'
  },
  {
    id: '150404',
    name: 'Huaros',
    parent: '1504',
    department_id: '15'
  },
  {
    id: '150405',
    name: 'Lachaqui',
    parent: '1504',
    department_id: '15'
  },
  {
    id: '150406',
    name: 'San Buenaventura',
    parent: '1504',
    department_id: '15'
  },
  {
    id: '150407',
    name: 'Santa Rosa de Quives',
    parent: '1504',
    department_id: '15'
  },
  {
    id: '150501',
    name: 'San Vicente de Cañete',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150502',
    name: 'Asia',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150503',
    name: 'Calango',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150504',
    name: 'Cerro Azul',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150505',
    name: 'Chilca',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150506',
    name: 'Coayllo',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150507',
    name: 'Imperial',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150508',
    name: 'Lunahuana',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150509',
    name: 'Mala',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150510',
    name: 'Nuevo Imperial',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150511',
    name: 'Pacaran',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150512',
    name: 'Quilmana',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150513',
    name: 'San Antonio',
    parent: '1505',
    department_id: '15'
  },{
    id: '150514',
    name: 'San Luis',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150515',
    name: 'Santa Cruz de Flores',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150516',
    name: 'Zúñiga',
    parent: '1505',
    department_id: '15'
  },
  {
    id: '150601',
    name: 'Huaral',
    parent: '1506',
    department_id: '15'
  },
  {
    id: '150602',
    name: 'Atavillos Alto',
    parent: '1506',
    department_id: '15'
  },
  {
    id: '150603',
    name: 'Atavillos Bajo',
    parent: '1506',
    department_id: '15'
  },
  {
    id: '150604',
    name: 'Aucallama',
    parent: '1506',
    department_id: '15'
  },
  {
    id: '150605',
    name: 'Chancay',
    parent: '1506',
    department_id: '15'
  },
  {
    id: '150606',
    name: 'Ihuari',
    parent: '1506',
    department_id: '15'
  },
  {
    id: '150607',
    name: 'Lampian',
    parent: '1506',
    department_id: '15'
  },
  {
    id: '150608',
    name: 'Pacaraos',
    parent: '1506',
    department_id: '15'
  },
  {
    id: '150609',
    name: 'San Miguel de Acos',
    parent: '1506',
    department_id: '15'
  },
  {
    id: '150610',
    name: 'Santa Cruz de Andamarca',
    parent: '1506',
    department_id: '15'
  },
  {
    id: '150611',
    name: 'Sumbilca',
    parent: '1506',
    department_id: '15'
  },
  {
    id: '150612',
    name: 'Veintisiete de Noviembre',
    parent: '1506',
    department_id: '15'
  },
  {
    id: '150701',
    name: 'Matucana',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150702',
    name: 'Antioquia',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150703',
    name: 'Callahuanca',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150704',
    name: 'Carampoma',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150705',
    name: 'Chicla',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150706',
    name: 'Cuenca',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150707',
    name: 'Huachupampa',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150708',
    name: 'Huanza',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150709',
    name: 'Huarochiri',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150710',
    name: 'Lahuaytambo',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150711',
    name: 'Langa',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150712',
    name: 'Laraos',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150713',
    name: 'Mariatana',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150714',
    name: 'Ricardo Palma',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150715',
    name: 'San Andrés de Tupicocha',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150716',
    name: 'San Antonio',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150717',
    name: 'San Bartolomé',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150718',
    name: 'San Damian',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150719',
    name: 'San Juan de Iris',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150720',
    name: 'San Juan de Tantaranche',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150721',
    name: 'San Lorenzo de Quinti',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150722',
    name: 'San Mateo',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150723',
    name: 'San Mateo de Otao',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150724',
    name: 'San Pedro de Casta',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150725',
    name: 'San Pedro de Huancayre',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150726',
    name: 'Sangallaya',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150727',
    name: 'Santa Cruz de Cocachacra',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150728',
    name: 'Santa Eulalia',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150729',
    name: 'Santiago de Anchucaya',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150730',
    name: 'Santiago de Tuna',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150731',
    name: 'Santo Domingo de Los Olleros',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150732',
    name: 'Surco',
    parent: '1507',
    department_id: '15'
  },
  {
    id: '150801',
    name: 'Huacho',
    parent: '1508',
    department_id: '15'
  },
  {
    id: '150802',
    name: 'Ambar',
    parent: '1508',
    department_id: '15'
  },
  {
    id: '150803',
    name: 'Caleta de Carquin',
    parent: '1508',
    department_id: '15'
  },
  {
    id: '150804',
    name: 'Checras',
    parent: '1508',
    department_id: '15'
  },
  {
    id: '150805',
    name: 'Hualmay',
    parent: '1508',
    department_id: '15'
  },
  {
    id: '150806',
    name: 'Huaura',
    parent: '1508',
    department_id: '15'
  },
  {
    id: '150807',
    name: 'Leoncio Prado',
    parent: '1508',
    department_id: '15'
  },
  {
    id: '150808',
    name: 'Paccho',
    parent: '1508',
    department_id: '15'
  },
  {
    id: '150809',
    name: 'Santa Leonor',
    parent: '1508',
    department_id: '15'
  },
  {
    id: '150810',
    name: 'Santa María',
    parent: '1508',
    department_id: '15'
  },
  {
    id: '150811',
    name: 'Sayan',
    parent: '1508',
    department_id: '15'
  },
  {
    id: '150812',
    name: 'Vegueta',
    parent: '1508',
    department_id: '15'
  },
  {
    id: '150901',
    name: 'Oyon',
    parent: '1509',
    department_id: '15'
  },
  {
    id: '150902',
    name: 'Andajes',
    parent: '1509',
    department_id: '15'
  },
  {
    id: '150903',
    name: 'Caujul',
    parent: '1509',
    department_id: '15'
  },
  {
    id: '150904',
    name: 'Cochamarca',
    parent: '1509',
    department_id: '15'
  },
  {
    id: '150905',
    name: 'Navan',
    parent: '1509',
    department_id: '15'
  },
  {
    id: '150906',
    name: 'Pachangara',
    parent: '1509',
    department_id: '15'
  },
  {
    id: '151001',
    name: 'Yauyos',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151002',
    name: 'Alis',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151003',
    name: 'Allauca',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151004',
    name: 'Ayaviri',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151005',
    name: 'Azángaro',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151006',
    name: 'Cacra',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151007',
    name: 'Carania',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151008',
    name: 'Catahuasi',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151009',
    name: 'Chocos',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151010',
    name: 'Cochas',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151011',
    name: 'Colonia',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151012',
    name: 'Hongos',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151013',
    name: 'Huampara',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151014',
    name: 'Huancaya',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151015',
    name: 'Huangascar',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151016',
    name: 'Huantan',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151017',
    name: 'Huañec',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151018',
    name: 'Laraos',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151019',
    name: 'Lincha',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151020',
    name: 'Madean',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151021',
    name: 'Miraflores',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151022',
    name: 'Omas',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151023',
    name: 'Putinza',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151024',
    name: 'Quinches',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151025',
    name: 'Quinocay',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151026',
    name: 'San Joaquín',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151027',
    name: 'San Pedro de Pilas',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151028',
    name: 'Tanta',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151029',
    name: 'Tauripampa',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151030',
    name: 'Tomas',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151031',
    name: 'Tupe',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151032',
    name: 'Viñac',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '151033',
    name: 'Vitis',
    parent: '1510',
    department_id: '15'
  },
  {
    id: '160101',
    name: 'Iquitos',
    parent: '1601',
    department_id: '16'
  },
  {
    id: '160102',
    name: 'Alto Nanay',
    parent: '1601',
    department_id: '16'
  },
  {
    id: '160103',
    name: 'Fernando Lores',
    parent: '1601',
    department_id: '16'
  },
  {
    id: '160104',
    name: 'Indiana',
    parent: '1601',
    department_id: '16'
  },
  {
    id: '160105',
    name: 'Las Amazonas',
    parent: '1601',
    department_id: '16'
  },
  {
    id: '160106',
    name: 'Mazan',
    parent: '1601',
    department_id: '16'
  },
  {
    id: '160107',
    name: 'Napo',
    parent: '1601',
    department_id: '16'
  },
  {
    id: '160108',
    name: 'Punchana',
    parent: '1601',
    department_id: '16'
  },
  {
    id: '160110',
    name: 'Torres Causana',
    parent: '1601',
    department_id: '16'
  },
  {
    id: '160112',
    name: 'Belén',
    parent: '1601',
    department_id: '16'
  },
  {
    id: '160113',
    name: 'San Juan Bautista',
    parent: '1601',
    department_id: '16'
  },
  {
    id: '160201',
    name: 'Yurimaguas',
    parent: '1602',
    department_id: '16'
  },
  {
    id: '160202',
    name: 'Balsapuerto',
    parent: '1602',
    department_id: '16'
  },
  {
    id: '160205',
    name: 'Jeberos',
    parent: '1602',
    department_id: '16'
  },
  {
    id: '160206',
    name: 'Lagunas',
    parent: '1602',
    department_id: '16'
  },
  {
    id: '160210',
    name: 'Santa Cruz',
    parent: '1602',
    department_id: '16'
  },
  {
    id: '160211',
    name: 'Teniente Cesar López Rojas',
    parent: '1602',
    department_id: '16'
  },
  {
    id: '160301',
    name: 'Nauta',
    parent: '1603',
    department_id: '16'
  },
  {
    id: '160302',
    name: 'Parinari',
    parent: '1603',
    department_id: '16'
  },
  {
    id: '160303',
    name: 'Tigre',
    parent: '1603',
    department_id: '16'
  },
  {
    id: '160304',
    name: 'Trompeteros',
    parent: '1603',
    department_id: '16'
  },
  {
    id: '160305',
    name: 'Urarinas',
    parent: '1603',
    department_id: '16'
  },
  {
    id: '160401',
    name: 'Ramón Castilla',
    parent: '1604',
    department_id: '16'
  },
  {
    id: '160402',
    name: 'Pebas',
    parent: '1604',
    department_id: '16'
  },
  {
    id: '160403',
    name: 'Yavari',
    parent: '1604',
    department_id: '16'
  },
  {
    id: '160404',
    name: 'San Pablo',
    parent: '1604',
    department_id: '16'
  },
  {
    id: '160501',
    name: 'Requena',
    parent: '1605',
    department_id: '16'
  },
  {
    id: '160502',
    name: 'Alto Tapiche',
    parent: '1605',
    department_id: '16'
  },
  {
    id: '160503',
    name: 'Capelo',
    parent: '1605',
    department_id: '16'
  },
  {
    id: '160504',
    name: 'Emilio San Martín',
    parent: '1605',
    department_id: '16'
  },
  {
    id: '160505',
    name: 'Maquia',
    parent: '1605',
    department_id: '16'
  },
  {
    id: '160506',
    name: 'Puinahua',
    parent: '1605',
    department_id: '16'
  },
  {
    id: '160507',
    name: 'Saquena',
    parent: '1605',
    department_id: '16'
  },
  {
    id: '160508',
    name: 'Soplin',
    parent: '1605',
    department_id: '16'
  },
  {
    id: '160509',
    name: 'Tapiche',
    parent: '1605',
    department_id: '16'
  },
  {
    id: '160510',
    name: 'Jenaro Herrera',
    parent: '1605',
    department_id: '16'
  },
  {
    id: '160511',
    name: 'Yaquerana',
    parent: '1605',
    department_id: '16'
  },
  {
    id: '160601',
    name: 'Contamana',
    parent: '1606',
    department_id: '16'
  },
  {
    id: '160602',
    name: 'Inahuaya',
    parent: '1606',
    department_id: '16'
  },
  {
    id: '160603',
    name: 'Padre Márquez',
    parent: '1606',
    department_id: '16'
  },
  {
    id: '160604',
    name: 'Pampa Hermosa',
    parent: '1606',
    department_id: '16'
  },
  {
    id: '160605',
    name: 'Sarayacu',
    parent: '1606',
    department_id: '16'
  },
  {
    id: '160606',
    name: 'Vargas Guerra',
    parent: '1606',
    department_id: '16'
  },
  {
    id: '160701',
    name: 'Barranca',
    parent: '1607',
    department_id: '16'
  },
  {
    id: '160702',
    name: 'Cahuapanas',
    parent: '1607',
    department_id: '16'
  },
  {
    id: '160703',
    name: 'Manseriche',
    parent: '1607',
    department_id: '16'
  },
  {
    id: '160704',
    name: 'Morona',
    parent: '1607',
    department_id: '16'
  },
  {
    id: '160705',
    name: 'Pastaza',
    parent: '1607',
    department_id: '16'
  },
  {
    id: '160706',
    name: 'Andoas',
    parent: '1607',
    department_id: '16'
  },
  {
    id: '160801',
    name: 'Putumayo',
    parent: '1608',
    department_id: '16'
  },
  {
    id: '160802',
    name: 'Rosa Panduro',
    parent: '1608',
    department_id: '16'
  },
  {
    id: '160803',
    name: 'Teniente Manuel Clavero',
    parent: '1608',
    department_id: '16'
  },
  {
    id: '160804',
    name: 'Yaguas',
    parent: '1608',
    department_id: '16'
  },
  {
    id: '170101',
    name: 'Tambopata',
    parent: '1701',
    department_id: '17'
  },
  {
    id: '170102',
    name: 'Inambari',
    parent: '1701',
    department_id: '17'
  },
  {
    id: '170103',
    name: 'Las Piedras',
    parent: '1701',
    department_id: '17'
  },
  {
    id: '170104',
    name: 'Laberinto',
    parent: '1701',
    department_id: '17'
  },
  {
    id: '170201',
    name: 'Manu',
    parent: '1702',
    department_id: '17'
  },
  {
    id: '170202',
    name: 'Fitzcarrald',
    parent: '1702',
    department_id: '17'
  },
  {
    id: '170203',
    name: 'Madre de Dios',
    parent: '1702',
    department_id: '17'
  },
  {
    id: '170204',
    name: 'Huepetuhe',
    parent: '1702',
    department_id: '17'
  },
  {
    id: '170301',
    name: 'Iñapari',
    parent: '1703',
    department_id: '17'
  },
  {
    id: '170302',
    name: 'Iberia',
    parent: '1703',
    department_id: '17'
  },
  {
    id: '170303',
    name: 'Tahuamanu',
    parent: '1703',
    department_id: '17'
  },
  {
    id: '180101',
    name: 'Moquegua',
    parent: '1801',
    department_id: '18'
  },
  {
    id: '180102',
    name: 'Carumas',
    parent: '1801',
    department_id: '18'
  },
  {
    id: '180103',
    name: 'Cuchumbaya',
    parent: '1801',
    department_id: '18'
  },
  {
    id: '180104',
    name: 'Samegua',
    parent: '1801',
    department_id: '18'
  },
  {
    id: '180105',
    name: 'San Cristóbal',
    parent: '1801',
    department_id: '18'
  },
  {
    id: '180106',
    name: 'Torata',
    parent: '1801',
    department_id: '18'
  },
  {
    id: '180201',
    name: 'Omate',
    parent: '1802',
    department_id: '18'
  },
  {
    id: '180202',
    name: 'Chojata',
    parent: '1802',
    department_id: '18'
  },
  {
    id: '180203',
    name: 'Coalaque',
    parent: '1802',
    department_id: '18'
  },
  {
    id: '180204',
    name: 'Ichuña',
    parent: '1802',
    department_id: '18'
  },
  {
    id: '180205',
    name: 'La Capilla',
    parent: '1802',
    department_id: '18'
  },
  {
    id: '180206',
    name: 'Lloque',
    parent: '1802',
    department_id: '18'
  },
  {
    id: '180207',
    name: 'Matalaque',
    parent: '1802',
    department_id: '18'
  },
  {
    id: '180208',
    name: 'Puquina',
    parent: '1802',
    department_id: '18'
  },
  {
    id: '180209',
    name: 'Quinistaquillas',
    parent: '1802',
    department_id: '18'
  },
  {
    id: '180210',
    name: 'Ubinas',
    parent: '1802',
    department_id: '18'
  },
  {
    id: '180211',
    name: 'Yunga',
    parent: '1802',
    department_id: '18'
  },
  {
    id: '180301',
    name: 'Ilo',
    parent: '1803',
    department_id: '18'
  },
  {
    id: '180302',
    name: 'El Algarrobal',
    parent: '1803',
    department_id: '18'
  },
  {
    id: '180303',
    name: 'Pacocha',
    parent: '1803',
    department_id: '18'
  },
  {
    id: '190101',
    name: 'Chaupimarca',
    parent: '1901',
    department_id: '19'
  },
  {
    id: '190102',
    name: 'Huachon',
    parent: '1901',
    department_id: '19'
  },
  {
    id: '190103',
    name: 'Huariaca',
    parent: '1901',
    department_id: '19'
  },
  {
    id: '190104',
    name: 'Huayllay',
    parent: '1901',
    department_id: '19'
  },
  {
    id: '190105',
    name: 'Ninacaca',
    parent: '1901',
    department_id: '19'
  },
  {
    id: '190106',
    name: 'Pallanchacra',
    parent: '1901',
    department_id: '19'
  },
  {
    id: '190107',
    name: 'Paucartambo',
    parent: '1901',
    department_id: '19'
  },
  {
    id: '190108',
    name: 'San Francisco de Asís de Yarusyacan',
    parent: '1901',
    department_id: '19'
  },
  {
    id: '190109',
    name: 'Simon Bolívar',
    parent: '1901',
    department_id: '19'
  },
  {
    id: '190110',
    name: 'Ticlacayan',
    parent: '1901',
    department_id: '19'
  },
  {
    id: '190111',
    name: 'Tinyahuarco',
    parent: '1901',
    department_id: '19'
  },
  {
    id: '190112',
    name: 'Vicco',
    parent: '1901',
    department_id: '19'
  },
  {
    id: '190113',
    name: 'Yanacancha',
    parent: '1901',
    department_id: '19'
  },
  {
    id: '190201',
    name: 'Yanahuanca',
    parent: '1902',
    department_id: '19'
  },
  {
    id: '190202',
    name: 'Chacayan',
    parent: '1902',
    department_id: '19'
  },
  {
    id: '190203',
    name: 'Goyllarisquizga',
    parent: '1902',
    department_id: '19'
  },
  {
    id: '190204',
    name: 'Paucar',
    parent: '1902',
    department_id: '19'
  },
  {
    id: '190205',
    name: 'San Pedro de Pillao',
    parent: '1902',
    department_id: '19'
  },
  {
    id: '190206',
    name: 'Santa Ana de Tusi',
    parent: '1902',
    department_id: '19'
  },
  {
    id: '190207',
    name: 'Tapuc',
    parent: '1902',
    department_id: '19'
  },
  {
    id: '190208',
    name: 'Vilcabamba',
    parent: '1902',
    department_id: '19'
  },
  {
    id: '190301',
    name: 'Oxapampa',
    parent: '1903',
    department_id: '19'
  },
  {
    id: '190302',
    name: 'Chontabamba',
    parent: '1903',
    department_id: '19'
  },
  {
    id: '190303',
    name: 'Huancabamba',
    parent: '1903',
    department_id: '19'
  },
  {
    id: '190304',
    name: 'Palcazu',
    parent: '1903',
    department_id: '19'
  },
  {
    id: '190305',
    name: 'Pozuzo',
    parent: '1903',
    department_id: '19'
  },
  {
    id: '190306',
    name: 'Puerto Bermúdez',
    parent: '1903',
    department_id: '19'
  },
  {
    id: '190307',
    name: 'Villa Rica',
    parent: '1903',
    department_id: '19'
  },
  {
    id: '190308',
    name: 'Constitución',
    parent: '1903',
    department_id: '19'
  },
  {
    id: '200101',
    name: 'Piura',
    parent: '2001',
    department_id: '20'
  },
  {
    id: '200104',
    name: 'Castilla',
    parent: '2001',
    department_id: '20'
  },
  {
    id: '200105',
    name: 'Catacaos',
    parent: '2001',
    department_id: '20'
  },
  {
    id: '200107',
    name: 'Cura Mori',
    parent: '2001',
    department_id: '20'
  },
  {
    id: '200108',
    name: 'El Tallan',
    parent: '2001',
    department_id: '20'
  },
  {
    id: '200109',
    name: 'La Arena',
    parent: '2001',
    department_id: '20'
  },
  {
    id: '200110',
    name: 'La Unión',
    parent: '2001',
    department_id: '20'
  },
  {
    id: '200111',
    name: 'Las Lomas',
    parent: '2001',
    department_id: '20'
  },
  {
    id: '200114',
    name: 'Tambo Grande',
    parent: '2001',
    department_id: '20'
  },
  {
    id: '200115',
    name: 'Veintiseis de Octubre',
    parent: '2001',
    department_id: '20'
  },
  {
    id: '200201',
    name: 'Ayabaca',
    parent: '2002',
    department_id: '20'
  },
  {
    id: '200202',
    name: 'Frias',
    parent: '2002',
    department_id: '20'
  },
  {
    id: '200203',
    name: 'Jilili',
    parent: '2002',
    department_id: '20'
  },
  {
    id: '200204',
    name: 'Lagunas',
    parent: '2002',
    department_id: '20'
  },
  {
    id: '200205',
    name: 'Montero',
    parent: '2002',
    department_id: '20'
  },
  {
    id: '200206',
    name: 'Pacaipampa',
    parent: '2002',
    department_id: '20'
  },
  {
    id: '200207',
    name: 'Paimas',
    parent: '2002',
    department_id: '20'
  },
  {
    id: '200208',
    name: 'Sapillica',
    parent: '2002',
    department_id: '20'
  },
  {
    id: '200209',
    name: 'Sicchez',
    parent: '2002',
    department_id: '20'
  },
  {
    id: '200210',
    name: 'Suyo',
    parent: '2002',
    department_id: '20'
  },
  {
    id: '200301',
    name: 'Huancabamba',
    parent: '2003',
    department_id: '20'
  },
  {
    id: '200302',
    name: 'Canchaque',
    parent: '2003',
    department_id: '20'
  },
  {
    id: '200303',
    name: 'El Carmen de la Frontera',
    parent: '2003',
    department_id: '20'
  },
  {
    id: '200304',
    name: 'Huarmaca',
    parent: '2003',
    department_id: '20'
  },
  {
    id: '200305',
    name: 'Lalaquiz',
    parent: '2003',
    department_id: '20'
  },
  {
    id: '200306',
    name: 'San Miguel de El Faique',
    parent: '2003',
    department_id: '20'
  },
  {
    id: '200307',
    name: 'Sondor',
    parent: '2003',
    department_id: '20'
  },
  {
    id: '200308',
    name: 'Sondorillo',
    parent: '2003',
    department_id: '20'
  },
  {
    id: '200401',
    name: 'Chulucanas',
    parent: '2004',
    department_id: '20'
  },
  {
    id: '200402',
    name: 'Buenos Aires',
    parent: '2004',
    department_id: '20'
  },
  {
    id: '200403',
    name: 'Chalaco',
    parent: '2004',
    department_id: '20'
  },
  {
    id: '200404',
    name: 'La Matanza',
    parent: '2004',
    department_id: '20'
  },
  {
    id: '200405',
    name: 'Morropon',
    parent: '2004',
    department_id: '20'
  },
  {
    id: '200406',
    name: 'Salitral',
    parent: '2004',
    department_id: '20'
  },
  {
    id: '200407',
    name: 'San Juan de Bigote',
    parent: '2004',
    department_id: '20'
  },
  {
    id: '200408',
    name: 'Santa Catalina de Mossa',
    parent: '2004',
    department_id: '20'
  },
  {
    id: '200409',
    name: 'Santo Domingo',
    parent: '2004',
    department_id: '20'
  },
  {
    id: '200410',
    name: 'Yamango',
    parent: '2004',
    department_id: '20'
  },
  {
    id: '200501',
    name: 'Paita',
    parent: '2005',
    department_id: '20'
  },
  {
    id: '200502',
    name: 'Amotape',
    parent: '2005',
    department_id: '20'
  },
  {
    id: '200503',
    name: 'Arenal',
    parent: '2005',
    department_id: '20'
  },
  {
    id: '200504',
    name: 'Colan',
    parent: '2005',
    department_id: '20'
  },
  {
    id: '200505',
    name: 'La Huaca',
    parent: '2005',
    department_id: '20'
  },
  {
    id: '200506',
    name: 'Tamarindo',
    parent: '2005',
    department_id: '20'
  },
  {
    id: '200507',
    name: 'Vichayal',
    parent: '2005',
    department_id: '20'
  },
  {
    id: '200601',
    name: 'Sullana',
    parent: '2006',
    department_id: '20'
  },
  {
    id: '200602',
    name: 'Bellavista',
    parent: '2006',
    department_id: '20'
  },
  {
    id: '200603',
    name: 'Ignacio Escudero',
    parent: '2006',
    department_id: '20'
  },
  {
    id: '200604',
    name: 'Lancones',
    parent: '2006',
    department_id: '20'
  },
  {
    id: '200605',
    name: 'Marcavelica',
    parent: '2006',
    department_id: '20'
  },
  {
    id: '200606',
    name: 'Miguel Checa',
    parent: '2006',
    department_id: '20'
  },
  {
    id: '200607',
    name: 'Querecotillo',
    parent: '2006',
    department_id: '20'
  },
  {
    id: '200608',
    name: 'Salitral',
    parent: '2006',
    department_id: '20'
  },
  {
    id: '200701',
    name: 'Pariñas',
    parent: '2007',
    department_id: '20'
  },
  {
    id: '200702',
    name: 'El Alto',
    parent: '2007',
    department_id: '20'
  },
  {
    id: '200703',
    name: 'La Brea',
    parent: '2007',
    department_id: '20'
  },
  {
    id: '200704',
    name: 'Lobitos',
    parent: '2007',
    department_id: '20'
  },
  {
    id: '200705',
    name: 'Los Organos',
    parent: '2007',
    department_id: '20'
  },
  {
    id: '200706',
    name: 'Mancora',
    parent: '2007',
    department_id: '20'
  },
  {
    id: '200801',
    name: 'Sechura',
    parent: '2008',
    department_id: '20'
  },
  {
    id: '200802',
    name: 'Bellavista de la Unión',
    parent: '2008',
    department_id: '20'
  },
  {
    id: '200803',
    name: 'Bernal',
    parent: '2008',
    department_id: '20'
  },
  {
    id: '200804',
    name: 'Cristo Nos Valga',
    parent: '2008',
    department_id: '20'
  },
  {
    id: '200805',
    name: 'Vice',
    parent: '2008',
    department_id: '20'
  },
  {
    id: '200806',
    name: 'Rinconada Llicuar',
    parent: '2008',
    department_id: '20'
  },
  {
    id: '210101',
    name: 'Puno',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210102',
    name: 'Acora',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210103',
    name: 'Amantani',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210104',
    name: 'Atuncolla',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210105',
    name: 'Capachica',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210106',
    name: 'Chucuito',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210107',
    name: 'Coata',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210108',
    name: 'Huata',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210109',
    name: 'Mañazo',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210110',
    name: 'Paucarcolla',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210111',
    name: 'Pichacani',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210112',
    name: 'Plateria',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210113',
    name: 'San Antonio',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210114',
    name: 'Tiquillaca',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210115',
    name: 'Vilque',
    parent: '2101',
    department_id: '21'
  },
  {
    id: '210201',
    name: 'Azángaro',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210202',
    name: 'Achaya',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210203',
    name: 'Arapa',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210204',
    name: 'Asillo',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210205',
    name: 'Caminaca',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210206',
    name: 'Chupa',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210207',
    name: 'José Domingo Choquehuanca',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210208',
    name: 'Muñani',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210209',
    name: 'Potoni',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210210',
    name: 'Saman',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210211',
    name: 'San Anton',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210212',
    name: 'San José',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210213',
    name: 'San Juan de Salinas',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210214',
    name: 'Santiago de Pupuja',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210215',
    name: 'Tirapata',
    parent: '2102',
    department_id: '21'
  },
  {
    id: '210301',
    name: 'Macusani',
    parent: '2103',
    department_id: '21'
  },
  {
    id: '210302',
    name: 'Ajoyani',
    parent: '2103',
    department_id: '21'
  },
  {
    id: '210303',
    name: 'Ayapata',
    parent: '2103',
    department_id: '21'
  },
  {
    id: '210304',
    name: 'Coasa',
    parent: '2103',
    department_id: '21'
  },
  {
    id: '210305',
    name: 'Corani',
    parent: '2103',
    department_id: '21'
  },
  {
    id: '210306',
    name: 'Crucero',
    parent: '2103',
    department_id: '21'
  },
  {
    id: '210307',
    name: 'Ituata',
    parent: '2103',
    department_id: '21'
  },
  {
    id: '210308',
    name: 'Ollachea',
    parent: '2103',
    department_id: '21'
  },
  {
    id: '210309',
    name: 'San Gaban',
    parent: '2103',
    department_id: '21'
  },
  {
    id: '210310',
    name: 'Usicayos',
    parent: '2103',
    department_id: '21'
  },
  {
    id: '210401',
    name: 'Juli',
    parent: '2104',
    department_id: '21'
  },
  {
    id: '210402',
    name: 'Desaguadero',
    parent: '2104',
    department_id: '21'
  },
  {
    id: '210403',
    name: 'Huacullani',
    parent: '2104',
    department_id: '21'
  },
  {
    id: '210404',
    name: 'Kelluyo',
    parent: '2104',
    department_id: '21'
  },
  {
    id: '210405',
    name: 'Pisacoma',
    parent: '2104',
    department_id: '21'
  },
  {
    id: '210406',
    name: 'Pomata',
    parent: '2104',
    department_id: '21'
  },
  {
    id: '210407',
    name: 'Zepita',
    parent: '2104',
    department_id: '21'
  },
  {
    id: '210501',
    name: 'Ilave',
    parent: '2105',
    department_id: '21'
  },
  {
    id: '210502',
    name: 'Capazo',
    parent: '2105',
    department_id: '21'
  },
  {
    id: '210503',
    name: 'Pilcuyo',
    parent: '2105',
    department_id: '21'
  },
  {
    id: '210504',
    name: 'Santa Rosa',
    parent: '2105',
    department_id: '21'
  },
  {
    id: '210505',
    name: 'Conduriri',
    parent: '2105',
    department_id: '21'
  },
  {
    id: '210601',
    name: 'Huancane',
    parent: '2106',
    department_id: '21'
  },
  {
    id: '210602',
    name: 'Cojata',
    parent: '2106',
    department_id: '21'
  },
  {
    id: '210603',
    name: 'Huatasani',
    parent: '2106',
    department_id: '21'
  },
  {
    id: '210604',
    name: 'Inchupalla',
    parent: '2106',
    department_id: '21'
  },
  {
    id: '210605',
    name: 'Pusi',
    parent: '2106',
    department_id: '21'
  },
  {
    id: '210606',
    name: 'Rosaspata',
    parent: '2106',
    department_id: '21'
  },
  {
    id: '210607',
    name: 'Taraco',
    parent: '2106',
    department_id: '21'
  },
  {
    id: '210608',
    name: 'Vilque Chico',
    parent: '2106',
    department_id: '21'
  },
  {
    id: '210701',
    name: 'Lampa',
    parent: '2107',
    department_id: '21'
  },
  {
    id: '210702',
    name: 'Cabanilla',
    parent: '2107',
    department_id: '21'
  },
  {
    id: '210703',
    name: 'Calapuja',
    parent: '2107',
    department_id: '21'
  },
  {
    id: '210704',
    name: 'Nicasio',
    parent: '2107',
    department_id: '21'
  },
  {
    id: '210705',
    name: 'Ocuviri',
    parent: '2107',
    department_id: '21'
  },
  {
    id: '210706',
    name: 'Palca',
    parent: '2107',
    department_id: '21'
  },
  {
    id: '210707',
    name: 'Paratia',
    parent: '2107',
    department_id: '21'
  },
  {
    id: '210708',
    name: 'Pucara',
    parent: '2107',
    department_id: '21'
  },
  {
    id: '210709',
    name: 'Santa Lucia',
    parent: '2107',
    department_id: '21'
  },
  {
    id: '210710',
    name: 'Vilavila',
    parent: '2107',
    department_id: '21'
  },
  {
    id: '210801',
    name: 'Ayaviri',
    parent: '2108',
    department_id: '21'
  },
  {
    id: '210802',
    name: 'Antauta',
    parent: '2108',
    department_id: '21'
  },
  {
    id: '210803',
    name: 'Cupi',
    parent: '2108',
    department_id: '21'
  },
  {
    id: '210804',
    name: 'Llalli',
    parent: '2108',
    department_id: '21'
  },
  {
    id: '210805',
    name: 'Macari',
    parent: '2108',
    department_id: '21'
  },
  {
    id: '210806',
    name: 'Nuñoa',
    parent: '2108',
    department_id: '21'
  },
  {
    id: '210807',
    name: 'Orurillo',
    parent: '2108',
    department_id: '21'
  },
  {
    id: '210808',
    name: 'Santa Rosa',
    parent: '2108',
    department_id: '21'
  },
  {
    id: '210809',
    name: 'Umachiri',
    parent: '2108',
    department_id: '21'
  },
  {
    id: '210901',
    name: 'Moho',
    parent: '2109',
    department_id: '21'
  },
  {
    id: '210902',
    name: 'Conima',
    parent: '2109',
    department_id: '21'
  },
  {
    id: '210903',
    name: 'Huayrapata',
    parent: '2109',
    department_id: '21'
  },
  {
    id: '210904',
    name: 'Tilali',
    parent: '2109',
    department_id: '21'
  },
  {
    id: '211001',
    name: 'Putina',
    parent: '2110',
    department_id: '21'
  },
  {
    id: '211002',
    name: 'Ananea',
    parent: '2110',
    department_id: '21'
  },
  {
    id: '211003',
    name: 'Pedro Vilca Apaza',
    parent: '2110',
    department_id: '21'
  },
  {
    id: '211004',
    name: 'Quilcapuncu',
    parent: '2110',
    department_id: '21'
  },
  {
    id: '211005',
    name: 'Sina',
    parent: '2110',
    department_id: '21'
  },
  {
    id: '211101',
    name: 'Juliaca',
    parent: '2111',
    department_id: '21'
  },
  {
    id: '211102',
    name: 'Cabana',
    parent: '2111',
    department_id: '21'
  },
  {
    id: '211103',
    name: 'Cabanillas',
    parent: '2111',
    department_id: '21'
  },
  {
    id: '211104',
    name: 'Caracoto',
    parent: '2111',
    department_id: '21'
  },
  {
    id: '211105',
    name: 'San Miguel',
    parent: '2111',
    department_id: '21'
  },
  {
    id: '211201',
    name: 'Sandia',
    parent: '2112',
    department_id: '21'
  },
  {
    id: '211202',
    name: 'Cuyocuyo',
    parent: '2112',
    department_id: '21'
  },
  {
    id: '211203',
    name: 'Limbani',
    parent: '2112',
    department_id: '21'
  },
  {
    id: '211204',
    name: 'Patambuco',
    parent: '2112',
    department_id: '21'
  },
  {
    id: '211205',
    name: 'Phara',
    parent: '2112',
    department_id: '21'
  },
  {
    id: '211206',
    name: 'Quiaca',
    parent: '2112',
    department_id: '21'
  },
  {
    id: '211207',
    name: 'San Juan del Oro',
    parent: '2112',
    department_id: '21'
  },
  {
    id: '211208',
    name: 'Yanahuaya',
    parent: '2112',
    department_id: '21'
  },
  {
    id: '211209',
    name: 'Alto Inambari',
    parent: '2112',
    department_id: '21'
  },
  {
    id: '211210',
    name: 'San Pedro de Putina Punco',
    parent: '2112',
    department_id: '21'
  },
  {
    id: '211301',
    name: 'Yunguyo',
    parent: '2113',
    department_id: '21'
  },
  {
    id: '211302',
    name: 'Anapia',
    parent: '2113',
    department_id: '21'
  },
  {
    id: '211303',
    name: 'Copani',
    parent: '2113',
    department_id: '21'
  },
  {
    id: '211304',
    name: 'Cuturapi',
    parent: '2113',
    department_id: '21'
  },
  {
    id: '211305',
    name: 'Ollaraya',
    parent: '2113',
    department_id: '21'
  },
  {
    id: '211306',
    name: 'Tinicachi',
    parent: '2113',
    department_id: '21'
  },
  {
    id: '211307',
    name: 'Unicachi',
    parent: '2113',
    department_id: '21'
  },
  {
    id: '220101',
    name: 'Moyobamba',
    parent: '2201',
    department_id: '22'
  },
  {
    id: '220102',
    name: 'Calzada',
    parent: '2201',
    department_id: '22'
  },
  {
    id: '220103',
    name: 'Habana',
    parent: '2201',
    department_id: '22'
  },
  {
    id: '220104',
    name: 'Jepelacio',
    parent: '2201',
    department_id: '22'
  },
  {
    id: '220105',
    name: 'Soritor',
    parent: '2201',
    department_id: '22'
  },
  {
    id: '220106',
    name: 'Yantalo',
    parent: '2201',
    department_id: '22'
  },
  {
    id: '220201',
    name: 'Bellavista',
    parent: '2202',
    department_id: '22'
  },
  {
    id: '220202',
    name: 'Alto Biavo',
    parent: '2202',
    department_id: '22'
  },
  {
    id: '220203',
    name: 'Bajo Biavo',
    parent: '2202',
    department_id: '22'
  },
  {
    id: '220204',
    name: 'Huallaga',
    parent: '2202',
    department_id: '22'
  },
  {
    id: '220205',
    name: 'San Pablo',
    parent: '2202',
    department_id: '22'
  },
  {
    id: '220206',
    name: 'San Rafael',
    parent: '2202',
    department_id: '22'
  },
  {
    id: '220301',
    name: 'San José de Sisa',
    parent: '2203',
    department_id: '22'
  },
  {
    id: '220302',
    name: 'Agua Blanca',
    parent: '2203',
    department_id: '22'
  },
  {
    id: '220303',
    name: 'San Martín',
    parent: '2203',
    department_id: '22'
  },
  {
    id: '220304',
    name: 'Santa Rosa',
    parent: '2203',
    department_id: '22'
  },
  {
    id: '220305',
    name: 'Shatoja',
    parent: '2203',
    department_id: '22'
  },
  {
    id: '220401',
    name: 'Saposoa',
    parent: '2204',
    department_id: '22'
  },
  {
    id: '220402',
    name: 'Alto Saposoa',
    parent: '2204',
    department_id: '22'
  },
  {
    id: '220403',
    name: 'El Eslabón',
    parent: '2204',
    department_id: '22'
  },
  {
    id: '220404',
    name: 'Piscoyacu',
    parent: '2204',
    department_id: '22'
  },
  {
    id: '220405',
    name: 'Sacanche',
    parent: '2204',
    department_id: '22'
  },
  {
    id: '220406',
    name: 'Tingo de Saposoa',
    parent: '2204',
    department_id: '22'
  },
  {
    id: '220501',
    name: 'Lamas',
    parent: '2205',
    department_id: '22'
  },
  {
    id: '220502',
    name: 'Alonso de Alvarado',
    parent: '2205',
    department_id: '22'
  },
  {
    id: '220503',
    name: 'Barranquita',
    parent: '2205',
    department_id: '22'
  },
  {
    id: '220504',
    name: 'Caynarachi',
    parent: '2205',
    department_id: '22'
  },
  {
    id: '220505',
    name: 'Cuñumbuqui',
    parent: '2205',
    department_id: '22'
  },
  {
    id: '220506',
    name: 'Pinto Recodo',
    parent: '2205',
    department_id: '22'
  },
  {
    id: '220507',
    name: 'Rumisapa',
    parent: '2205',
    department_id: '22'
  },
  {
    id: '220508',
    name: 'San Roque de Cumbaza',
    parent: '2205',
    department_id: '22'
  },
  {
    id: '220509',
    name: 'Shanao',
    parent: '2205',
    department_id: '22'
  },
  {
    id: '220510',
    name: 'Tabalosos',
    parent: '2205',
    department_id: '22'
  },
  {
    id: '220511',
    name: 'Zapatero',
    parent: '2205',
    department_id: '22'
  },
  {
    id: '220601',
    name: 'Juanjuí',
    parent: '2206',
    department_id: '22'
  },
  {
    id: '220602',
    name: 'Campanilla',
    parent: '2206',
    department_id: '22'
  },
  {
    id: '220603',
    name: 'Huicungo',
    parent: '2206',
    department_id: '22'
  },
  {
    id: '220604',
    name: 'Pachiza',
    parent: '2206',
    department_id: '22'
  },
  {
    id: '220605',
    name: 'Pajarillo',
    parent: '2206',
    department_id: '22'
  },
  {
    id: '220701',
    name: 'Picota',
    parent: '2207',
    department_id: '22'
  },
  {
    id: '220702',
    name: 'Buenos Aires',
    parent: '2207',
    department_id: '22'
  },
  {
    id: '220703',
    name: 'Caspisapa',
    parent: '2207',
    department_id: '22'
  },
  {
    id: '220704',
    name: 'Pilluana',
    parent: '2207',
    department_id: '22'
  },
  {
    id: '220705',
    name: 'Pucacaca',
    parent: '2207',
    department_id: '22'
  },
  {
    id: '220706',
    name: 'San Cristóbal',
    parent: '2207',
    department_id: '22'
  },
  {
    id: '220707',
    name: 'San Hilarión',
    parent: '2207',
    department_id: '22'
  },
  {
    id: '220708',
    name: 'Shamboyacu',
    parent: '2207',
    department_id: '22'
  },
  {
    id: '220709',
    name: 'Tingo de Ponasa',
    parent: '2207',
    department_id: '22'
  },
  {
    id: '220710',
    name: 'Tres Unidos',
    parent: '2207',
    department_id: '22'
  },
  {
    id: '220801',
    name: 'Rioja',
    parent: '2208',
    department_id: '22'
  },
  {
    id: '220802',
    name: 'Awajun',
    parent: '2208',
    department_id: '22'
  },
  {
    id: '220803',
    name: 'Elías Soplin Vargas',
    parent: '2208',
    department_id: '22'
  },
  {
    id: '220804',
    name: 'Nueva Cajamarca',
    parent: '2208',
    department_id: '22'
  },
  {
    id: '220805',
    name: 'Pardo Miguel',
    parent: '2208',
    department_id: '22'
  },
  {
    id: '220806',
    name: 'Posic',
    parent: '2208',
    department_id: '22'
  },
  {
    id: '220807',
    name: 'San Fernando',
    parent: '2208',
    department_id: '22'
  },
  {
    id: '220808',
    name: 'Yorongos',
    parent: '2208',
    department_id: '22'
  },
  {
    id: '220809',
    name: 'Yuracyacu',
    parent: '2208',
    department_id: '22'
  },
  {
    id: '220901',
    name: 'Tarapoto',
    parent: '2209',
    department_id: '22'
  },
  {
    id: '220902',
    name: 'Alberto Leveau',
    parent: '2209',
    department_id: '22'
  },
  {
    id: '220903',
    name: 'Cacatachi',
    parent: '2209',
    department_id: '22'
  },
  {
    id: '220904',
    name: 'Chazuta',
    parent: '2209',
    department_id: '22'
  },
  {
    id: '220905',
    name: 'Chipurana',
    parent: '2209',
    department_id: '22'
  },
  {
    id: '220906',
    name: 'El Porvenir',
    parent: '2209',
    department_id: '22'
  },
  {
    id: '220907',
    name: 'Huimbayoc',
    parent: '2209',
    department_id: '22'
  },
  {
    id: '220908',
    name: 'Juan Guerra',
    parent: '2209',
    department_id: '22'
  },
  {
    id: '220909',
    name: 'La Banda de Shilcayo',
    parent: '2209',
    department_id: '22'
  },
  {
    id: '220910',
    name: 'Morales',
    parent: '2209',
    department_id: '22'
  },
  {
    id: '220911',
    name: 'Papaplaya',
    parent: '2209',
    department_id: '22'
  },
  {
    id: '220912',
    name: 'San Antonio',
    parent: '2209',
    department_id: '22'
  },
  {
    id: '220913',
    name: 'Sauce',
    parent: '2209',
    department_id: '22'
  },
  {
    id: '220914',
    name: 'Shapaja',
    parent: '2209',
    department_id: '22'
  },
  {
    id: '221001',
    name: 'Tocache',
    parent: '2210',
    department_id: '22'
  },
  {
    id: '221002',
    name: 'Nuevo Progreso',
    parent: '2210',
    department_id: '22'
  },
  {
    id: '221003',
    name: 'Polvora',
    parent: '2210',
    department_id: '22'
  },
  {
    id: '221004',
    name: 'Shunte',
    parent: '2210',
    department_id: '22'
  },
  {
    id: '221005',
    name: 'Uchiza',
    parent: '2210',
    department_id: '22'
  },
  {
    id: '230101',
    name: 'Tacna',
    parent: '2301',
    department_id: '23'
  },
  {
    id: '230102',
    name: 'Alto de la Alianza',
    parent: '2301',
    department_id: '23'
  },
  {
    id: '230103',
    name: 'Calana',
    parent: '2301',
    department_id: '23'
  },
  {
    id: '230104',
    name: 'Ciudad Nueva',
    parent: '2301',
    department_id: '23'
  },
  {
    id: '230105',
    name: 'Inclan',
    parent: '2301',
    department_id: '23'
  },
  {
    id: '230106',
    name: 'Pachia',
    parent: '2301',
    department_id: '23'
  },
  {
    id: '230107',
    name: 'Palca',
    parent: '2301',
    department_id: '23'
  },
  {
    id: '230108',
    name: 'Pocollay',
    parent: '2301',
    department_id: '23'
  },
  {
    id: '230109',
    name: 'Sama',
    parent: '2301',
    department_id: '23'
  },
  {
    id: '230110',
    name: 'Coronel Gregorio Albarracín Lanchipa',
    parent: '2301',
    department_id: '23'
  },
  {
    id: '230111',
    name: 'La Yarada los Palos',
    parent: '2301',
    department_id: '23'
  },
  {
    id: '230201',
    name: 'Candarave',
    parent: '2302',
    department_id: '23'
  },
  {
    id: '230202',
    name: 'Cairani',
    parent: '2302',
    department_id: '23'
  },
  {
    id: '230203',
    name: 'Camilaca',
    parent: '2302',
    department_id: '23'
  },
  {
    id: '230204',
    name: 'Curibaya',
    parent: '2302',
    department_id: '23'
  },
  {
    id: '230205',
    name: 'Huanuara',
    parent: '2302',
    department_id: '23'
  },
  {
    id: '230206',
    name: 'Quilahuani',
    parent: '2302',
    department_id: '23'
  },
  {
    id: '230301',
    name: 'Locumba',
    parent: '2303',
    department_id: '23'
  },
  {
    id: '230302',
    name: 'Ilabaya',
    parent: '2303',
    department_id: '23'
  },
  {
    id: '230303',
    name: 'Ite',
    parent: '2303',
    department_id: '23'
  },
  {
    id: '230401',
    name: 'Tarata',
    parent: '2304',
    department_id: '23'
  },
  {
    id: '230402',
    name: 'Héroes Albarracín',
    parent: '2304',
    department_id: '23'
  },
  {
    id: '230403',
    name: 'Estique',
    parent: '2304',
    department_id: '23'
  },
  {
    id: '230404',
    name: 'Estique-Pampa',
    parent: '2304',
    department_id: '23'
  },
  {
    id: '230405',
    name: 'Sitajara',
    parent: '2304',
    department_id: '23'
  },
  {
    id: '230406',
    name: 'Susapaya',
    parent: '2304',
    department_id: '23'
  },
  {
    id: '230407',
    name: 'Tarucachi',
    parent: '2304',
    department_id: '23'
  },
  {
    id: '230408',
    name: 'Ticaco',
    parent: '2304',
    department_id: '23'
  },
  {
    id: '240101',
    name: 'Tumbes',
    parent: '2401',
    department_id: '24'
  },
  {
    id: '240102',
    name: 'Corrales',
    parent: '2401',
    department_id: '24'
  },
  {
    id: '240103',
    name: 'La Cruz',
    parent: '2401',
    department_id: '24'
  },
  {
    id: '240104',
    name: 'Pampas de Hospital',
    parent: '2401',
    department_id: '24'
  },
  {
    id: '240105',
    name: 'San Jacinto',
    parent: '2401',
    department_id: '24'
  },
  {
    id: '240106',
    name: 'San Juan de la Virgen',
    parent: '2401',
    department_id: '24'
  },
  {
    id: '240201',
    name: 'Zorritos',
    parent: '2402',
    department_id: '24'
  },
  {
    id: '240202',
    name: 'Casitas',
    parent: '2402',
    department_id: '24'
  },
  {
    id: '240203',
    name: 'Canoas de Punta Sal',
    parent: '2402',
    department_id: '24'
  },
  {
    id: '240301',
    name: 'Zarumilla',
    parent: '2403',
    department_id: '24'
  },
  {
    id: '240302',
    name: 'Aguas Verdes',
    parent: '2403',
    department_id: '24'
  },
  {
    id: '240303',
    name: 'Matapalo',
    parent: '2403',
    department_id: '24'
  },
  {
    id: '240304',
    name: 'Papayal',
    parent: '2403',
    department_id: '24'
  },
  {
    id: '250101',
    name: 'Calleria',
    parent: '2501',
    department_id: '25'
  },
  {
    id: '250102',
    name: 'Campoverde',
    parent: '2501',
    department_id: '25'
  },
  {
    id: '250103',
    name: 'Iparia',
    parent: '2501',
    department_id: '25'
  },
  {
    id: '250104',
    name: 'Masisea',
    parent: '2501',
    department_id: '25'
  },
  {
    id: '250105',
    name: 'Yarinacocha',
    parent: '2501',
    department_id: '25'
  },
  {
    id: '250106',
    name: 'Nueva Requena',
    parent: '2501',
    department_id: '25'
  },
  {
    id: '250107',
    name: 'Manantay',
    parent: '2501',
    department_id: '25'
  },
  {
    id: '250201',
    name: 'Raymondi',
    parent: '2502',
    department_id: '25'
  },
  {
    id: '250202',
    name: 'Sepahua',
    parent: '2502',
    department_id: '25'
  },
  {
    id: '250203',
    name: 'Tahuania',
    parent: '2502',
    department_id: '25'
  },
  {
    id: '250204',
    name: 'Yurua',
    parent: '2502',
    department_id: '25'
  },
  {
    id: '250301',
    name: 'Padre Abad',
    parent: '2503',
    department_id: '25'
  },
  {
    id: '250302',
    name: 'Irazola',
    parent: '2503',
    department_id: '25'
  },
  {
    id: '250303',
    name: 'Curimana',
    parent: '2503',
    department_id: '25'
  },
  {
    id: '250304',
    name: 'Neshuya',
    parent: '2503',
    department_id: '25'
  },
  {
    id: '250305',
    name: 'Alexander Von Humboldt',
    parent: '2503',
    department_id: '25'
  },
  {
    id: '250401',
    name: 'Purus',
    parent: '2504',
    department_id: '25'
  }
]
