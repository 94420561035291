import React from "react";

import UserActions from "./UserActions";

export default () => {
  return (
    <ul className="border-left flex-row navbar-nav px-3 border-start">
      <UserActions />
    </ul>
  );
};
