import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { fecthLogout, setMenuMobile } from "../../../action";
import SidebarNavCollapseItem from "../MainSidebar/SidebarNavCollapse";

const NavbarToggle = (props) => {

  const {handleSetMenuMobile,menuMobile,listNavbar} = props

  const handleClick = () => {
    handleSetMenuMobile(!menuMobile);
  }

  const setNavLinkComponent = (props) => {
    const { to, children } = props;
    return <NavLink {...props} to={to} onClick={() => handleSetMenuMobile(false)}>{children}</NavLink>;
  }

  const logout = () => {
    const { logout } = props;
    localStorage.removeItem("user");
    logout();
    window.location.href = "/Login";
  };

  return (
    <nav className="nav">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      {/* <a
        onClick={handleClick}
        className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
        style={{cursor: 'pointer'}}
      > 
        {menuMobile ?
          <i className="material-icons">&#xE5CD;</i>
          :
          <i className="material-icons">&#xE5D2;</i>
        }
        
      </a> */}

      <div className="menuMobile" style={{display:menuMobile ? 'block' : 'none'}}>

        <ul className="userMenuMobile">
          <li className="nav-item">
            <a className="nav-link" href="/user-profile-lite">
              <div className="d-inline-block item-icon-wrapper">
                <i className="material-icons">person</i>
              </div>
              <span>Perfil</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#" onClick={logout} style={{cursor:'pointer'}} >
              <div className="d-inline-block item-icon-wrapper">
                <i className="material-icons">logout</i>
              </div>
              <span style={{alignSelf:'center'}}>Cerrar Sesión</span>
            </a>
          </li>
        </ul>

        <hr />

        <div className="nav-wrapper">
          <nav className="nav--no-borders flex-column">
            {listNavbar.map((item, idx) => {
              return (
                <SidebarNavCollapseItem
                  key={idx}
                  item={item}
                  hideLogoText={true}
                />
              );
            })}
          </nav>
        </div>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => ({
  listNavbar: state.app.listNavbar,
  menuMobile: state.app.menuMobile
});

const mapDispatchToProps = (dispatch) => ({
  handleSetMenuMobile: (payload) => {
    dispatch(setMenuMobile(payload));
  },
  logout: () => dispatch(fecthLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarToggle);
