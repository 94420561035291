const Icon = ({ name, classNameProps }) => {

  if (!name)
    return null

  return (
    <>
      <i className={`material-icons iconMenu ${classNameProps || ''}`}>{name}</i>
    </>
  )
}

export default Icon