import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import NavbarSearch from "./NavbarSearch";
import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";
import { connect } from "react-redux";

const MainNavbar = ({ stickyTop }) => {
  const classes = classNames(
    "main-navbar",
    "navbarResponsive",
    "bg-white",
    stickyTop && "sticky-top"
  );
  
  return (
    <div className={classes} >
      <nav className="p-0 navbar navbar-light">
        <NavbarSearch />
        <NavbarNav />
        <NavbarToggle />
      </nav>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,

  props: PropTypes.object,
};

MainNavbar.defaultProps = {
  stickyTop: true,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default  connect(mapStateToProps, mapDispatchToProps)(MainNavbar);
