export const region = [
  { id: 13, name: 'Región Metropolitana' },
  { id: 1, name: 'Región de Tarapacá (I)' },
  { id: 2, name: 'Región de Antofagasta (II)' },
  { id: 3, name: 'Región de Atacama (III)' },
  { id: 4, name: 'Región de Coquimbo (IV)' },
  { id: 5, name: 'Región de Valparaíso (V)' },
  {
    id: 6,
    name: 'Región del Libertador General Bernardo O’Higgins (VI)'
  },
  { id: 7, name: 'Región del Maule (VII)' },
  { id: 8, name: 'Región del BioBío (VIII)' },
  { id: 9, name: 'Región de La Araucanía (IX)' },
  { id: 10, name: 'Región de Los Lagos (X)' },
  {
    id: 11,
    name: 'Región de Aysén del General Carlos Ibáñez del Campo (XI)'
  },
  {
    id: 12,
    name: 'Región de Magallanes y la Antártica Chilena (XII)'
  },
  { id: 13, name: 'Región Metropolitana' },
  { id: 14, name: 'Región de los Ríos (XIV)' },
  {
    id: 15,
    name: 'Región de Arica y Parinacota (XV)'
  },
  { id: 16, name: 'Región de Ñuble (XVI)' }
]

export const comuna = [
    { id: 1, name: 'Cerrillos', parent: 13 },
    { id: 2, name: 'Cerro Navia', parent: 13 },
    { id: 3, name: 'Conchalí', parent: 13 },
    { id: 4, name: 'El Bosque', parent: 13 },
    {
      id: 5,
      name: 'Estación Central',
      parent: 13
    },
    { id: 6, name: 'Huechuraba', parent: 13 },
    { id: 7, name: 'Independencia', parent: 13 },
    { id: 8, name: 'La Cisterna', parent: 13 },
    { id: 9, name: 'La Florida', parent: 13 },
    { id: 10, name: 'La Granja', parent: 13 },
    { id: 11, name: 'La Pintana', parent: 13 },
    { id: 12, name: 'La Reina', parent: 13 },
    { id: 13, name: 'Las Condes', parent: 13 },
    { id: 14, name: 'Lo Barnechea', parent: 13 },
    { id: 15, name: 'Lo Espejo', parent: 13 },
    { id: 16, name: 'Lo Prado', parent: 13 },
    { id: 17, name: 'Macul', parent: 13 },
    { id: 18, name: 'Maipú', parent: 13 },
    { id: 19, name: 'Ñuñoa', parent: 13 },
    {
      id: 20,
      name: 'Pedro Aguirre Cerda',
      parent: 13
    },
    { id: 21, name: 'Peñalolén', parent: 13 },
    { id: 22, name: 'Providencia', parent: 13 },
    { id: 23, name: 'Pudahuel', parent: 13 },
    { id: 24, name: 'Quilicura', parent: 13 },
    {
      id: 25,
      name: 'Quinta Normal',
      parent: 13
    },
    { id: 26, name: 'Recoleta', parent: 13 },
    { id: 27, name: 'Renca', parent: 13 },
    { id: 28, name: 'Santiago', parent: 13 },
    { id: 29, name: 'San Joaquín', parent: 13 },
    { id: 30, name: 'San Miguel', parent: 13 },
    { id: 31, name: 'San Ramón', parent: 13 },
    { id: 32, name: 'Vitacura', parent: 13 },
    { id: 33, name: 'Puente Alto', parent: 13 },
    { id: 34, name: 'Pirque', parent: 13 },
    {
      id: 35,
      name: 'San José de Maipo',
      parent: 13
    },
    { id: 36, name: 'Colina', parent: 13 },
    { id: 37, name: 'Lampa', parent: 13 },
    { id: 38, name: 'Tiltil', parent: 13 },
    { id: 39, name: 'San Bernardo', parent: 13 },
    { id: 40, name: 'Buin', parent: 13 },
    {
      id: 41,
      name: 'Calera de Tango',
      parent: 13
    },
    { id: 42, name: 'Paine', parent: 13 },
    { id: 43, name: 'Melipilla', parent: 13 },
    { id: 44, name: 'Alhué', parent: 13 },
    { id: 45, name: 'Curacaví', parent: 13 },
    { id: 46, name: 'María Pinto', parent: 13 },
    { id: 47, name: 'San Pedro', parent: 13 },
    { id: 48, name: 'Talagante', parent: 13 },
    { id: 49, name: 'El Monte', parent: 13 },
    {
      id: 50,
      name: 'Isla de Maipo',
      parent: 13
    },
    {
      id: 51,
      name: 'Padre Hurtado',
      parent: 13
    },
    { id: 52, name: 'Peñaflor', parent: 13 }
  ,
  
    { id: 53, name: 'Arica', parent: 15 },
    { id: 54, name: 'Camarones', parent: 15 },
    { id: 55, name: 'Putre', parent: 15 },
    {
      id: 56,
      name: 'General Lagos',
      parent: 15
    }
  ,
  
    { id: 57, name: 'Iquique', parent: 1 },
    { id: 58, name: 'Alto Hospicio', parent: 1 },
    { id: 59, name: 'Pozo Almonte', parent: 1 },
    { id: 60, name: 'Camiña', parent: 1 },
    { id: 61, name: 'Colchane', parent: 1 },
    { id: 62, name: 'Huara', parent: 1 },
    { id: 63, name: 'Pica', parent: 1 }
  ,
  
    { id: 64, name: 'Antofagasta', parent: 2 },
    { id: 65, name: 'Mejillones', parent: 2 },
    { id: 66, name: 'Sierra Gorda', parent: 2 },
    { id: 67, name: 'Taltal', parent: 2 },
    { id: 68, name: 'Calama', parent: 2 },
    { id: 69, name: 'Ollagüe', parent: 2 },
    {
      id: 70,
      name: 'San Pedro de Atacama',
      parent: 2
    },
    { id: 71, name: 'Tocopilla', parent: 2 },
    { id: 72, name: 'María Elena', parent: 2 }
  ,
  
    { id: 73, name: 'Copiapó', parent: 3 },
    { id: 74, name: 'Caldera', parent: 3 },
    {
      id: 75,
      name: 'Tierra Amarilla',
      parent: 3
    },
    { id: 76, name: 'Chañaral', parent: 3 },
    {
      id: 77,
      name: 'Diego de Almagro',
      parent: 3
    },
    { id: 78, name: 'Vallenar', parent: 3 },
    {
      id: 79,
      name: 'Alto del Carmen',
      parent: 3
    },
    { id: 80, name: 'Freirina', parent: 3 },
    { id: 81, name: 'Huasco', parent: 3 }
  ,
    { id: 82, name: 'La Serena', parent: 4 },
    { id: 83, name: 'Coquimbo', parent: 4 },
    { id: 84, name: 'Andacollo', parent: 4 },
    { id: 85, name: 'La Higuera', parent: 4 },
    { id: 86, name: 'Paiguano', parent: 4 },
    { id: 87, name: 'Vicuña', parent: 4 },
    { id: 88, name: 'Illapel', parent: 4 },
    { id: 89, name: 'Canela', parent: 4 },
    { id: 90, name: 'Los Vilos', parent: 4 },
    { id: 91, name: 'Salamanca', parent: 4 },
    { id: 92, name: 'Ovalle', parent: 4 },
    { id: 93, name: 'Combarbalá', parent: 4 },
    { id: 94, name: 'Monte Patria', parent: 4 },
    { id: 95, name: 'Punitaqui', parent: 4 },
    { id: 96, name: 'Río Hurtado', parent: 4 },
    { id: 97, name: 'Valparaíso', parent: 5 },
    { id: 98, name: 'Casablanca', parent: 5 },
    { id: 99, name: 'Concón', parent: 5 },
    {
      id: 100,
      name: 'Juan Fernández',
      parent: 5
    },
    { id: 101, name: 'Puchuncaví', parent: 5 },
    { id: 102, name: 'Quintero', parent: 5 },
    { id: 103, name: 'Viña del Mar', parent: 5 },
    {
      id: 104,
      name: 'Isla de Pascua',
      parent: 5
    },
    { id: 105, name: 'Los Andes', parent: 5 },
    { id: 106, name: 'Calle Larga', parent: 5 },
    { id: 107, name: 'Rinconada', parent: 5 },
    { id: 108, name: 'San Esteban', parent: 5 },
    { id: 109, name: 'La Ligua', parent: 5 },
    { id: 110, name: 'Cabildo', parent: 5 },
    { id: 111, name: 'Papudo', parent: 5 },
    { id: 112, name: 'Petorca', parent: 5 },
    { id: 113, name: 'Zapallar', parent: 5 },
    { id: 114, name: 'Quillota', parent: 5 },
    { id: 115, name: 'Calera', parent: 5 },
    { id: 116, name: 'Hijuelas', parent: 5 },
    { id: 117, name: 'La Cruz', parent: 5 },
    { id: 118, name: 'Nogales', parent: 5 },
    { id: 119, name: 'San Antonio', parent: 5 },
    { id: 120, name: 'Algarrobo', parent: 5 },
    { id: 121, name: 'Cartagena', parent: 5 },
    { id: 122, name: 'El Quisco', parent: 5 },
    { id: 123, name: 'El Tabo', parent: 5 },
    {
      id: 124,
      name: 'Santo Domingo',
      parent: 5
    },
    { id: 125, name: 'San Felipe', parent: 5 },
    { id: 126, name: 'Catemu', parent: 5 },
    { id: 127, name: 'Llaillay', parent: 5 },
    { id: 128, name: 'Panquehue', parent: 5 },
    { id: 129, name: 'Putaendo', parent: 5 },
    { id: 130, name: 'Santa María', parent: 5 },
    { id: 131, name: 'Quilpué', parent: 5 },
    { id: 132, name: 'Limache', parent: 5 },
    { id: 133, name: 'Olmué', parent: 5 },
    {
      id: 134,
      name: 'Villa Alemana',
      parent: 5
    }
  ,
  
    { id: 135, name: 'Rancagua', parent: 6 },
    { id: 136, name: 'Codegua', parent: 6 },
    { id: 137, name: 'Coinco', parent: 6 },
    { id: 138, name: 'Coltauco', parent: 6 },
    { id: 139, name: 'Doñihue', parent: 6 },
    { id: 140, name: 'Graneros', parent: 6 },
    { id: 141, name: 'Las Cabras', parent: 6 },
    { id: 142, name: 'Machalí', parent: 6 },
    { id: 143, name: 'Malloa', parent: 6 },
    { id: 144, name: 'Mostazal', parent: 6 },
    { id: 145, name: 'Olivar', parent: 6 },
    { id: 146, name: 'Peumo', parent: 6 },
    { id: 147, name: 'Pichidegua', parent: 6 },
    {
      id: 148,
      name: 'Quinta de Tilcoco',
      parent: 6
    },
    { id: 149, name: 'Rengo', parent: 6 },
    { id: 150, name: 'Requínoa', parent: 6 },
    { id: 151, name: 'San Vicente', parent: 6 },
    { id: 152, name: 'Pichilemu', parent: 6 },
    { id: 153, name: 'La Estrella', parent: 6 },
    { id: 154, name: 'Litueche', parent: 6 },
    { id: 155, name: 'Marchihue', parent: 6 },
    { id: 156, name: 'Navidad', parent: 6 },
    { id: 157, name: 'Paredones', parent: 6 },
    { id: 158, name: 'San Fernando', parent: 6 },
    { id: 159, name: 'Chépica', parent: 6 },
    { id: 160, name: 'Chimbarongo', parent: 6 },
    { id: 161, name: 'Lolol', parent: 6 },
    { id: 162, name: 'Nancagua', parent: 6 },
    { id: 163, name: 'Palmilla', parent: 6 },
    { id: 164, name: 'Peralillo', parent: 6 },
    { id: 165, name: 'Placilla', parent: 6 },
    { id: 166, name: 'Pumanque', parent: 6 },
    { id: 167, name: 'Santa Cruz', parent: 6 }
  ,
  
    { id: 168, name: 'Talca', parent: 7 },
    { id: 169, name: 'Constitución', parent: 7 },
    { id: 170, name: 'Curepto', parent: 7 },
    { id: 171, name: 'Empedrado', parent: 7 },
    { id: 172, name: 'Maule', parent: 7 },
    { id: 173, name: 'Pelarco', parent: 7 },
    { id: 174, name: 'Pencahue', parent: 7 },
    { id: 175, name: 'Río Claro', parent: 7 },
    { id: 176, name: 'San Clemente', parent: 7 },
    { id: 177, name: 'San Rafael', parent: 7 },
    { id: 178, name: 'Cauquenes', parent: 7 },
    { id: 179, name: 'Chanco', parent: 7 },
    { id: 180, name: 'Pelluhue', parent: 7 },
    { id: 181, name: 'Curicó', parent: 7 },
    { id: 182, name: 'Hualañé', parent: 7 },
    { id: 183, name: 'Licantén', parent: 7 },
    { id: 184, name: 'Molina', parent: 7 },
    { id: 185, name: 'Rauco', parent: 7 },
    { id: 186, name: 'Romeral', parent: 7 },
    {
      id: 187,
      name: 'Sagrada Familia',
      parent: 7
    },
    { id: 188, name: 'Teno', parent: 7 },
    { id: 189, name: 'Vichuquén', parent: 7 },
    { id: 190, name: 'Linares', parent: 7 },
    { id: 191, name: 'Colbún', parent: 7 },
    { id: 192, name: 'Longaví', parent: 7 },
    { id: 193, name: 'Parral', parent: 7 },
    { id: 194, name: 'Retiro', parent: 7 },
    { id: 195, name: 'San Javier', parent: 7 },
    { id: 196, name: 'Villa Alegre', parent: 7 },
    {
      id: 197,
      name: 'Yerbas Buenas',
      parent: 7
    }
  ,
  
    { id: 198, name: 'Cobquecura', parent: 16 },
    { id: 199, name: 'Coelemu', parent: 16 },
    { id: 200, name: 'Ninhue', parent: 16 },
    { id: 201, name: 'Portezuelo', parent: 16 },
    { id: 202, name: 'Quirihue', parent: 16 },
    { id: 203, name: 'Ránquil', parent: 16 },
    { id: 204, name: 'Treguaco', parent: 16 },
    { id: 205, name: 'Bulnes', parent: 16 },
    {
      id: 206,
      name: 'Chillán Viejo',
      parent: 16
    },
    { id: 207, name: 'Chillán', parent: 16 },
    { id: 208, name: 'El Carmen', parent: 16 },
    { id: 209, name: 'Pemuco', parent: 16 },
    { id: 210, name: 'Pinto', parent: 16 },
    { id: 211, name: 'Quillón', parent: 16 },
    { id: 212, name: 'San Ignacio', parent: 16 },
    { id: 213, name: 'Yungay', parent: 16 },
    { id: 214, name: 'Coihueco', parent: 16 },
    { id: 215, name: 'Ñiquén', parent: 16 },
    { id: 216, name: 'San Carlos', parent: 16 },
    { id: 217, name: 'San Fabián', parent: 16 },
    { id: 218, name: 'San Nicolás', parent: 16 }
  ,
  
    { id: 219, name: 'Concepción', parent: 8 },
    { id: 220, name: 'Coronel', parent: 8 },
    { id: 221, name: 'Chiguayante', parent: 8 },
    { id: 222, name: 'Florida', parent: 8 },
    { id: 223, name: 'Hualqui', parent: 8 },
    { id: 224, name: 'Lota', parent: 8 },
    { id: 225, name: 'Penco', parent: 8 },
    {
      id: 226,
      name: 'San Pedro de la Paz',
      parent: 8
    },
    { id: 227, name: 'Santa Juana', parent: 8 },
    { id: 228, name: 'Talcahuano', parent: 8 },
    { id: 229, name: 'Tomé', parent: 8 },
    { id: 230, name: 'Hualpén', parent: 8 },
    { id: 231, name: 'Lebu', parent: 8 },
    { id: 232, name: 'Arauco', parent: 8 },
    { id: 233, name: 'Cañete', parent: 8 },
    { id: 234, name: 'Contulmo', parent: 8 },
    { id: 235, name: 'Curanilahue', parent: 8 },
    { id: 236, name: 'Los Álamos', parent: 8 },
    { id: 237, name: 'Tirúa', parent: 8 },
    { id: 238, name: 'Los Ángeles', parent: 8 },
    { id: 239, name: 'Antuco', parent: 8 },
    { id: 240, name: 'Cabrero', parent: 8 },
    { id: 241, name: 'Laja', parent: 8 },
    { id: 242, name: 'Mulchén', parent: 8 },
    { id: 243, name: 'Nacimiento', parent: 8 },
    { id: 244, name: 'Negrete', parent: 8 },
    { id: 245, name: 'Quilaco', parent: 8 },
    { id: 246, name: 'Quilleco', parent: 8 },
    { id: 247, name: 'San Rosendo', parent: 8 },
    {
      id: 248,
      name: 'Santa Bárbara',
      parent: 8
    },
    { id: 249, name: 'Tucapel', parent: 8 },
    { id: 250, name: 'Yumbel', parent: 8 },
    { id: 251, name: 'Alto Biobío', parent: 8 }
  ,
  
    { id: 252, name: 'Temuco', parent: 9 },
    { id: 253, name: 'Carahue', parent: 9 },
    { id: 254, name: 'Cunco', parent: 9 },
    { id: 255, name: 'Curarrehue', parent: 9 },
    { id: 256, name: 'Freire', parent: 9 },
    { id: 257, name: 'Galvarino', parent: 9 },
    { id: 258, name: 'Gorbea', parent: 9 },
    { id: 259, name: 'Lautaro', parent: 9 },
    { id: 260, name: 'Loncoche', parent: 9 },
    { id: 261, name: 'Melipeuco', parent: 9 },
    {
      id: 262,
      name: 'Nueva Imperial',
      parent: 9
    },
    {
      id: 263,
      name: 'Padre las Casas',
      parent: 9
    },
    { id: 264, name: 'Perquenco', parent: 9 },
    { id: 265, name: 'Pitrufquén', parent: 9 },
    { id: 266, name: 'Pucón', parent: 9 },
    { id: 267, name: 'Saavedra', parent: 9 },
    {
      id: 268,
      name: 'Teodoro Schmidt',
      parent: 9
    },
    { id: 269, name: 'Nueva Tolten', parent: 9 },
    { id: 270, name: 'Vilcún', parent: 9 },
    { id: 271, name: 'Villarrica', parent: 9 },
    { id: 272, name: 'Cholchol', parent: 9 },
    { id: 273, name: 'Angol', parent: 9 },
    { id: 274, name: 'Collipulli', parent: 9 },
    { id: 275, name: 'Curacautín', parent: 9 },
    { id: 276, name: 'Ercilla', parent: 9 },
    { id: 277, name: 'Lonquimay', parent: 9 },
    { id: 278, name: 'Los Sauces', parent: 9 },
    { id: 279, name: 'Lumaco', parent: 9 },
    { id: 280, name: 'Purén', parent: 9 },
    { id: 281, name: 'Renaico', parent: 9 },
    { id: 282, name: 'Traiguén', parent: 9 },
    { id: 283, name: 'Victoria', parent: 9 }
  ,
  
    { id: 284, name: 'Valdivia', parent: 14 },
    { id: 285, name: 'Corral', parent: 14 },
    { id: 286, name: 'Lanco', parent: 14 },
    { id: 287, name: 'Los Lagos', parent: 14 },
    { id: 288, name: 'Máfil', parent: 14 },
    { id: 289, name: 'Mariquina', parent: 14 },
    { id: 290, name: 'Paillaco', parent: 14 },
    { id: 291, name: 'Panguipulli', parent: 14 },
    { id: 292, name: 'La Unión', parent: 14 },
    { id: 293, name: 'Futrono', parent: 14 },
    { id: 294, name: 'Lago Ranco', parent: 14 },
    { id: 295, name: 'Río Bueno', parent: 14 }
  ,
  
    {
      id: 296,
      name: 'Puerto Montt',
      parent: 10
    },
    { id: 297, name: 'Calbuco', parent: 10 },
    { id: 298, name: 'Cochamó', parent: 10 },
    { id: 299, name: 'Fresia', parent: 10 },
    { id: 300, name: 'Frutillar', parent: 10 },
    { id: 301, name: 'Los Muermos', parent: 10 },
    { id: 302, name: 'Llanquihue', parent: 10 },
    { id: 303, name: 'Maullín', parent: 10 },
    {
      id: 304,
      name: 'Puerto Varas',
      parent: 10
    },
    { id: 305, name: 'Castro', parent: 10 },
    { id: 306, name: 'Ancud', parent: 10 },
    { id: 307, name: 'Chonchi', parent: 10 },
    {
      id: 308,
      name: 'Curaco de Vélez',
      parent: 10
    },
    { id: 309, name: 'Dalcahue', parent: 10 },
    { id: 310, name: 'Puqueldón', parent: 10 },
    { id: 311, name: 'Queilén', parent: 10 },
    { id: 312, name: 'Quellón', parent: 10 },
    { id: 313, name: 'Quemchi', parent: 10 },
    { id: 314, name: 'Quinchao', parent: 10 },
    { id: 315, name: 'Osorno', parent: 10 },
    {
      id: 316,
      name: 'Puerto Octay',
      parent: 10
    },
    { id: 317, name: 'Purranque', parent: 10 },
    { id: 318, name: 'Puyehue', parent: 10 },
    { id: 319, name: 'Río Negro', parent: 10 },
    {
      id: 320,
      name: 'San Juan de la Costa',
      parent: 10
    },
    { id: 321, name: 'San Pablo', parent: 10 },
    { id: 322, name: 'Chaitén', parent: 10 },
    { id: 323, name: 'Futaleufú', parent: 10 },
    { id: 324, name: 'Hualaihué', parent: 10 },
    { id: 325, name: 'Palena', parent: 10 }
  ,
  
    { id: 326, name: 'Coyhaique', parent: 11 },
    { id: 327, name: 'Lago Verde', parent: 11 },
    { id: 328, name: 'Aysen', parent: 11 },
    { id: 329, name: 'Cisnes', parent: 11 },
    { id: 330, name: 'Guaitecas', parent: 11 },
    { id: 331, name: 'Cochrane', parent: 11 },
    { id: 332, name: 'O’Higgins', parent: 11 },
    { id: 333, name: 'Tortel', parent: 11 },
    { id: 334, name: 'Chile Chico', parent: 11 },
    { id: 335, name: 'Río Ibáñez', parent: 11 }
  ,
    {
      id: 336,
      name: 'Punta Arenas',
      parent: 12
    },
    {
      id: 337,
      name: 'Laguna Blanca',
      parent: 12
    },
    { id: 338, name: 'Río Verde', parent: 12 },
    {
      id: 339,
      name: 'San Gregorio',
      parent: 12
    },
    {
      id: 340,
      name: 'Cabo de Hornos (Ex Navarino)',
      parent: 12
    },
    { id: 341, name: 'Antártica', parent: 12 },
    { id: 342, name: 'Porvenir', parent: 12 },
    { id: 343, name: 'Primavera', parent: 12 },
    { id: 344, name: 'Timaukel', parent: 12 },
    { id: 345, name: 'Natales', parent: 12 },
    {
      id: 346,
      name: 'Torres del Paine',
      parent: 12
    }
  
]