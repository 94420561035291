/** @format */

import React from "react";
import SidebarNavCollapseItem from "./SidebarNavCollapse";
import { connect } from "react-redux";

const SidebarNavItems = (props) => {
  const { navbarStatus, listNavbar } = props;

  return (
    <div className="nav-wrapper">
      <ul className="nav--no-borders d-flex flex-column nav nav-pills px-2 justify-content-center">
        {listNavbar.map((item, idx) => {
          return (
            <SidebarNavCollapseItem
              key={idx}
              item={item}
              hideLogoText={navbarStatus}
            />
          );
        })}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({
  listNavbar: state.app.listNavbar,
  navbarStatus: state.app.navbarStatus,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItems);
