import {
  SET_LOADING,
  POST_LOGIN_USUARIO,
  RESET_INITIAL_VALUES,
  SET_COUNTRY,
  SET_NAVBAR_ITEM,
  SET_NAVBAR_STATUS,
  SET_MENU_MOBILE,
} from "../action";

const initialStateValues = {
  usuario: {},
  loading: false,
  country: {},
  listNavbar: [],
  navbarStatus: true,
  menuMobile: false,
};

const initialState = { ...initialStateValues };

const actionsMap = {
  [SET_LOADING]: (state, action) => {
    const { loading } = action;
    return {
      ...state,
      loading,
    };
  },
  [POST_LOGIN_USUARIO]: (state, action) => {
    const { usuario } = action;
    state = state || initialState;
    return {
      ...state,
      usuario,
    };
  },
  [SET_COUNTRY]: (state, action) => {
    const { country } = action;
    state = state || initialState;
    return {
      ...state,
      country,
    };
  },
  [SET_COUNTRY]: (state, action) => {
    const { country } = action;
    state = state || initialState;
    return {
      ...state,
      country,
    };
  },
  [SET_NAVBAR_ITEM]: (state, action) => {
    const { listNavbar } = action;
    state = state || initialState;
    return {
      ...state,
      listNavbar,
    };
  },
  [SET_NAVBAR_STATUS]: (state, action) => {
    const { navbarStatus } = action;
    state = state || initialState;
    return {
      ...state,
      navbarStatus,
    };
  },
  [SET_MENU_MOBILE]: (state, action) => {
    const { payload } = action;
    state = state || initialState;
    return {
      ...state,
      menuMobile:payload,
    };
  },
  [RESET_INITIAL_VALUES]: (state, action) => {
    state = state || initialState;
    const {country} = state
    state = undefined;
    return {
      ...state,
      country,
      action,
    };
  },
};

export default (state = initialState, action = {}) => {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
};
