/** @format */

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col } from 'antd'

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarNavItems from "./SidebarNavItems";

const MainSidebar = () => {
  return (
    <>
      <Col tag="aside">
        <SidebarMainNavbar />
        <SidebarNavItems />
      </Col>
    </>
  );
};

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
  collapse: PropTypes.bool,
  onChangeCollapse: PropTypes.func,
};

MainSidebar.defaultProps = {
  hideLogoText: false,
  collapse: false,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainSidebar);
