import { useMemo } from 'react'
import { connect } from 'react-redux'
// import Sider from "antd/lib/layout/Sider"
// import MainFooter from "../components/layout/MainFooter"


const PrivateLayouts = ({ children }) => {
  return (
    <div className="h-100" >
      <div className="pb-5" >
        {children}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateLayouts)
