/** @format */

import {Fragment} from "react";
import PropTypes from "prop-types";

const DefaultLayout = ({ children }) => {
  return (
    <Fragment>
      {/* <Header>{!noNavbar && <MainNavbar props={children.props} />}</Header> */}
      {/* <Content>{children}</Content> */}
      {children}
    </Fragment>
  );
};

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export { DefaultLayout };
