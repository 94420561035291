import React from "react";
import { connect } from "react-redux";

const NavbarSearch = ({country}) => {
  return (
    <div className="main-navbar__search d-flex flex-grow-1">
      <div>
        <div className="iconMenu">
          <div className="d-flex m-auto">
            <img
              id="main-logo"
              className="d-inline-block align-top mr-1"
              style={{ maxWidth: "32px",}}
              src={require("../../../images/icon.png")}
              alt="Shards Dashboard"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  country: state.app.country,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarSearch)
