/** @format */

import axios from "axios";
import {
  urlApiUtils,
  cargaArchivos,
  apiUtilsStatusUrl,
  apiCargaArchivosCorreosStatusUrl,
  apiBackendStatusUrl,
  courierStatusUrl,
  //bluexStatusUrl,
  apiPedidosStatusUrl,
  apiQuiebres,
  chileExpressStatusUrl,
  starkenStatusUrl,
} from "../utils/url";
import { authHeader } from "../utils/authHeader";
import Swal from "sweetalert2";
import moment from "moment";
import {store} from '../config/store';
import {setLogout} from '../action/index'
import fetchBackend from "./fetchBackend";

axios.defaults.headers.common["Authorization"] = authHeader();

axios.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    console.log(error);
    // Your Interceptor code to do something with response error
    // Return error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (error && error.response && error.response.status === 403) {
      if (!Swal.isVisible()) {

        const {dispatch} = store

        Swal.fire({
          title: "Sesión Caducada",
          text: "Tu sesión ha caducado, favor inicia sesión nuevamente 😇",
          type: "warning",
        }).then( async () => {
          localStorage.removeItem("user");
          localStorage.removeItem("rol");
          localStorage.removeItem("usuarioEmail");
          await dispatch(setLogout())
          window.location.href = "/Login";
        });
      }
    }
  }
);

/* ===============API USUARIO ===================================*/

const apiLoginWeb = async (login) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `usuario/loginUsuario`,
      data: login,
    });

    if (res.data.estado === 1) {
      localStorage.setItem("user", res.data.data.token);
      localStorage.setItem("rol", res.data.data.usuario.roles);
      localStorage.setItem("usuarioEmail", res.data.data.usuario.usuarioEmail);
    }

    return res.data;
  } catch (error) {
    console.error("error: ", error);
  }
};

const apiRecuperarContrasena = async (login) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `usuario/recuperarContrasena`,
      data: login,
    });
    console.log("apiRecuperarContrasena -> res", res);

    return res.data;
  } catch (error) {
    console.error("error: ", error);
  }
};

const apiGetUsuarios = async () => {
  try {
    const res = await fetchBackend({
      method: "GET",
      url: `usuario`,
    });

    return res.data;
  } catch (error) {
    console.error("error: ", error);
  }
};

const apiGetUsuario = async (id) =>
  fetchBackend
    .get(`usuario/obtener/${id}`)
    .then((value) => value.data)
    .catch((reason) => reason.response);

const apiUpdateUsuario = async (user) =>
  fetchBackend
    .put(`usuario/editar`, user)
    .then((value) => value.data)
    .catch((reason) => reason.response);

const apiGetUsuariosByRol = async (rol) => {
  try {
    const res = await fetchBackend({
      method: "GET",
      url: `usuario/obtenerUsuarioPorRol?rol=${rol}`,
    });

    return res.data;
  } catch (error) {
    console.error("error: ", error);
  }
};

const apiAddUSer = async (user) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `usuario`,
      data: user,
    });
    return res.data;
  } catch (error) {
    console.error("error: ", error);
  }
};

/* ===============FIN API USUARIO ===============================*/

/* ===============API TIENDAS ===================================*/
const apiGetTiendas = async () => {
  try {
    const res = await fetchBackend({
      method: "GET",
      url: `tienda`,
    });

    return res.data;
  } catch (error) {
    console.error("error: ", error);
  }
};

const apiGetCadenas = async () => {
  try {
    const res = await fetchBackend({
      method: "GET",
      url: `tienda/cadenas`,
    });

    return res.data;
  } catch (error) {
    console.error("error: ", error);
  }
};

const apiGetTiendasActivas = async (sg) => {
  try {
    const res = await fetchBackend({
      method: "GET",
      url: `tienda/obtenerTiendasActivas/${sg}`,
    });

    return res.data;
  } catch (error) {
    console.error("error: ", error);
  }
};

const apiGetTiendasActivasAndCnC = async (sg) => {
  try {
    const res = await fetchBackend({
      method: "GET",
      url: `tienda/obtenerTiendasActivasAndCnC/${sg}`,
    });

    return res.data;
  } catch (error) {
    console.error("error: ", error);
  }
};

const apiGetTiendasCambiarActiva = async (localId) => {
  try {
    const res = await fetchBackend({
      method: "GET",
      url: `tienda/cambiarActiva?localId=${localId}`,
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiGetTiendasInformacion = async (localId) => {
  try {
    const res = await fetchBackend({
      method: "GET",
      url: `tienda/${localId}`,
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiGetTiendasClickCollect = async (localId) => {
  try {
    const res = await fetchBackend({
      method: "GET",
      url: `vtex/update?localId=${localId}`,
    });
    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiGetOperadoresLogisticos = async () => {
  try {
    const res = await fetchBackend({
      method: "GET",
      url: `tienda/logistico/find`,
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiSaveOperadoresLogisticos = async (data) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `tienda/logistico/save`,
      data,
    });
    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiUpdateOperadoresLogisticos = async (data) => {
  try {
    const res = await fetchBackend({
      method: "PUT",
      url: `tienda/logistico/update`,
      data,
    });
    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiGetUpdateDespachoVtex = async (localId) => {
  try {
    const res = await fetchBackend({
      method: "GET",
      url: `vtex/updateDespacho?localId=${localId}`,
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiPostUpdateCobroDespachoCI = async (localId, cobroDespacho, userId) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `tienda/updateTiendaCobroDespachoCI?localId=${localId}&cobroDespacho=${cobroDespacho}&userId=${userId}`,
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apigetNombresYcodigosDeTiendas = async () =>
  await fetchBackend
    .get(`tienda/tiendasSelect`)
    .then((value) => value.data)
    .catch((reason) => reason.response);

const apiUpdateTienda = async (objTienda) => {
  return await fetchBackend
    .post(`tienda/updateTienda`, objTienda)
    .then((res) => res.data)
    .catch((reason) => console.error("resason", reason));
};
/* ===============FIN API TIENDAS ===============================*/

/* ===============API CENTROS DE DISTRIBUCIÓN ===============================*/
const apiGetCentrosDistribucion = async () => {
  return await fetchBackend
    .get(`tienda/centroDistribucion`)
    .then((res) => res.data)
    .catch((reason) => console.error("resason", reason));
};

const apiGetPrioridades = async () => {
  return await fetchBackend
    .get(`tienda/prioridad`)
    .then((res) => res.data)
    .catch((reason) => console.error("resason", reason));
};

const apiUpdatePrioridades = async (tienda) => {
  return await fetchBackend
    .post(`tienda/updatePrioridad`, tienda)
    .then((res) => res.data)
    .catch((reason) => console.error("resason", reason));
};

const apiObtenerListaNegraList = async (page, sg, sku, localId,startDate,endDate) => {
  try {
    if (typeof sg === "undefined") sg = "";
    if (typeof sku === "undefined") sku = "";
    if (typeof localId === "undefined") localId = "";
    const res = await fetchBackend({
      method: "GET",
      url: `producto/listaNegra?page=${page}&shGroup=${sg}&sku=${sku}&localId=${localId}&startDate=${startDate}&endDate=${endDate}`,
    });
    return res.data;
  } catch (error) {
    console.error("TCL: error", error);
  }
};

const apiObtenerListaNegra = async (id) => {
  return await fetchBackend
    .get(`producto/listaNegra/${id}`)
    .then((res) => res.data)
    .catch((reason) => console.error("resason", reason));
};

const apiObtenerListaNegraImagenes = async (listaNegra) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `producto/obtenerImagenes`,
      data: listaNegra,
    });
    return res.data;
  } catch (error) {
    console.error("Error => apiObtenerListaNegraImagenes => : ", error);
  }
};

/* ===============FIN API CENTROS DE DISTRIBUCIÓN ===============================*/

/* ===============API ORDENES ===================================*/
const apiGetSites = async () => {
  try {
    const res = await fetchBackend({
      method: "GET",
      url: `orden/sites`,
    });

    return res.data;
  } catch (error) {
    console.error("error: ", error);
  }
};

const apiGetOrdenes = async (paginate, params) => {
  try {
    let strQuery = `orden/ordenesDisponiblesPorFiltro?pagina=${paginate}`;

    Object.keys(params).forEach(
      (value) => (strQuery += params[value] ? `&${value}=${params[value]}` : "")
    );
    
    const res = await fetchBackend({
      method: "GET",
      url: strQuery,
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiGetExcel = async (params) => {
  let strQuery = `${urlApiUtils}orden/excel?`;
  var config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "x-api-key": "7f20e24a-2b73-4741-8209-dcadfb114cb5",
    },
  };
  Object.keys(params).forEach(
    (value) => (strQuery += params[value] ? `&${value}=${params[value]}` : "")
  );

  const res = await axios.get(strQuery, config);
  if (res && res.data) {
    return res.data;
  }

  return null;
};

const apiGetOrdenesByEstados = async () => {
  try {
    let strQuery = `orden/cuentaPorEstadoAlerta`;

    const res = await fetchBackend({
      method: "GET",
      url: strQuery,
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiPostAddComentarioOrden = async (obj) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `orden/agregarComentario`,
      data: obj,
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiPutUpdateTiendaOrden = async (obj) => {
  try {
    const res = await fetchBackend.put(`orden/actualizarTienda`, obj);

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiRepocesarSG = async (id) => {
  try {
    const res = await fetchBackend.get(`orden/reprocesar?id=${id}`);

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiUpdateSGandSendEmail = async (sg) => {
  try {
    const res = await fetchBackend.post(`orden/updateAndSendEmail`, sg);

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiCancelarSG = async (id) => {
  try {
    const res = await fetchBackend.get(`orden/cancelar?id=${id}`);

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiGetPorOrdenesPorEstado = async (tipo, sitio = "", searchDateGraph) => {
  try {
    const { fechaFin, fechaInit } = searchDateGraph;

    const from = fechaInit
      ? moment(fechaInit, "DD/MM/YYYY").format("YYYY-MM-DD")
      : "";
    const to = fechaFin
      ? moment(fechaFin, "DD/MM/YYYY").format("YYYY-MM-DD")
      : "";

    const res = await fetchBackend.get(
      `orden/estados?Tipo=${tipo}&sitio=${sitio}&from=${from}&to=${to}`
    );

    return res.data;
  } catch (e) {
    console.error(e);
  }
};

const apiGetHistorial = async (shGroup) => {
  try {
    const res = await fetchBackend.get(
      `orden/historial?shGroup=${shGroup}`
    );

    return res.data;
  } catch (e) {
    console.error("TCL: Error: apiGetHistorial: =>", e);
  }
};

const apiGetInfoCorreo = async (request) => {
  return await fetchBackend
    .post(`correosChile/consultaCorreo`, request)
    .then((res) => res.data)
    .catch((reason) => reason.response);
};

const apiGetDetalleVentaCx = async (shGroup) => {
  try {
    const res = await fetchBackend.get(
      `orden/detalleVentaCx?shGroup=${shGroup}`
    );
    return res.data;
  } catch (e) {
    console.error("TCL: Error: apiGetDetalleVentaCx: =>", e);
  }
};

const apiPostFixDirectionClient = async (obj) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `orden/fixCorreosShippingGroup`,
      data: obj,
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiFinalizaSgs = async (shGroup) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `orden/finalizarOrdenes`,
      data: shGroup,
    });

    return res.data;
  } catch (e) {
    console.error("TCL: Error: apiFinalizaSgs: =>", e);
  }
};

const apiReprocesaMasivamenteSgs = async (obj) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `orden/reprocesarMasivamenteOrdenes`,
      data: obj,
    });

    return res;
  } catch (e) {
    console.error("TCL: Error: apiReprocesaMasivamenteSgs: =>", e);
  }
};

const apiCountTotalizadores = async (obj) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `orden/totalizadoresSgs`,
      data: obj,
    });

    return res.data;
  } catch (e) {
    console.error("TCL: Error: apiCountTotalizadores: =>", e);
  }
};

/* ===============FIN API ORDENES ===============================*/

/* ===============API PRODUCTOS =================================*/
const apiPostGetFiltrosStockSeguridad = async (obj) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `producto/busquedaFiltros`,
      data: obj,
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};
/* ===============FIN API PRODUCTOS =============================*/

/* ===============API ETIQUETA ARCHIVOS =================================*/
const apiDescargaArchivoSG = async (obj) => {
  //TODO: API Key <3
  try {
    const res = await axios.get(
      `${cargaArchivos}Mail/downloadArchivo?SGs=${obj.sg}&Tipo=${obj.tipo}`
    );

    return res.data;
  } catch (e) {
    console.error(e);
  }
};
/* ===============FIN API ETIQUETA ARCHIVOS =============================*/

/* ===============API LOGBI =================================*/

const apiLogBi = async (ano, cadena, mes, pagina) => {
  try {
    let num = pagina - 1;
    let numPagina = isNaN(num) ? 0 : num;

    const res = await fetchBackend.get(
      `logbi?year=${ano}&cadena=${cadena}&month=${mes}&pagina=${numPagina}`
    );

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

/* ===============FIN API LOGBI =============================*/

/* ===============API BBR  =================================*/

const apiBbr = async (id) => {
  try {
    const res = await fetchBackend.post(
      `bbrmadness/reprocesaTrx?id=${id}`
    );

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiBbrConsulta = async (id) => {
  try {
    const res = await fetchBackend.get(`bbrmadness/obtenerTrx?id=${id}`);

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiBbrInsertaTrama = async (obj) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `bbrmadness/insertaTramaMod`,
      data: obj,
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiConsultaTramaForus = async (id) => {
  try {
    console.log("id", id);
    const res = await fetchBackend.get(`orden/tramaForus?shGroup=${id}`);
    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};
/* ===============FIN API BBR  =============================*/

/* ===============API COMUNAS  =============================*/
const apiVtexConsultaComunas = async (id) => {
  try {
    const res = await fetchBackend.get(`vtex/listarComunasVtex`);

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiAddOrUpdateComuna = async (obj) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `vtex/guardarComuna`,
      data: { comunasVtexWoodstock: obj },
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiDeleteComunaForusApp = async (request) => {
  return await fetchBackend
    .delete(`vtex/eliminarComuna`, request)
    .then((res) => res.data)
    .catch((reason) => reason.response);
};

/* ===============API CLIENTES =================================*/

const apiGetClientes = async (paginate, params) => {
  try {
    let strQuery = `clientes/getClientes?pagina=${paginate}`;

    Object.keys(params).forEach(
      (value) => (strQuery += params[value] ? `&${value}=${params[value]}` : "")
    );

    const res = await fetchBackend({
      method: "GET",
      url: strQuery,
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiGetClienteById = async (id, pagina) => {
  try {
    const res = await fetchBackend.get(
      `clientes/getClienteById?id=${id}&pagina=${pagina}`
    );
    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

/* ===============API PROMOCIONES  =============================*/
const apiObtenerPromociones = async (id) => {
  try {
    const res = await fetchBackend.get(`promocion/obtenerPromociones`);

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiAddOrUpdatePromocion = async (obj) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `promocion/guardarPromocion`,
      data: { promocion: obj },
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiUpdatePromocion = async (obj) => {
  try {
    const res = await fetchBackend({
      method: "PUT",
      url: `promocion/actualizarPromocion`,
      data: { promocion: obj },
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiDeletePromoMantenedor = async (request) => {
  try {
    const res = await fetchBackend({
      method: "DELETE",
      url: `promocion/eliminarPromocion`,
      data: { promocion: request },
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

/**INI [19-10-2020] obtener promociones no configuradas**/

const apiObtenerTotalPromocionesNoConfig = async (opt) => {
  try {
    const res = await fetchBackend.get(
      `promocion/obtenerPromocionesNoConfig?opt=${opt}`
    );

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiUpdateAlarmaPromocion = async (obj, onOff, user) => {
  try {
    const res = await fetchBackend({
      method: "POST",
      url: `promocion/guardarHistoricoPromoNoConfig`,
      data: {
        idObjectPromoNoConfig: obj.idPromocion,
        usuario: user,
        apagarAlarma: onOff,
      },
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

const apiObtenerTotalPromocionesNoConfigBadget = async () => {
  try {
    const res = await fetchBackend.get(
      `promocion/obtenerPromocionesNoConfig?opt=2`
    );

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

/**FIN [19-10-2020] obtener promociones no configuradas**/
/* ===============FIN API PROMOCIONES  =============================*/

/* =============== API INTENTOS CORREOS PARA PROMOCIONES NO CONFIGURADAS=======*/
const apiGetIntentos = async () => {
  return await fetchBackend
    .get(`promocion/intentos-correos-promo-no-config/list`)
    .then((res) => res.data)
    .catch((reason) => console.error("resason", reason));
};

const apiUpdateIntentos = async (intentos) => {
  return await fetchBackend
    .put(
      `promocion/intentos-correos-promo-no-config/edit`,
      intentos
    )
    .then((res) => res.data)
    .catch((reason) => console.error("resason", reason));
};

/* =============== FIN INTENTOS CORREOS PARA PROMOCIONES NO CONFIGURADAS ===================================*/

/* ===============   API SKU VTEX    =============================*/

const apiActivaSkuVtex = async (request) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${apiQuiebres}sku/reactivar`,
      data: request,
    });

    return res.data;
  } catch (error) {
    console.error("Error", error);
  }
};

const apiObtenerSkuDesactivados = async () => {
  try {
    const res = await axios.get(`${apiQuiebres}sku`);

    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
};

/* =============== FIN API SKU VTEX  =============================*/

/* ===============API STATUS SISTEMA  =============================*/
const apiUtilsStatus = async () => {
  try {
    const res = await axios.get(apiUtilsStatusUrl);
    return res.data;
  } catch (error) {
    console.error("Error", error);
    return false;
  }
};

const apiBackendStatus = async () => {
  try {
    const res = await axios.get(apiBackendStatusUrl);
    return res.data;
  } catch (error) {
    console.error("Error", error);
    return false;
  }
};

const apiCargaArchivosCorreoStatus = async () => {
  try {
    const res = await axios.get(apiCargaArchivosCorreosStatusUrl);
    return res.data;
  } catch (error) {
    console.error("Error", error);
    return false;
  }
};

const apiPedidosStatus = async () => {
  try {
    const res = await axios.get(apiPedidosStatusUrl);
    return res.data;
  } catch (error) {
    console.error("Error", error);
    return false;
  }
};

const apiCourierStatus = async () => {
  try {
    const res = await axios.get(courierStatusUrl);
    return res.data;
  } catch (error) {
    console.error("Error", error);
    return false;
  }
};

const apiStatusChileExpress = async () => {
  try {
    const res = await axios.get(chileExpressStatusUrl);
    return res.data;
  } catch (error) {
    console.error("Error", error);
    return false;
  }
};

const apiStatusStarken = async () => {
  try {
    const res = await axios.get(starkenStatusUrl);
    return res.data;
  } catch (error) {
    console.error("Error", error);
    return false;
  }
};

/* ===============API FIN STATUS SISTEMA  =============================*/
/* ===============API ZONAS ===================================*/

const apiGetZonas = async () => {
  return await fetchBackend
    .get(`zona`)
    .then((res) => res.data)
    .catch((reason) => console.error("resason", reason));
};
const apiGetZonaId = async (id) =>
  fetchBackend
    .get(`zona/${id}`)
    .then((value) => value.data)
    .catch((reason) => reason.response);

const apiPutZona = async (zona) => {
  return await fetchBackend
    .put(`zona/`, zona)
    .then((res) => res.data)
    .catch((reason) => console.error("resason", reason));
};
const apiUpdatePrioridadZona = async (zona) => {
  return await fetchBackend
    .put(`zona/editar`, zona)
    .then((res) => res.data)
    .catch((reason) => console.error("resason", reason));
};
const apiUpdateZonaTienda = async (id, localId) =>
  fetchBackend
    .put(`zona/actzona/?id=${id}&localId=${localId}`)
    .then((value) => value.data)
    .catch((reason) => reason.response);
const apiDeleteZona = async (id) =>
  fetchBackend
    .delete(`zona/eliminar/${id}`)
    .then((value) => value.data)
    .catch((reason) => reason.response);
/* =============== FIN API ZONAS ===================================*/

/* ===============API FECHA COMPROMETIDA ===================================*/
const apiGetFechaComprometida = async () => {
  return await fetchBackend
    .get(`fecha-comprometida/list`)
    .then((res) => res.data)
    .catch((reason) => console.error("resason", reason));
};

const apiPostFechaComprometida = async (fechaComprometida) => {
  return await fetchBackend
    .post(`fecha-comprometida/add`, fechaComprometida)
    .then((res) => res.data)
    .catch((reason) => console.error("resason", reason));
};

const apiGetFechaComprometidaId = async (id) =>
  fetchBackend
    .get(`fecha-comprometida/get/${id}`)
    .then((value) => value.data)
    .catch((reason) => reason.response);

const apiUpdateFechaComprometida = async (fechaComprometida) => {
  return await fetchBackend
    .put(`fecha-comprometida/edit`, fechaComprometida)
    .then((res) => res.data)
    .catch((reason) => console.error("resason", reason));
};

const apiDeleteFechaComprometida = async (id) =>
  fetchBackend
    .delete(`fecha-comprometida/delete/${id}`)
    .then((value) => value.data)
    .catch((reason) => reason.response);

/* =============== FIN FECHA COMPROMETIDA ===================================*/

/* =============== MARKETPLACE ==============================================*/

const apiGetListOrderMarketPlace = async (payload, page = 0) => {
  try {
    const { estado } = payload;

    let strQuery = `marketplace/getListOrden?pagina=${page}`;

    strQuery += `&estadoCancelada=${
      estado && estado.indexOf("CANCELADA") > -1
        ? "true"
        : !estado
        ? "true"
        : "false"
    }`;
    strQuery += `&estadoConfirmada=${
      estado && estado.indexOf("CONFIRMADA") > -1
        ? "true"
        : !estado
        ? "true"
        : "false"
    }`;
    strQuery += `&estadoParticionada=${
      estado && estado.indexOf("PARTICIONADA") > -1
        ? "true"
        : !estado
        ? "true"
        : "false"
    }`;

    Object.keys(payload).forEach(
      (value) =>
        (strQuery +=
          payload[value] && value !== "estado"
            ? `&${value}=${payload[value]}`
            : "")
    );

    const value = await fetchBackend.get(strQuery);

    if (!value) {
      throw new Error(
        "Error al realizar la petición al servicio apiGetListOrderMarketPlace"
      );
    }

    const response = value.data;

    if (!response || !response.estado) {
      return [];
    }

    return response.data || [];
  } catch (error) {
    console.warn("apiGetListOrderMarketPlace -> ", error.message);
    return false;
  }
};

const apiGetListSGMarketPlace = async (payload = "") => {
  try {
    const value = await fetchBackend.get(
      `marketplace/getSg?ordenId=${payload}`
    );

    if (!value) {
      throw new Error(
        "Error al realizar la petición al servicio apiGetListOrderMarketPlace"
      );
    }

    const response = value.data;

    if (!Array.isArray(response) && !response.estado) {
      return false;
    }

    return response || false;
  } catch (error) {
    console.warn("apiGetListOrderMarketPlace -> ", error.message);
    return false;
  }
};

const apiSaveOrderSGMarketPlace = async (payload = {}) => {
  try {
    const value = await fetchBackend.post(`marketplace/order`, payload);
    if (!value) {
      throw new Error(
        "Error al realizar la petición al servicio apiSaveOrderSGMarketPlace"
      );
    }

    /*const response = value.data

    if(!response.estado) {
      console.log('apiSaveOrderSGMarketPlace -> ',response)
      return false
    }*/

    return true;
  } catch (error) {
    console.warn("apiGetListOrderMarketPlace -> ", error.message);
    return false;
  }
};

const apiGetPorOrdenesPorEstadoMarketPlace = async (
  tipo,
  sitio = "",
  searchDateGraph
) => {
  try {
    const { fechaFin, fechaInit } = searchDateGraph;

    const from = fechaInit
      ? moment(fechaInit, "DD/MM/YYYY").format("YYYY-MM-DD")
      : "";
    const to = fechaFin
      ? moment(fechaFin, "DD/MM/YYYY").format("YYYY-MM-DD")
      : "";

    const resConfirm = fetchBackend.get(
      `orden/estadoConfirmadaPorMarketplace?Tipo=${tipo}&sitio=${sitio}&from=${from}&to=${to}`
    );

    const resCancel = fetchBackend.get(
      `orden/estadoCanceladaPorMarketplace?Tipo=${tipo}&sitio=${sitio}&from=${from}&to=${to}`
    );

    const resMarketPlace = await Promise.all([resConfirm, resCancel]);

    const listMarketPlace = resMarketPlace.reduce((prevList, resMkp) => {
      if (resMkp.status === 200 && resMkp.data) {
        return [...prevList, ...resMkp.data.data];
      }

      return prevList;
    }, []);

    return listMarketPlace;
  } catch (e) {
    console.error("listMarketPlace -> ", e);
    return false;
  }
};

/**
 * DESCUENTO CI 
 */
const apiListDiscountCI = async () => {
  try {

    const res = await fetchBackend.get(`ci/promocion`);

    if(!res || !res.data || !res.data.estado) {
      return []
    }

    return res.data.data || []
  
  } catch (error) {
    console.error("listMarketPlace -> ", error);
    return [];
  }
}

const apiAddDiscountCI = async (payload) => {
  try {

    const res = await fetchBackend.post(`ci/promocion`, payload);

    if(!res || !res.data) {
      return false
    }

    if(res.data.estado === 0) {
      return res.data
    }

    return true

  } catch (error) {
    console.error("apiAddDiscountCI -> ", error);
    return false;
  }
}

const apiUpdateDiscountCI = async (payload) => {
  try {

    const res = await fetchBackend.put(`ci/promocion`, payload);

    if(!res || !res.data) {
      return false
    }

    if(res.data.estado === 0) {
      return res.data
    }

    return true

  } catch (error) {
    console.error("apiUpdateDiscountCI -> ", error);
    return false;
  }
}

const apiDeleteDiscountCI = async (payload) => {
  try {

    const res = await fetchBackend.delete(`ci/promocion/${payload}`);

    if(!res || !res.data) {
      return false
    }

    if(res.data.estado === 0) {
      return res.data
    }

    return true

  } catch (error) {
    console.error("apiUpdateDiscountCI -> ", error);
    return false;
  }
}
/**
 * END DESCUENTO CI
 */

const apiGetMacroRule = async () => {
  return await fetchBackend
    .get(`tienda/macroregla`)
    .then((res) => res.data)
    .catch((reason) => console.error("resason", reason));
}

const apiPutMacroRule = async (payload) => {
  return await fetchBackend
  .put(`tienda/macroregla`, payload)
  .then((res) => res.data)
  .catch((reason) => console.error("resason", reason));
}

/**
 * Matriz Nuevo Operadores Logístico 
 */

const apiGetListAllocationMatrix = async () => {
  return await fetchBackend
    .get(`matrizCobertura/obtenerCobertura`)
    .then((res) => {

      if(!res?.status === 200){ 
        return []
      }

      if(!res?.data) {
        return []
      }

      return res.data
    })
    .catch((reason) => console.error("resason", reason));
} 

const apiPutListAllocationMatrix = async (payload) => {
  return await fetchBackend
  .put(`matrizCobertura/editarCobertura`, payload)
  .then((res) => {
    if(res?.status === 200 || res?.status === 201 || res?.status === 202) {
      return true
    } 

    return false
  })
  .catch((reason) => console.error("resason", reason));
}

const apiDeleteListAllocationMatrix = async (payload) => {
  return await fetchBackend
  .delete(`matrizCobertura/eliminarCobertura/${payload}`)
  .then((res) => {
    if(res?.status === 200 || res?.status === 201 || res?.status === 202) {
      return true
    } 

    return false
  })
  .catch((reason) => console.error("resason", reason));
}

export default {
  apiLoginWeb,
  apiAddUSer,
  apiGetUsuarios,
  apiGetUsuariosByRol,
  apiGetTiendas,
  apiGetCadenas,
  apiGetTiendasCambiarActiva,
  apiGetTiendasInformacion,
  apiGetTiendasClickCollect,
  apiGetSites,
  apiGetOrdenes,
  apiPostAddComentarioOrden,
  apiGetTiendasActivas,
  apiPutUpdateTiendaOrden,
  apiGetOrdenesByEstados,
  apiPostGetFiltrosStockSeguridad,
  apiRepocesarSG,
  apiCancelarSG,
  apiUpdateSGandSendEmail,
  apiGetPorOrdenesPorEstado,
  apiGetCentrosDistribucion,
  apiGetPrioridades,
  apiUpdatePrioridades,
  apiGetHistorial,
  apiGetInfoCorreo,
  apiGetTiendasActivasAndCnC,
  apiGetUpdateDespachoVtex,
  apiPostUpdateCobroDespachoCI,
  apiObtenerListaNegraImagenes,
  apiObtenerListaNegraList,
  apiObtenerListaNegra,
  apiGetDetalleVentaCx,
  apiPostFixDirectionClient,
  apiLogBi,
  apiGetExcel,
  apigetNombresYcodigosDeTiendas,
  apiGetUsuario,
  apiUpdateUsuario,
  apiDescargaArchivoSG,
  apiBbr,
  apiBbrConsulta,
  apiAddOrUpdateComuna,
  apiVtexConsultaComunas,
  apiDeleteComunaForusApp,
  apiObtenerPromociones,
  apiAddOrUpdatePromocion,
  apiUpdatePromocion,
  apiDeletePromoMantenedor,
  apiFinalizaSgs,
  apiReprocesaMasivamenteSgs,
  apiCountTotalizadores,
  apiBbrInsertaTrama,
  apiGetClientes,
  apiGetClienteById,
  apiUpdateTienda,
  apiConsultaTramaForus,
  apiRecuperarContrasena,
  apiUtilsStatus,
  apiBackendStatus,
  apiCargaArchivosCorreoStatus,
  apiCourierStatus,
  apiPedidosStatus,
  apiActivaSkuVtex,
  apiObtenerSkuDesactivados,
  apiObtenerTotalPromocionesNoConfig,
  apiUpdateAlarmaPromocion,
  apiObtenerTotalPromocionesNoConfigBadget,
  apiGetZonas,
  apiGetZonaId,
  apiPutZona,
  apiUpdatePrioridadZona,
  apiUpdateZonaTienda,
  apiDeleteZona,
  apiGetFechaComprometida,
  apiPostFechaComprometida,
  apiGetFechaComprometidaId,
  apiUpdateFechaComprometida,
  apiDeleteFechaComprometida,
  apiGetIntentos,
  apiUpdateIntentos,
  apiGetOperadoresLogisticos,
  apiSaveOperadoresLogisticos,
  apiUpdateOperadoresLogisticos,
  apiGetListOrderMarketPlace,
  apiGetListSGMarketPlace,
  apiSaveOrderSGMarketPlace,
  apiGetPorOrdenesPorEstadoMarketPlace,
  //DESCUENTO CI
  apiListDiscountCI,
  apiAddDiscountCI,
  apiUpdateDiscountCI,
  apiDeleteDiscountCI,

  //Macroregla
  apiGetMacroRule,
  apiPutMacroRule,

  //AllocationMatrix
  apiGetListAllocationMatrix,
  apiPutListAllocationMatrix,
  apiDeleteListAllocationMatrix,
  apiStatusChileExpress,
  apiStatusStarken,
};
