
const CHILE_ID = '.cl.'
const PERU_ID = '.pe.'
const COLOMBIA_ID = '.co.'
const URUGUAY_ID = '.ur.'


export {
  CHILE_ID,
  PERU_ID,
  COLOMBIA_ID,
  URUGUAY_ID
}