export function authHeader() {
  // return authorization header with jwt token
  let user = localStorage.getItem("user"); //JSON.parse();

  if (user) {
    return "Bearer " + user;
  } else {
    return null;
  }
}
