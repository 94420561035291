import { Spin } from 'antd'

function LoadingLazy() {
  return (
    <div className='container-fluid d-flex justify-content-center align-items-center' style={{ height: '80vh' }}>
      <Spin size='large' className='text-center' tip='Cargando...' />
    </div>
  )
}

export default LoadingLazy