import {
  routers,
  profiles,
  itemsSidebarNav,
} from "../constants/listRouterProfile";
import { getCountry } from "../utils/country";

const country = getCountry();
const { blockedRouteList, blockedPageList } = country;

const getRoutes = (ProfilesUser) => {
  if (!ProfilesUser) return [];

  const listRoute = ProfilesUser.reduce(
    (arr, profile) =>
      profiles[profile] ? [...arr, ...profiles[profile].routes] : arr,
    []
  );

  if (listRoute.includes("ADMINISTRADOR")) {
    return routers.filter(route => !blockedRouteList.includes(route.path));
  }

  let list = routers.filter(
    (route) =>
      listRoute.includes(route.path) && !blockedRouteList.includes(route.path)
  );

  return list;
};

const getSidebar = (ProfilesUser) => {
  if (!ProfilesUser) return [];

  const listSidebar = ProfilesUser.reduce(
    (arr, profile) =>
      profiles[profile] ? [...arr, ...profiles[profile].sidebar] : arr,
    []
  );

  let list = itemsSidebarNav.filter(item => !blockedPageList.includes(item.id));

  list = list.map((item) => {
    if(!item.child) return item;

    const childs = item.childs.filter(child =>  !blockedPageList.includes(child.id))

    return {
      ...item,
      childs,
    }
  });

  if (listSidebar.includes("ADMINISTRADOR")) {
    return list
  }

  list = list.filter(
    (item) =>
      listSidebar.includes(item.id)
  );

  return list;
};

export { getRoutes, getSidebar };
