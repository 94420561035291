import { useEffect } from 'react'
import { Layout, Space } from "antd"
import MainSidebar from "../components/layout/MainSidebar/MainSidebar"
import MainNavbar from '../components/layout/MainNavbar/MainNavbar'
import MainFooter from '../components/layout/MainFooter';
import { useLocation, useHistory } from 'react-router-dom';
import { routers } from '../constants/listRouterProfile';
import { connect } from 'react-redux';
import { setNavbarStatus } from '../action';

const { Header, Footer, Sider, Content } = Layout;

const LayoutSidebar = ({ children, navbarStatus, handleSetStatusNavbar }) => {

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    verifyRoute()
  }, [location])

  const verifyRoute = () => {

    const newArr = [...routers]
    const findRoute = newArr.find(ro => {
      const str = ro.path.replace('/:id', '')
      return str !== '/' && location.pathname.includes(str)
    })

    !findRoute && history.replace('/index-ordenes')
  }

  return (
    <Layout>
      <Sider
        collapsible
        zeroWidthTriggerStyle={{ display: 'none' }}
        collapsed={!navbarStatus}
        onCollapse={() => handleSetStatusNavbar(!navbarStatus)}
        width={250}
        theme='light'
        className='p-0'
        breakpoint="lg"
        collapsedWidth="0"
      >
        <MainSidebar />
      </Sider>
      <Layout>

        <Header className='p-0' style={{ height: 60 }}>
          <MainNavbar props={children.props} />
        </Header>

        <Content>
          {children}
        </Content>

        <Footer className='p-0'>
          <MainFooter />
        </Footer>

      </Layout>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  navbarStatus: state.app.navbarStatus,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetStatusNavbar: (payload) => {
    dispatch(setNavbarStatus(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSidebar)


