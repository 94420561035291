import  { useState } from "react";
import { connect } from "react-redux";
import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';

import { fecthLogout } from "../../../../action";

const UserActions = (props) => {
  const {usuario,country} = props;
  const [visible, setVisible] = useState(false);
  

  const profile = () => {
    window.location.href = "/user-profile-lite";
  };

  const logout = () => {
    const { logout } = props;
    localStorage.removeItem("user");
    logout();
    window.location.href = "/Login";
  };

  const toggleUserActions = () => {
    setVisible(!visible);
  }

  const imageUser = country.imageUser

  const items = [
    {
      label: (
        <a className="text-decoration-none" href="/user-profile-lite" >
          <span className=" d-flex align-items-center"> 
            <i className="material-icons me-1">&#xE7FD;</i> Perfil
          </span>
        </a>
      ),
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <a className="text-decoration-none text-danger" onClick={logout} >
          <span className="d-flex align-items-center">
            <i className="material-icons text-danger me-1">&#xE879;</i> Cerrar Sesión 
          </span>
        </a>
      ),
      key: '1',
    },
  ];

  return (
    <>
      <Dropdown
        menu={{
          items,
        }}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space className=" text-secondary">
            {(country && imageUser) ?
              <img
                width="40" height="40"
                style={{borderRadius:20}}
                src={imageUser}
                alt="User Avatar"
              />
            :
              <img
                width="40" height="40"
                style={{borderRadius:20}}
                src={require("./../../../../images/avatars/0.jpg")}
                alt="User Avatar"
              />
            }
            {" "}
            <span className="d-none d-md-inline-block">{usuario.nombre}</span>
            <CaretDownOutlined size={'10'} />
          </Space>
        </a>
      </Dropdown>
    </>
  );
}

const mapStateToProps = state => ({
  usuario: state.app.usuario,
  country: state.app.country
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(fecthLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserActions);
