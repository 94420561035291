import axios from "axios";
import {
  urlService
} from "../utils/url";
import { authHeader } from "../utils/authHeader";
import Swal from "sweetalert2";
import {store} from '../config/store';
import { setLogout } from "../action";

const fetchBackend = axios.create({
  baseURL: urlService,
  headers: {
    "Content-type": "application/json",
  },
});

fetchBackend.defaults.headers.common["Authorization"] = authHeader();

fetchBackend.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    console.log(error);
    // Your Interceptor code to do something with response error
    // Return error
    return Promise.reject(error);
  }
);

fetchBackend.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (error && error.response && error.response.status === 403) {
      if (!Swal.isVisible()) {

        const {dispatch} = store

        Swal.fire({
          title: "Sesión Caducada",
          text: "Tu sesión ha caducado, favor inicia sesión nuevamente 😇",
          type: "warning",
        }).then( async () => {
          localStorage.removeItem("user");
          localStorage.removeItem("rol");
          localStorage.removeItem("usuarioEmail");
          await dispatch(setLogout())
          removeAuthenticationHeader();
          window.location.href = "/Login";
        });
      }
    }
  }
);


export const removeAuthenticationHeader = () => {
  delete fetchBackend.defaults.headers.common["Authorization"];
};

export default fetchBackend;