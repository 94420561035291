import  { useEffect } from "react";
import { BrowserRouter as Router} from "react-router-dom";
import Routes from "./routers/Routes";

import { connect } from "react-redux";
import { setCountry } from "./action/index";
import { ConfigProvider } from "antd";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";
import { getCountry } from "./utils/country";

import dayjs from "dayjs";
import "dayjs/locale/es";
import locale from "antd/es/locale/es_ES";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale);
dayjs.updateLocale("es_es", {
  weekStart: 0
});

const App = (props) => {
  const { actionCountry } = props;

  useEffect(() => {
    const country = getCountry();
    actionCountry(country);
  }, []);

  return (
    <ConfigProvider locale={locale}>
      <Router basename={process.env.REACT_APP_BASENAME || "forusapp/backoffice"}>
        <Routes />
      </Router>
    </ConfigProvider>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  actionCountry: (payload) => dispatch(setCountry(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
